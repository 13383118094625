import { GetProp, TableProps } from "antd";
import { utilityService } from "shared/services";
import { IPipelineDetails } from "sales/models";

type ColumnsType<T extends object> = GetProp<TableProps<T>, "columns">;

export const PipelineDetailsColumns: ColumnsType<IPipelineDetails> = [
  {
    title: "",
    dataIndex: "id",
    width: 100,
    fixed: "left",
    render: (value, record, index) => index + 1,
  },
  {
    title: "Project",
    dataIndex: "projectName",
    render: (value, record, index) => utilityService.truncateField(value),
  },
  {
    title: "Revenue",
    dataIndex: "revenue",
    render: (value, record, index) =>
      `$${utilityService.statisticsFormatter(value)}`,
  },
  {
    title: "Commission",
    dataIndex: "commission",
    render: (value, record, index) =>
      `$${utilityService.statisticsFormatter(value)}`,
  },
];
