import React, { useEffect } from "react";
import { Alert, Form, Input, notification, Typography } from "antd";
import { useNavigate } from "react-router-dom";

import "./PasswordRecoveryConfirmationForm.scss";
import { useConfirmForgotPassword } from "../../hooks";
import { SubmitButton } from "shared/components";
import { SplitPageLayout } from "shared/components";

type Props = {
  email: string;
};

export const PasswordRecoveryConfirmationForm = ({ email }: Props) => {
  const { confirmForgotPassword, success, error } = useConfirmForgotPassword();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [api, notificationContextHolder] = notification.useNotification();

  const onFinishHandler = async (values: any) => {
    const password = values.password.trim();
    const verificationCode = values.verificationCode.trim();

    await confirmForgotPassword(email, verificationCode, password);
    form.resetFields();
  };

  const subtitle = (
    <Typography.Paragraph
      type='secondary'
      className='password-recovery-confirmation-subtitle'
    >
      Your previous password has been reset. <br />
      Please set a new password for your account.
      <br />
      <br />
      Password requirements:
      <br />
      <br />
      <ul>
        <li>At least 8 characters long</li>
        <li>At least 1 uppercase letter</li>
        <li>At least 1 lowercase letter</li>
        <li>At least 1 symbol</li>
      </ul>
    </Typography.Paragraph>
  );

  useEffect(() => {
    if (success) {
      api.success({
        onClose: () => {
          navigate("/login");
        },
        duration: 3,
        message: "Password recovery complete",
        description: "Your password has been updated successfully.",
      });
    } else if (error) {
      api.error({
        message: "Password recovery error",
        description: error?.message,
      });
    }
  }, [success, error, api, navigate]);

  return (
    <SplitPageLayout title='Set a new password' subtitle={subtitle}>
      {notificationContextHolder}
      {error && (
        <Alert
          data-testid='password-recovery-confirmation-error'
          className='password-recovery-confirmation-error'
          message='Error'
          description={error?.message}
          type='error'
          showIcon
        />
      )}
      <Form
        form={form}
        name='password-recovery-confirmation-form'
        data-testid='password-recovery-confirmation-form'
        layout='vertical'
        onFinish={onFinishHandler}
        requiredMark={false}
      >
        <Form.Item
          name='verificationCode'
          data-testid='verificationCode'
          label='Verification Code'
          rules={[
            {
              required: true,
              message: "Please enter the verification code.",
            },
          ]}
        >
          <Input size='large' />
        </Form.Item>
        <Form.Item
          name='password'
          data-testid='password'
          label='Create Password'
          rules={[
            {
              required: true,
              message: "Please enter a password.",
            },
            {
              type: "string",
              min: 8,
              message:
                "Your password is too short. It should be at least 8 characters long.",
            },
            {
              type: "string",
              pattern: /[0-9]/,
              message: "Password requires a number.",
            },
            {
              type: "string",
              pattern: /[a-z]/,
              message: "Password requires a lowercase letter.",
            },
            {
              type: "string",
              pattern: /[A-Z]/,
              message: "Password requires an uppercase letter.",
            },
            {
              type: "string",
              pattern: /[^\w]/,
              message: "Password requires a special character.",
            },
          ]}
        >
          <Input.Password size='large' />
        </Form.Item>
        <Form.Item
          name='confirm-password'
          data-testid='confirm-password'
          label='Re-enter'
          rules={[
            {
              required: true,
              message: "Please confirm your password.",
            },
            ({ getFieldValue }) => ({
              async validator(_, value) {
                const password = getFieldValue("password") ?? "";

                if (value?.trim() !== password.trim()) {
                  return Promise.reject(
                    new Error("Your passwords are not matching.")
                  );
                }

                return Promise.resolve();
              },
            }),
          ]}
        >
          <Input.Password size='large' />
        </Form.Item>
        <SubmitButton
          className='password-recovery-confirmation-button'
          testId='password-recovery-confirmation-button'
          form={form}
        >
          Set Password
        </SubmitButton>
      </Form>
    </SplitPageLayout>
  );
};
