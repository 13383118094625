import { httpService } from "shared/services";
import { IFinancialsInfo } from "financials/models";

const apiPath = `${process.env.REACT_APP_API_BASE_URL}/api/v1/financials`;

export const getFinancialsInfo = async () => {
  const res = await httpService.instance.get<IFinancialsInfo>(
    `${apiPath}/info`
  );
  return res?.data;
};
