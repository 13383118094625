import { Row, Table, TableProps, Typography } from "antd";
import "./SalesLeaderboard.scss";
import React from "react";
import { SalesLeaderboardColumns } from "sales/constants";
import { ISalesLeaderboard } from "sales/models";
import { useBoundStore } from "store";

type Props = {
  dataLoading?: boolean;
};

export const SalesLeaderboard = ({ dataLoading }: Props) => {
  const tblRef: Parameters<typeof Table>[0]["ref"] = React.useRef(null);
  const tableColumns = SalesLeaderboardColumns.map((item) => ({ ...item }));
  const tableProps: TableProps<ISalesLeaderboard> = {
    loading: dataLoading,
  };
  const { salesLeaderboard } = useBoundStore();

  return (
    <>
      <Row>
        <Typography.Title className='sales-leaderboard-title' level={3}>
          Sales Leaderboard
        </Typography.Title>
      </Row>
      <Row>
        <Table
          {...tableProps}
          columns={tableColumns}
          dataSource={salesLeaderboard}
          className='sales-leaderboard-table'
          ref={tblRef}
          rowKey='id'
          pagination={false}
        />
      </Row>
    </>
  );
};
