import { StateCreator } from "zustand";

import { ISelectOption } from "shared/models";
import { IAuthSlice } from "../models";
import { sliceResetFns } from "store";

const initialState = {
  salesRepsSelectOptions: [],
  accessToken: undefined,
  idToken: undefined,
};

export const createAuthSlice: StateCreator<
  IAuthSlice,
  [["zustand/devtools", never]],
  [],
  IAuthSlice
> = (set) => {
  sliceResetFns.add(() => set(initialState));
  return {
    ...initialState,
    setSalesRepsSelectOptions: (selectOptions: ISelectOption[]) =>
      set(() => ({
        salesRepsSelectOptions: selectOptions,
      })),
    setTokens: (accessToken, idToken) =>
      set(() => ({
        accessToken,
        idToken,
      })),
  };
};
