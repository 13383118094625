import { useEffect } from "react";
import { isEmpty } from "lodash";
import { Alert, Col, Form, Input, Row, Typography, notification } from "antd";

import "./LoginAndSecuritySettingsForm.scss";
import { SubmitButton } from "shared/components";
import { useUpdateLoginAndSecuritySettings } from "auth/hooks";
import { useBoundStore } from "store";

export const LoginAndSecuritySettingsForm = () => {
  const [form] = Form.useForm();
  const { updateLoginAndSecuritySettings, error, success } =
    useUpdateLoginAndSecuritySettings();
  const user = useBoundStore((state) => state.user);
  const [api, contextHolder] = notification.useNotification();

  const onFinishHandler = async (values: any) => {
    const email = values?.email?.trim();
    const password = values?.password?.trim();
    const oldPassword = values?.oldPassword?.trim();
    const isSameEmail = email?.toLowerCase() === user?.email?.toLowerCase();

    await updateLoginAndSecuritySettings(
      isSameEmail ? "" : email,
      oldPassword,
      password
    );
    form.resetFields();
  };

  useEffect(() => {
    if (success) {
      api.success({
        duration: 3,
        message: "Update login and security complete",
        description: "Your login and security information has been updated.",
      });
    }
  }, [success, api]);

  return (
    <>
      {contextHolder}
      <Row>
        <Col>
          <Typography.Title
            className='login-and-security-settings-title'
            level={3}
          >
            Update your login information
          </Typography.Title>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          {error && (
            <Alert
              data-testid='login-and-security-settings-error'
              className='login-and-security-settings-error'
              message='Error'
              description={error?.message}
              type='error'
              showIcon
            />
          )}
          <Form
            form={form}
            name='login-and-security-settings-form'
            data-testid='login-and-security-settings-form'
            layout='vertical'
            onFinish={onFinishHandler}
            requiredMark={false}
          >
            <Form.Item
              initialValue={user?.email}
              name='email'
              data-testid='email'
              label='Email'
              rules={[
                {
                  required: false,
                  type: "email",
                  message: "Please enter a valid email address.",
                },
              ]}
            >
              <Input size='large' />
            </Form.Item>
            <Form.Item
              name='oldPassword'
              data-testid='oldPassword'
              label='Previous Password'
              rules={[
                ({ getFieldValue }) => ({
                  async validator(_, value) {
                    const password = (getFieldValue("password") ?? "").trim();

                    if (!isEmpty(password) && isEmpty(value?.trim())) {
                      return Promise.reject(
                        new Error("Please enter a previous password.")
                      );
                    }

                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Input.Password size='large' />
            </Form.Item>
            <Form.Item
              name='password'
              data-testid='password'
              label='New Password'
              rules={[
                {
                  type: "string",
                  min: 8,
                  message:
                    "Your password is too short. It should be at least 8 characters long.",
                },
                {
                  pattern: /[0-9]/,
                  message: "Password requires a number.",
                },
                {
                  pattern: /[a-z]/,
                  message: "Password requires a lowercase letter.",
                },
                {
                  pattern: /[A-Z]/,
                  message: "Password requires an uppercase letter.",
                },
                {
                  pattern: /[^\w]/,
                  message: "Password requires a special character.",
                },
                ({ getFieldValue, setFields }) => ({
                  async validator(_, value) {
                    const oldPassword = (
                      getFieldValue("oldPassword") ?? ""
                    ).trim();
                    const confirmPassword = (
                      getFieldValue("confirmPassword") ?? ""
                    ).trim();

                    if (!isEmpty(value?.trim())) {
                      if (isEmpty(oldPassword)) {
                        setFields([
                          {
                            name: "oldPassword",
                            value: oldPassword,
                            errors: ["Please enter a previous password."],
                          },
                        ]);
                      }

                      if (isEmpty(confirmPassword)) {
                        setFields([
                          {
                            name: "confirmPassword",
                            value: confirmPassword,
                            errors: ["Your passwords are not matching."],
                          },
                        ]);
                      }
                    }

                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Input.Password size='large' />
            </Form.Item>
            <Form.Item
              name='confirmPassword'
              data-testid='confirmPassword'
              label='Confirm Password'
              rules={[
                ({ getFieldValue, setFields }) => ({
                  async validator(_, value) {
                    const password = (getFieldValue("password") ?? "").trim();
                    const oldPassword = (
                      getFieldValue("oldPassword") ?? ""
                    ).trim();

                    if (!isEmpty(password) && isEmpty(oldPassword)) {
                      setFields([
                        {
                          name: "oldPassword",
                          value: oldPassword,
                          errors: ["Please enter a previous password."],
                        },
                      ]);
                    }

                    if (!isEmpty(password) && value?.trim() !== password) {
                      return Promise.reject(
                        new Error("Your passwords are not matching.")
                      );
                    }

                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Input.Password size='large' />
            </Form.Item>
            <SubmitButton
              className='login-and-security-settings-button'
              testId='login-and-security-settings-button'
              form={form}
              overrideDisabled={false}
            >
              Update Login
            </SubmitButton>
          </Form>
        </Col>
      </Row>
    </>
  );
};
