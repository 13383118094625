import { Col, notification, Row, Typography, Card, Skeleton } from "antd";
import { useEffect } from "react";

import "./ProgramManagerDashboardProjectCard.scss";
import {
  DeficientProjects,
  ProjectsByStage,
  ProjectsTimeTrackingSummary,
} from "projects/components";
import { useGetProjectsInfo } from "projects/hooks/useGetProjectsInfo";

export const ProgramManagerDashboardProjectCard = () => {
  const { getProjectsInfo, success, error, loading } = useGetProjectsInfo();
  const [api, notificationContextHolder] = notification.useNotification();

  useEffect(() => {
    getProjectsInfo();
  }, []);

  useEffect(() => {
    if (error) {
      api.error({
        message: "Error",
        description: error?.message,
      });
    }
  }, [error, api]);

  return (
    <>
      {notificationContextHolder}

      <Card className='program-manager-dashboard-project-card-container'>
        {success ? (
          <>
            <Row
              gutter={{ xs: 4, sm: 8, md: 16, lg: 20 }}
              justify={"space-between"}
            >
              <Col>
                <Typography.Title
                  className='program-manager-dashboard-project-card-title'
                  level={3}
                >
                  Projects
                </Typography.Title>
              </Col>
            </Row>
            <Row
              gutter={{ xs: 4, sm: 8, md: 16, lg: 20 }}
              justify={"space-between"}
            >
              <Col span={8}>
                <ProjectsByStage dataLoading={loading} />
              </Col>
              <Col span={16}>
                <ProjectsTimeTrackingSummary dataLoading={loading} />
              </Col>
            </Row>
            <Row
              gutter={{ xs: 4, sm: 8, md: 16, lg: 20 }}
              justify={"space-between"}
            >
              <Col span={24}>
                <DeficientProjects />
              </Col>
            </Row>
          </>
        ) : (
          <Skeleton />
        )}
      </Card>
    </>
  );
};
