enum CHART_COLORS {
  RED = "#FF5E4B",
  PURPLE = "#7041A9",
  YELLOW = "#FAF119",
  GREEN = "#13B76E",
  ORANGE = "#FB9D11",
}

export const ChartColors = [
  CHART_COLORS.RED,
  CHART_COLORS.PURPLE,
  CHART_COLORS.YELLOW,
  CHART_COLORS.GREEN,
  CHART_COLORS.ORANGE,
];
