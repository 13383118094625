import { Button, Form, Modal, Select } from "antd";
import { isEmpty } from "lodash";
import { useEffect } from "react";
import { useGetSubcontractorMemberSelectOptions } from "subcontractors/hooks";
import { ISubcontractorMember } from "subcontractors/models";

type Props = {
  onAdd: (subcontractorMember: ISubcontractorMember) => void;
  onCancel: () => void;
  open: boolean;
  subcontractorId?: string;
};

export const AddSubcontractorMemberModal = ({
  onAdd,
  onCancel,
  open,
  subcontractorId,
}: Props) => {
  const [form] = Form.useForm();
  const {
    getSubcontractorMemberSelectOptions,
    data: subcontractorMemberSelectOptions,
    loading: subcontractorMemberSelectOptionsLoading,
  } = useGetSubcontractorMemberSelectOptions();

  const onFinishHandler = ({
    subcontractorMemberId,
  }: {
    subcontractorMemberId: string;
  }) => {
    if (isEmpty(subcontractorMemberId)) return;

    const subcontractorMember = subcontractorMemberSelectOptions?.find(
      (x) => x.value === subcontractorMemberId
    );

    if (onAdd)
      onAdd({
        id: subcontractorMemberId,
        name: subcontractorMember?.label,
      } as ISubcontractorMember);
  };

  useEffect(() => {
    getSubcontractorMemberSelectOptions(subcontractorId);
  }, [getSubcontractorMemberSelectOptions, subcontractorId]);

  useEffect(() => {
    form.resetFields();
  }, [form, open]);

  return (
    <Form
      form={form}
      name='add-subcontractor-member-form'
      data-testid='add-subcontractor-member-form'
      layout='vertical'
      onFinish={onFinishHandler}
      requiredMark={false}
    >
      <Modal
        title='Add a member'
        footer={
          <Button type='primary' htmlType='submit' onClick={form.submit}>
            Save
          </Button>
        }
        loading={subcontractorMemberSelectOptionsLoading}
        open={open}
        onCancel={onCancel}
      >
        <Form.Item
          name='subcontractorMemberId'
          data-testid='subcontractorMemberId'
          className='subcontractor-setup-select-box'
          labelAlign='left'
          label='Select Member'
        >
          <Select
            defaultValue={""}
            size='large'
            options={[
              { value: "", label: "Select..." },
              ...(subcontractorMemberSelectOptions ?? []),
            ]}
          />
        </Form.Item>
      </Modal>
    </Form>
  );
};
