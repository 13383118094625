import { isEmpty } from "lodash";
import { UtilityPrograms } from "projects/constants";
import { IProject, IProjectsInfo } from "projects/models";
import { ISelectOption, PaginatedResult } from "shared/models";
import { httpService } from "shared/services";

const apiPath = `${process.env.REACT_APP_API_BASE_URL}/api/v1/projects`;

export const getProjects = async (
  projectId?: string,
  status?: string,
  subcontractor?: string,
  utilityProgram?: string,
  accountManager?: string,
  customerPaymentMethod?: string,
  stage?: string,
  showArchived?: boolean,
  lastEvaluatedKey?: any
) => {
  const params = {
    projectId,
    status,
    subcontractor,
    utilityProgram,
    accountManager,
    customerPaymentMethod,
    stage,
    showArchived,
    lastEvaluatedKey,
  };
  const res = await httpService.instance.post<PaginatedResult<IProject>>(
    `${apiPath}/all`,
    params
  );
  return res?.data;
};

export const getProject = async (id: string) => {
  const res = await httpService.instance.get<IProject>(`${apiPath}/${id}`);
  return res?.data;
};

export const addProject = async (project: IProject) => {
  const res = await httpService.instance.post<IProject>(`${apiPath}`, project);
  return res?.data;
};

export const updateProject = async (id: string, project: IProject) => {
  const res = await httpService.instance.put<IProject>(
    `${apiPath}/${id}`,
    project
  );
  return res?.data;
};

export const deleteProject = async (id: string) => {
  await httpService.instance.delete(`${apiPath}/${id}`);
};

export const getUtilityProgramLabel = (utilityProgram: string) => {
  return UtilityPrograms?.find((p) => p.value === utilityProgram)?.label;
};

export const getProjectsInfo = async () => {
  const res = await httpService.instance.get<IProjectsInfo>(`${apiPath}/info`);
  return res?.data;
};

export const getProjectOptions = async (search?: string) => {
  let path = `${apiPath}/options`;

  if (!isEmpty(search)) {
    path = `${path}?search=${search}`;
  }

  const res = await httpService.instance.get<ISelectOption[]>(path);
  return res?.data;
};
