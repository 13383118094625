import { Row, Table, TableProps, Typography } from "antd";
import "./ProjectsInCloseoutStage.scss";
import React from "react";
import { useBoundStore } from "store";
import { ProjectsInCloseoutStageColumns } from "projects/constants";
import { IProjectInCloseoutStage } from "projects/models";
import { utilityService } from "shared/services";

type Props = {
  dataLoading?: boolean;
};

export const ProjectsInCloseoutStage = ({ dataLoading }: Props) => {
  const tblRef: Parameters<typeof Table>[0]["ref"] = React.useRef(null);
  const tableColumns = ProjectsInCloseoutStageColumns.map((item) => ({
    ...item,
  }));
  const tableProps: TableProps<IProjectInCloseoutStage> = {
    loading: dataLoading,
  };
  const { projectsInCloseoutStage } = useBoundStore();

  return (
    <>
      <Row>
        <Typography.Title
          className='projects-in-closeout-stage-title'
          level={3}
        >
          Projects in Closeout Stage {">"} 10 Days
        </Typography.Title>
      </Row>
      <Row>
        <Table
          {...tableProps}
          columns={tableColumns}
          dataSource={projectsInCloseoutStage}
          className='projects-in-closeout-stage-table'
          ref={tblRef}
          rowKey='id'
          pagination={false}
          summary={(pageData) => {
            let totalProjectValues = 0;

            pageData.forEach(({ totalProjectValue }) => {
              totalProjectValues += Number(totalProjectValue ?? 0);
            });

            return (
              <>
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0}></Table.Summary.Cell>
                  <Table.Summary.Cell index={1} colSpan={4}>
                    <Typography.Text strong>TOTALS</Typography.Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={2}>
                    <Typography.Text strong>
                      ${utilityService.statisticsFormatter(totalProjectValues)}
                    </Typography.Text>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </>
            );
          }}
        />
      </Row>
    </>
  );
};
