import { Col, Row, Tabs, Typography } from "antd";

import "./Settings.scss";
import { ProfileSettingsForm } from "users/components";
import { LoginAndSecuritySettingsForm } from "auth/components";

export const Settings = () => {
  return (
    <>
      <Row>
        <Col>
          <Typography.Title className='settings-title' level={2}>
            Settings
          </Typography.Title>
        </Col>
      </Row>
      <Row>
        <Col>
          <Tabs
            defaultActiveKey='1'
            items={[
              {
                label: "Profile",
                key: "1",
                children: <ProfileSettingsForm />,
              },
              {
                label: "Login & Security",
                key: "2",
                children: <LoginAndSecuritySettingsForm />,
              },
              {
                label: "Notifications",
                key: "3",
                children: "Notifications",
                disabled: true,
              },
            ]}
          />
        </Col>
      </Row>{" "}
    </>
  );
};
