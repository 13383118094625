import { Card } from "antd";

import "./EngineerDashboardNotesCard.scss";

import { NotesList } from "notes/components";

export const EngineerDashboardNotesCard = () => {
  return (
    <Card className='engineer-dashboard-notes-card-container'>
      <NotesList title='My Project Notes' height={900} />
    </Card>
  );
};
