import { Col, Row, Typography, Card, Skeleton } from "antd";

import "./FieldAuditorDashboardAuditAppointmentsCard.scss";
import { AuditAppointments } from "audit/components";

type Props = {
  loaded?: boolean;
};

export const FieldAuditorDashboardAuditAppointmentsCard = ({
  loaded,
}: Props) => {
  return (
    <Card className='field-auditor-dashboard-audit-appointments-card-container'>
      {loaded ? (
        <>
          <Row
            gutter={{ xs: 4, sm: 8, md: 16, lg: 20 }}
            justify={"space-between"}
          >
            <Col>
              <Typography.Title
                className='field-auditor-dashboard-audit-appointments-card-title'
                level={3}
              >
                Audit Appointments
              </Typography.Title>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <AuditAppointments />
            </Col>
          </Row>
        </>
      ) : (
        <Skeleton />
      )}
    </Card>
  );
};
