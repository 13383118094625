import { Empty } from "antd";
import { memo } from "react";

type Props = {
  height?: number;
};

export const EmptyData = memo(({ height }: Props) => {
  return (
    <div className='empty-data-container'>
      <Empty
        description='No data'
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        style={
          height
            ? {
                height: `${height}px`,
              }
            : undefined
        }
      />
    </div>
  );
});
