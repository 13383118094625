import { Col, notification, Row } from "antd";

import "./FinanceManagerDashboard.scss";
import { FinanceManagerFinancialsChartsCard } from "./finance-manager-financials-charts-card/FinanceManagerFinancialsChartsCard";
import { FinanceManagerFinancialsDatatablesCard } from "./finance-manager-financials-datatables-card/FinanceManagerFinancialsDatatablesCard";
import { useEffect } from "react";
import { useGetFinancialsInfo } from "financials/hooks";
import { FinanceManagerDashboardNotesCard } from "./finance-manager-dashboard-notes-card/FinanceManagerDashboardNotesCard";

export const FinanceManagerDashboard = () => {
  const { getFinancialsInfo, error, success } = useGetFinancialsInfo();
  const [api, notificationContextHolder] = notification.useNotification();

  const onSalesTargetTimePeriodChange = () => {
    getFinancialsInfo();
  };

  useEffect(() => {
    getFinancialsInfo();
  }, []);

  useEffect(() => {
    if (error) {
      api.error({
        message: "Error",
        description: error?.message,
      });
    }
  }, [error, api]);

  return (
    <>
      {notificationContextHolder}
      <Col span={24} className='finance-manager-dashboard'>
        <Row
          gutter={{ xs: 4, sm: 8, md: 16, lg: 20 }}
          justify={"space-between"}
        >
          <Col span={16}>
            <FinanceManagerFinancialsChartsCard
              onSalesTargetTimePeriodChange={onSalesTargetTimePeriodChange}
              loaded={success}
            />
          </Col>
          <Col span={8}>
            <FinanceManagerDashboardNotesCard />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <FinanceManagerFinancialsDatatablesCard loaded={success} />
          </Col>
        </Row>
      </Col>
    </>
  );
};
