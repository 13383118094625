import { useCallback, useState } from "react";
import { ISimpleSubcontractor } from "subcontractors/models";
import { subcontractorService } from "subcontractors/services";

export const useGetSubcontractors = () => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState<Error | undefined>(undefined);
  const [data, setData] = useState<ISimpleSubcontractor[] | undefined>();

  const getSubcontractors = useCallback(async () => {
    try {
      setLoading(true);
      setSuccess(false);
      setData(undefined);
      setError(undefined);

      const subcontractors =
        await subcontractorService.getSimpleSubcontractors();

      setData(subcontractors);
      setSuccess(true);
    } catch (error) {
      setSuccess(false);
      setError(error as Error);
    } finally {
      setLoading(false);
    }
  }, []);

  return {
    getSubcontractors,
    loading,
    success,
    error,
    data,
  };
};
