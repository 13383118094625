import { httpService } from "shared/services";
import { ISalesTeam } from "sales/models";

const apiPath = `${process.env.REACT_APP_API_BASE_URL}/api/v1/salesteams`;

export const getSalesTeams = async () => {
  const res = await httpService.instance.get<ISalesTeam[]>(`${apiPath}`);
  return res?.data;
};

export const upsertSalesTeam = async (team: ISalesTeam) => {
  try {
    const res = await httpService.instance.post<ISalesTeam>(
      `${apiPath}/upsert`,
      team
    );
    return res?.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const deleteSalesTeam = async (id: string) => {
  try {
    const res = await httpService.instance.delete<string>(`${apiPath}/${id}`);
    return res?.data;
  } catch (error) {
    return Promise.reject(error);
  }
};
