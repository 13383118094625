import { useState } from "react";
import { ISalesTeam } from "sales/models";
import { salesTeamService } from "sales/services";

export const useGetSalesTeams = () => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState<Error | undefined>(undefined);
  const [data, setData] = useState<ISalesTeam[] | undefined>();

  const getSalesTeams = async () => {
    try {
      setLoading(true);
      setSuccess(false);
      setData(undefined);
      setError(undefined);

      const teams = await salesTeamService.getSalesTeams();

      setData(teams);
      setSuccess(true);
    } catch (error) {
      setSuccess(false);
      setError(error as Error);
    } finally {
      setLoading(false);
    }
  };

  return {
    getSalesTeams,
    loading,
    success,
    error,
    data,
  };
};
