import { Row, Table, TableProps, Typography } from "antd";
import "./OpenAccountsReceivableIncentive.scss";
import React from "react";
import { useBoundStore } from "store";
import { OpenAccountsReceivableIncentiveColumns } from "financials/constants";
import { IOpenAccountsReceivableIncentive } from "financials/models";
import { utilityService } from "shared/services";

type Props = {
  dataLoading?: boolean;
};

export const OpenAccountsReceivableIncentive = ({ dataLoading }: Props) => {
  const tblRef: Parameters<typeof Table>[0]["ref"] = React.useRef(null);
  const tableColumns = OpenAccountsReceivableIncentiveColumns?.map((item) => ({
    ...item,
  }));
  const tableProps: TableProps<IOpenAccountsReceivableIncentive> = {
    loading: dataLoading,
  };
  const { openAccountsReceivableIncentive } = useBoundStore();

  return (
    <>
      <Row>
        <Typography.Title
          className='open-accounts-receivable-incentive-title'
          level={3}
        >
          Open Accounts Receivable - Incentive
        </Typography.Title>
      </Row>
      <Row>
        <Table
          {...tableProps}
          columns={tableColumns}
          dataSource={openAccountsReceivableIncentive}
          className='open-accounts-receivable-incentive-table'
          ref={tblRef}
          rowKey='id'
          pagination={false}
          summary={(pageData) => {
            let totalIncentiveBilledAmount = 0;
            let totalIncentiveReceivedAmount = 0;

            pageData.forEach(
              ({ incentiveBilledAmount, incentiveReceivedAmount }) => {
                totalIncentiveBilledAmount += Number(
                  incentiveBilledAmount ?? 0
                );
                totalIncentiveReceivedAmount += Number(
                  incentiveReceivedAmount ?? 0
                );
              }
            );

            return (
              <>
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0}></Table.Summary.Cell>
                  <Table.Summary.Cell index={1} colSpan={2}>
                    <Typography.Text strong>TOTALS</Typography.Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={2} colSpan={4}>
                    <Typography.Text strong>
                      $
                      {utilityService.statisticsFormatter(
                        totalIncentiveBilledAmount
                      )}
                    </Typography.Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={3}>
                    <Typography.Text strong>
                      $
                      {utilityService.statisticsFormatter(
                        totalIncentiveReceivedAmount
                      )}
                    </Typography.Text>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </>
            );
          }}
        />
      </Row>
    </>
  );
};
