import { Button, Divider, Flex, Image, Typography } from "antd";

import "./ProjectStageSelection.scss";
import { ProjectStages } from "projects/constants";
import selectProjectStage from "../../assets/images/select-project-stage.svg";
import { useBoundStore } from "store";

export const ProjectStageSelection = () => {
  const { stage, setStage } = useBoundStore();

  const onClickHandler = (newStage: string) => {
    setStage(newStage === stage ? "" : newStage);
  };

  return (
    <>
      <Flex dir='row' gap='small'>
        <Image
          src={selectProjectStage}
          alt='Select Project Stage'
          preview={false}
          width={20}
        />
        <Typography.Title className='project-stage-selection-title' level={2}>
          Select Project Stage
        </Typography.Title>
      </Flex>
      <Divider />
      <Flex gap='middle' wrap>
        {ProjectStages.map((s) => (
          <Button
            type='primary'
            size='large'
            className={`project-stage-button ${stage === s.value ? "project-stage-button-selected" : ""}`}
            key={s.value}
            onClick={() => onClickHandler(s?.value?.toString() ?? "")}
          >
            {s.label}
          </Button>
        ))}
      </Flex>
    </>
  );
};
