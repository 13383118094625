import { Col, Row, Card, Skeleton } from "antd";

import "./OperationsManagerDashboardProjectsCard.scss";

import {
  DeficientProjects,
  ProjectsInCloseoutStage,
} from "projects/components";

type Props = {
  loaded?: boolean;
};

export const OperationsManagerDashboardProjectsCard = ({ loaded }: Props) => {
  return (
    <Card className='operations-manager-dashboard-projects-card-container'>
      {loaded ? (
        <>
          <Row>
            <Col span={24}>
              <ProjectsInCloseoutStage />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <DeficientProjects />
            </Col>
          </Row>
        </>
      ) : (
        <Skeleton />
      )}
    </Card>
  );
};
