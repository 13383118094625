import { Col, Row, Card, Skeleton } from "antd";

import "./ContractorDashboardDeficientProjectsCard.scss";

import { DeficientProjects } from "projects/components";

type Props = {
  loaded?: boolean;
};

export const ContractorDashboardDeficientProjectsCard = ({ loaded }: Props) => {
  return (
    <Card className='contractor-dashboard-deficient-projects-card-container'>
      {loaded ? (
        <>
          <Row>
            <Col span={24}>
              <DeficientProjects />
            </Col>
          </Row>
        </>
      ) : (
        <Skeleton />
      )}
    </Card>
  );
};
