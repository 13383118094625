import { Col, Row, Typography } from "antd";
import { Cell, Legend, Pie, PieChart, ResponsiveContainer } from "recharts";

import { useBoundStore } from "store";

import "./PipelineByProjectsStage.scss";
import { utilityService } from "shared/services";
import { ChartColors } from "shared/constants";

export const PipelineByProjectsStage = () => {
  const { pipelineByProjectStages } = useBoundStore();

  return (
    <>
      <Row>
        <Typography.Title
          className='pipeline-by-projects-stage-title'
          level={3}
        >
          Pipeline By Projects Stage
        </Typography.Title>
      </Row>
      <Row>
        <Col span={24}>
          <ResponsiveContainer width='100%' height={400}>
            <PieChart>
              <Pie
                dataKey='actualSales'
                nameKey='stage'
                data={pipelineByProjectStages}
                innerRadius={70}
                outerRadius={100}
              >
                {pipelineByProjectStages?.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={ChartColors[index % ChartColors.length]}
                  />
                ))}
              </Pie>
              <Legend
                layout='vertical'
                align='right'
                payload={pipelineByProjectStages?.map((item, index) => ({
                  id: item.stage,
                  type: "square",
                  value: `${utilityService.ifEmptyUseNotAvailable(item.stage)} (${item.totalProjects ?? 0}) $${utilityService.statisticsFormatter(item.actualSales)}`,
                  color: ChartColors[index % ChartColors.length],
                }))}
              />
            </PieChart>
          </ResponsiveContainer>
        </Col>
      </Row>
    </>
  );
};
