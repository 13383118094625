import { useState } from "react";
import { ISalesTeam } from "sales/models";
import { salesTeamService } from "sales/services";

export const useUpsertSalesTeam = () => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState<Error | undefined>(undefined);

  const upsertSalesTeam = async (team: ISalesTeam) => {
    try {
      setLoading(true);
      setSuccess(false);
      setError(undefined);

      await salesTeamService.upsertSalesTeam(team);

      setSuccess(true);
    } catch (error) {
      setSuccess(false);
      setError(error as Error);
    } finally {
      setLoading(false);
    }
  };

  return {
    upsertSalesTeam,
    loading,
    success,
    error,
  };
};
