import { Card } from "antd";

import "./SubcontractorDashboardNotesCard.scss";

import { NotesList } from "notes/components";

export const SubcontractorDashboardNotesCard = () => {
  return (
    <Card className='subcontractor-dashboard-notes-card-container'>
      <NotesList title='My Project Notes' height={900} />
    </Card>
  );
};
