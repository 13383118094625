import { Col, Row } from "antd";

import "./OwnerDashboard.scss";
import { OwnerDashboardSalesCard } from "./owner-dashboard-sales-card/OwnerDashboardSalesCard";
import { OwnerDashboardProjectCard } from "./owner-dashboard-project-card/OwnerDashboardProjectCard";
import { OwnerDashboardNotesCard } from "./owner-dashboard-notes-card/OwnerDashboardNotesCard";

export const OwnerDashboard = () => {
  return (
    <Col span={24} className='owner-dashboard'>
      <Row gutter={{ xs: 4, sm: 8, md: 16, lg: 20 }} justify={"space-between"}>
        <Col span={16}>
          <OwnerDashboardSalesCard />
        </Col>
        <Col span={8}>
          <OwnerDashboardNotesCard />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <OwnerDashboardProjectCard />
        </Col>
      </Row>
    </Col>
  );
};
