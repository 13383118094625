import React, { useCallback, useEffect, useState } from "react";
import { Form, Input, notification } from "antd";
import { useNavigate, useSearchParams } from "react-router-dom";

import { SubmitButton } from "shared/components";
import { ResendConfirmationCode } from "../../components";
import { SplitPageLayout } from "shared/components";
import { useVerifyCode } from "auth/hooks/useVerifyCode";
import { VerificationTypes } from "auth/constants";

export const VerifyCode = () => {
  let [searchParams] = useSearchParams();
  const [overrideDisabled, setOverrideDisabled] = useState<boolean | undefined>(
    undefined
  );
  const navigate = useNavigate();
  const { verifyCode, error, success, loading, data } = useVerifyCode();
  const [form] = Form.useForm();
  const [api, contextHolder] = notification.useNotification();

  const onFinishHandler = async (values: any) => {
    const email = values?.email?.trim();
    const verificationCode = values?.verificationCode?.trim();
    const verificationType = searchParams?.get("type")?.trim() ?? "";

    await verifyCode(email, verificationCode, verificationType);
  };

  const openNotification = useCallback(() => {
    api.success({
      onClose: () => {
        navigate("/login");
      },
      duration: 3,
      message: data?.message,
      description: data?.description,
    });
  }, [api, navigate, data]);

  useEffect(() => {
    if (success && data) {
      openNotification();
    }

    if (success || loading) {
      setOverrideDisabled(true);
    }

    if (error) {
      setOverrideDisabled(undefined);
    }
  }, [success, loading, error, openNotification, data]);

  return (
    <SplitPageLayout
      title='Verify Code'
      subtitle='Please complete verification so you can access your personal account.'
      error={error}
    >
      {contextHolder}
      <Form
        form={form}
        name='verify-code-form'
        data-testid='verify-code-up-form'
        layout='vertical'
        onFinish={onFinishHandler}
        requiredMark={false}
      >
        <Form.Item
          initialValue={searchParams?.get("email")}
          name='email'
          data-testid='email'
          label='Email'
          required
          hidden={searchParams?.get("type") !== VerificationTypes.SIGN_UP}
        >
          <Input size='large' disabled />
        </Form.Item>
        <Form.Item
          name='verificationCode'
          data-testid='verificationCode'
          label='Verification Code'
          rules={[
            {
              required: true,
              message: "Please enter the verification code.",
            },
          ]}
        >
          <Input size='large' />
        </Form.Item>
        {searchParams?.get("type") === VerificationTypes.SIGN_UP && (
          <ResendConfirmationCode email={searchParams?.get("email") ?? ""} />
        )}
        <SubmitButton
          className='verify-code-button'
          testId='verify-code-button'
          form={form}
          overrideDisabled={overrideDisabled}
        >
          Verify
        </SubmitButton>
      </Form>
    </SplitPageLayout>
  );
};
