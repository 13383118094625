import { dashboardsByRole } from "dashboard/constants";
import { DashboardRole } from "dashboard/models";
import { useBoundStore } from "store";

export const DashboardSelector = () => {
  const { user } = useBoundStore();
  const role: DashboardRole = (user?.role?.name as DashboardRole) ?? "basic";
  const Dashboard = dashboardsByRole[role];

  return <Dashboard />;
};
