import {
  Form,
  Select,
  Typography,
  Image,
  Space,
  Col,
  Row,
  Skeleton,
  Button,
  notification,
  Input,
  Table,
} from "antd";
import selectProjectStage from "../../../admin/assets/images/select-project-stage.svg";
import "./SubcontractorSetup.scss";
import { useCallback, useEffect, useState } from "react";
import { isEmpty, set } from "lodash";
import {
  useDeleteSubcontractor,
  useGetSubcontractorMembers,
  useGetSubcontractors,
  useUpsertSubcontractor,
} from "subcontractors/hooks";
import { SubcontractorMemberColumns } from "subcontractors/constants";
import { ISubcontractorMember } from "subcontractors/models";
import { AddSubcontractorMemberModal } from "./add-subcontractor-member-modal/AddSubcontractorMemberModal";
import { SubmitButton } from "shared/components";
import { ProjectTypes } from "projects/constants";

export const SubcontractorSetup = () => {
  const {
    getSubcontractors,
    data: subcontractorsData,
    loading: subcontractorsLoading,
    success: subcontractorsSuccess,
    error: subcontractorsError,
  } = useGetSubcontractors();
  const { getSubcontractorMembers, data: subcontractorMembersData } =
    useGetSubcontractorMembers();
  const [subcontractorMembers, setSubcontractorMembers] = useState<
    ISubcontractorMember[]
  >([]);
  const {
    upsertSubcontractor,
    success: upsertSubcontractorSuccess,
    error: upsertSubcontractorError,
  } = useUpsertSubcontractor();
  const {
    deleteSubcontractor,
    success: deleteSubcontractorSuccess,
    error: deleteSubcontractorError,
  } = useDeleteSubcontractor();
  const [existingSubcontractorDisabled, setExistingSubcontractorDisabled] =
    useState(false);
  const [open, setOpen] = useState(false);
  const [form] = Form.useForm();
  const [api, notificationContextHolder] = notification.useNotification();

  const handleFinish = async (values: any) => {
    const subcontractorId = values?.subcontractor?.trim();
    const subcontractorType = values?.type?.trim();
    const name = values?.name?.trim();
    const subcontractor = {
      id: subcontractorId,
      name,
      type: subcontractorType,
      members: subcontractorMembers?.map((m) => m.id as string),
    };

    await upsertSubcontractor(subcontractor);
  };

  const handleDelete = (id?: string) => {
    const newData = subcontractorMembers.filter((item) => item.id !== id);
    setSubcontractorMembers(newData);
  };

  const handleAdd = (newMember: ISubcontractorMember) => {
    setSubcontractorMembers([...subcontractorMembers, newMember]);
    setOpen(false);
  };

  const handleSubcontractorSelect = useCallback(
    async (id: string) => {
      const type = subcontractorsData?.find((t) => t.id === id)?.type;
      form.setFieldsValue({ subcontractor: id, type: type });

      if (isEmpty(id)) {
        setSubcontractorMembers([]);
      } else {
        await getSubcontractorMembers(id);
      }
    },
    [form, getSubcontractorMembers, subcontractorsData]
  );

  const columns = SubcontractorMemberColumns(handleDelete);

  useEffect(() => {
    const run = async () => {
      await getSubcontractors();
    };

    run();
  }, []);

  useEffect(() => {
    if (subcontractorMembersData) {
      setSubcontractorMembers(subcontractorMembersData);
    }
  }, [subcontractorMembersData]);

  useEffect(() => {
    if (upsertSubcontractorSuccess) {
      api.success({
        message: "Subcontractor saved successfully",
      });
      form.resetFields();
      setSubcontractorMembers([]);
      setExistingSubcontractorDisabled(false);
      getSubcontractors();
    }

    if (upsertSubcontractorError) {
      api.error({
        message: "Failed to save subcontractor",
      });
      setSubcontractorMembers([]);
    }
  }, [
    api,
    form,
    upsertSubcontractorSuccess,
    upsertSubcontractorError,
    getSubcontractors,
  ]);

  useEffect(() => {
    if (deleteSubcontractorSuccess) {
      api.success({
        message: "Subcontractor deleted successfully",
      });
      form.resetFields();
      setSubcontractorMembers([]);
      getSubcontractors();
    }

    if (deleteSubcontractorError) {
      api.error({
        message: "Failed to delete subcontractor",
      });
      setSubcontractorMembers([]);
    }
  }, [
    api,
    form,
    deleteSubcontractorSuccess,
    deleteSubcontractorError,
    getSubcontractors,
  ]);

  return (
    <>
      {notificationContextHolder}
      {subcontractorsSuccess ? (
        <>
          <Typography.Title className='subcontractor-setup-title'>
            Subcontractor Setup
          </Typography.Title>
          <Form
            form={form}
            name='subcontractor-setup-form'
            data-testid='subcontractor-setup-form'
            layout='vertical'
            onFinish={handleFinish}
            requiredMark={false}
          >
            <Row gutter={{ xs: 4, sm: 8, md: 16, lg: 20 }}>
              <Col span={8}>
                <Form.Item
                  name='name'
                  data-testid='name'
                  className='subcontractor-setup-input'
                  labelAlign='left'
                  rules={[
                    {
                      required: isEmpty(form.getFieldValue("subcontractor")),
                      message: "Subcontractor name is required.",
                    },
                  ]}
                  label={
                    <>
                      <Space>
                        <Image
                          src={selectProjectStage}
                          alt='New Subcontractor'
                          preview={false}
                          width={22}
                        />
                        <Typography.Text className='subcontractor-setup-title-small'>
                          Enter New Subcontractor Name
                        </Typography.Text>
                      </Space>
                    </>
                  }
                >
                  <Input
                    size='large'
                    onChange={(element) => {
                      const empty = isEmpty(element?.target?.value);

                      if (!empty && !existingSubcontractorDisabled) {
                        setSubcontractorMembers([]);
                        setExistingSubcontractorDisabled(true);
                      } else if (empty && existingSubcontractorDisabled) {
                        setExistingSubcontractorDisabled(false);
                      }
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={2} className='subcontractor-setup-text'>
                <Typography.Paragraph>OR</Typography.Paragraph>
              </Col>
              <Col span={8}>
                <Form.Item
                  name='subcontractor'
                  data-testid='subcontractor'
                  className='subcontractor-setup-select-box'
                  labelAlign='left'
                  label={
                    <>
                      <Space>
                        <Image
                          src={selectProjectStage}
                          alt='Existing Subcontractor'
                          preview={false}
                          width={22}
                        />
                        <Typography.Text className='subcontractor-setup-title-small'>
                          Select Existing Subcontractor
                        </Typography.Text>
                      </Space>
                    </>
                  }
                >
                  <Select
                    key={existingSubcontractorDisabled.toString()}
                    disabled={existingSubcontractorDisabled}
                    defaultValue={""}
                    size='large'
                    options={[
                      { value: "", label: "Select..." },
                      ...(subcontractorsData?.map((t) => ({
                        value: t.id,
                        label: t.name,
                      })) ?? []),
                    ]}
                    onSelect={handleSubcontractorSelect}
                  />{" "}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={{ xs: 4, sm: 8, md: 16, lg: 20 }}>
              <Col span={8}>
                <Form.Item
                  name='type'
                  label='Subcontractor Type'
                  rules={[
                    {
                      required: true,
                      message: "Subcontractor type is required.",
                    },
                  ]}
                >
                  <Select
                    size='large'
                    options={[
                      { value: "", label: "Select..." },
                      ...ProjectTypes,
                    ]}
                    onSelect={(value) => {
                      form.setFieldValue("type", value);
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={{ xs: 4, sm: 8, md: 16, lg: 20 }}>
              <Col span={18}>
                <Button
                  type='primary'
                  onClick={() => setOpen(true)}
                  style={{ marginBottom: 16 }}
                >
                  Add a member
                </Button>
                <AddSubcontractorMemberModal
                  open={open}
                  onAdd={handleAdd}
                  onCancel={() => setOpen(false)}
                  subcontractorId={form.getFieldValue("subcontractor")}
                />
                <Table
                  rowClassName={() => "editable-row"}
                  dataSource={subcontractorMembers}
                  columns={columns}
                  pagination={false}
                  className='subcontractor-setup-member-table'
                />
              </Col>
            </Row>
            <Row gutter={{ xs: 4, sm: 8, md: 16, lg: 20 }}>
              <Col span={5}>
                <SubmitButton
                  className='subcontractor-setup-form-submit-button'
                  testId='subcontractor-setup-form-submit-button'
                  form={form}
                >
                  Save Subcontractor
                </SubmitButton>
              </Col>
              <Col span={5}>
                <Button
                  danger
                  type='primary'
                  size='large'
                  block
                  className='subcontractor-setup-form-delete-button'
                  onClick={() => {
                    deleteSubcontractor(form.getFieldValue("subcontractor"));
                  }}
                  disabled={
                    existingSubcontractorDisabled ||
                    isEmpty(form.getFieldValue("subcontractor"))
                  }
                >
                  Delete Subcontractor
                </Button>
              </Col>
            </Row>
          </Form>
        </>
      ) : (
        <Skeleton />
      )}
    </>
  );
};
