import { GetProp, TableProps } from "antd";
import { utilityService } from "shared/services";
import { IProjectDetails } from "projects/models";

type ColumnsType<T extends object> = GetProp<TableProps<T>, "columns">;

export const ProjectsDetailsColumns: ColumnsType<IProjectDetails> = [
  {
    title: "",
    dataIndex: "id",
    width: 100,
    fixed: "left",
    render: (value, record, index) => index + 1,
  },
  {
    title: "Project",
    dataIndex: "projectName",
    render: (value, record, index) => utilityService.truncateField(value),
  },
  {
    title: "Project Value",
    dataIndex: "projectValue",
    render: (value, record, index) =>
      `$${utilityService.statisticsFormatter(value)}`,
  },
];
