import { Col, Row, Image } from "antd";

import { StatisticCard } from "shared/components";
import { utilityService } from "shared/services";
import projectsIcon from "projects/assets/images/projects.svg";
import actualSalesIcon from "sales/assets/images/actual_sales.svg";
import newSalesIcon from "sales/assets/images/new_sales.svg";

import "./SalesPipelineProjects.scss";
import { useBoundStore } from "store";

export const SalesPipelineProjects = () => {
  const { salesPipelineProjects } = useBoundStore();
  return (
    <Row
      className='sales-pipeline-projects'
      gutter={{ xs: 4, sm: 8, md: 16, lg: 20 }}
      justify={"space-between"}
    >
      <Col span={8}>
        <StatisticCard
          title='Number of Projects'
          value={salesPipelineProjects?.totalProjects ?? 0}
          formatter={utilityService.statisticsFormatter}
          backgroundColor='#FFF4DE'
          Icon={() => (
            <Image
              src={projectsIcon}
              alt='Projects Icon'
              preview={false}
              width={35}
            />
          )}
          textAlign='left'
        />
      </Col>
      <Col span={8}>
        <StatisticCard
          title='Projects Value'
          value={salesPipelineProjects?.totalProjectsValue ?? 0}
          prefix='$'
          formatter={utilityService.statisticsFormatter}
          backgroundColor='#E2FFF3'
          Icon={() => (
            <Image
              src={actualSalesIcon}
              alt='Sales Icon'
              preview={false}
              width={35}
            />
          )}
          textAlign='left'
        />
      </Col>
      <Col span={8}>
        <StatisticCard
          title='Projects Closing < 30 Days'
          value={salesPipelineProjects?.totalProjectsClosingInThirtyDays ?? 0}
          precision={0}
          prefix='$'
          formatter={utilityService.statisticsFormatter}
          backgroundColor='#EBF3FE'
          Icon={() => (
            <Image
              src={newSalesIcon}
              alt='New Sales Icon'
              preview={false}
              width={35}
            />
          )}
          textAlign='left'
        />
      </Col>
    </Row>
  );
};
