import {
  DatePicker,
  Divider,
  Flex,
  Form,
  FormInstance,
  Radio,
  Row,
  Select,
  Typography,
} from "antd";

import "./ProjectAccountingInformation.scss";
import { ProjectFormFieldItem } from "../project-form-field-item/ProjectFormFieldItem";
import dayjs from "dayjs";
import { isEmpty } from "lodash";
import { useDisplayFieldByStage } from "projects/hooks";
import { DefermentOptions, FinanceLenders } from "projects/constants";
import { DateFormat } from "shared/constants";
import { MoneyInput, PercentageInput } from "shared/components";

type Props = {
  form: FormInstance;
};

const fieldCategory = "accountInformationFields";

export const ProjectAccountingInformation = ({ form }: Props) => {
  const validationStage = Form.useWatch("stage", form);
  const { displayFieldCategoryByStage } = useDisplayFieldByStage();

  return displayFieldCategoryByStage(fieldCategory) ? (
    <div className='project-accounting-information-container'>
      <Flex>
        <Typography.Title
          className='project-accounting-information-title'
          level={2}
        >
          Accounting Information
        </Typography.Title>
      </Flex>
      <Divider className='project-accounting-information-divider' />
      <Row justify='start' gutter={{ xs: 4, sm: 8, md: 16, lg: 20 }}>
        <ProjectFormFieldItem
          name='soldTotalProjectValue'
          label='Sold Total Project Value'
          fieldCategory={fieldCategory}
          colSpan={4}
          validationStage={validationStage}
        >
          <MoneyInput size='small' />
        </ProjectFormFieldItem>
        <ProjectFormFieldItem
          name='soldTotalIncentive'
          label='Sold Total Incentive'
          fieldCategory={fieldCategory}
          colSpan={4}
          validationStage={validationStage}
        >
          <MoneyInput size='small' />
        </ProjectFormFieldItem>
        <ProjectFormFieldItem
          name='soldTotalCustomerPortion'
          label='Sold Total Customer Portion'
          fieldCategory={fieldCategory}
          colSpan={4}
          validationStage={validationStage}
        >
          <MoneyInput size='small' />
        </ProjectFormFieldItem>
        <ProjectFormFieldItem
          name='soldLtgValue'
          label='Sold LTG Value'
          fieldCategory={fieldCategory}
          colSpan={4}
          validationStage={validationStage}
        >
          <MoneyInput size='small' />
        </ProjectFormFieldItem>
        <ProjectFormFieldItem
          name='soldHvacValue'
          label='Sold HVAC Value'
          fieldCategory={fieldCategory}
          colSpan={4}
          validationStage={validationStage}
        >
          <MoneyInput size='small' />
        </ProjectFormFieldItem>
        <ProjectFormFieldItem
          name='soldRefrigerationValue'
          label='Sold Refrigeration Value'
          fieldCategory={fieldCategory}
          colSpan={4}
          validationStage={validationStage}
        >
          <MoneyInput size='small' />
        </ProjectFormFieldItem>
        <ProjectFormFieldItem
          name='financeLender'
          label='Finance Lender'
          fieldCategory={fieldCategory}
          colSpan={4}
          validationStage={validationStage}
        >
          <Select
            size='small'
            options={[{ value: "", label: "Select..." }, ...FinanceLenders]}
            onSelect={(value) => {
              form.setFieldValue("financeLender", value);
            }}
          />
        </ProjectFormFieldItem>
        <ProjectFormFieldItem
          name='financeExpirationDate'
          label='Finance Expiration Date'
          fieldCategory={fieldCategory}
          colSpan={4}
          validationStage={validationStage}
          getValueProps={(i) => ({
            value: isEmpty(i) ? null : dayjs(i, DateFormat),
          })}
        >
          <DatePicker
            allowClear
            size='small'
            onChange={(x) => {
              form.setFieldValue(
                "financeExpirationDate",
                isEmpty(x) ? null : dayjs(x, DateFormat)
              );
            }}
            format={DateFormat}
          />
        </ProjectFormFieldItem>
        <ProjectFormFieldItem
          name='preFunding'
          label='PreFunding'
          fieldCategory={fieldCategory}
          colSpan={4}
          validationStage={validationStage}
        >
          <Radio.Group>
            <Radio value={true}>Yes</Radio>
            <Radio value={false}>No</Radio>
          </Radio.Group>
        </ProjectFormFieldItem>
        <ProjectFormFieldItem
          name='deferment'
          label='Deferment'
          fieldCategory={fieldCategory}
          colSpan={4}
          validationStage={validationStage}
        >
          <Select
            size='small'
            options={[{ value: "", label: "Select..." }, ...DefermentOptions]}
            onSelect={(value) => {
              form.setFieldValue("deferment", value);
            }}
          />
        </ProjectFormFieldItem>
        <ProjectFormFieldItem
          name='fiftyPercentPrefundInvoiceDate'
          label='50% Prefund Invoice Date'
          fieldCategory={fieldCategory}
          colSpan={4}
          validationStage={validationStage}
          getValueProps={(i) => ({
            value: isEmpty(i) ? null : dayjs(i, DateFormat),
          })}
        >
          <DatePicker
            allowClear
            size='small'
            onChange={(x) => {
              form.setFieldValue(
                "fiftyPercentPrefundInvoiceDate",
                isEmpty(x) ? null : dayjs(x, DateFormat)
              );
            }}
            format={DateFormat}
          />
        </ProjectFormFieldItem>
        <ProjectFormFieldItem
          name='twentyFivePercentPrefundInvoiceDate'
          label='25% Prefund Invoice Date'
          fieldCategory={fieldCategory}
          colSpan={4}
          validationStage={validationStage}
          getValueProps={(i) => ({
            value: isEmpty(i) ? null : dayjs(i, DateFormat),
          })}
        >
          <DatePicker
            allowClear
            size='small'
            onChange={(x) => {
              form.setFieldValue(
                "twentyFivePercentPrefundInvoiceDate",
                isEmpty(x) ? null : dayjs(x, DateFormat)
              );
            }}
            format={DateFormat}
          />
        </ProjectFormFieldItem>
        <ProjectFormFieldItem
          name='soldBudgetCosts'
          label='Sold Budget Costs'
          fieldCategory={fieldCategory}
          colSpan={4}
          validationStage={validationStage}
        >
          <MoneyInput size='small' readOnly disabled />
        </ProjectFormFieldItem>
        <ProjectFormFieldItem
          name='soldProfit'
          label='Sold Profit'
          fieldCategory={fieldCategory}
          colSpan={4}
          validationStage={validationStage}
        >
          <MoneyInput size='small' readOnly disabled />
        </ProjectFormFieldItem>
        <ProjectFormFieldItem
          name='soldMargin'
          label='Sold Margin'
          fieldCategory={fieldCategory}
          colSpan={4}
          validationStage={validationStage}
        >
          <PercentageInput size='small' readOnly disabled />
        </ProjectFormFieldItem>
        <ProjectFormFieldItem
          name='billedDepositDate'
          label='Billed Deposit Date'
          fieldCategory={fieldCategory}
          colSpan={4}
          validationStage={validationStage}
          getValueProps={(i) => ({
            value: isEmpty(i) ? null : dayjs(i, DateFormat),
          })}
        >
          <DatePicker
            allowClear
            size='small'
            onChange={(x) => {
              form.setFieldValue(
                "billedDepositDate",
                isEmpty(x) ? null : dayjs(x, DateFormat)
              );
            }}
            format={DateFormat}
          />
        </ProjectFormFieldItem>
        <ProjectFormFieldItem
          name='billedDepositAmount'
          label='Billed Deposit Amount'
          fieldCategory={fieldCategory}
          colSpan={4}
          validationStage={validationStage}
        >
          <MoneyInput size='small' />
        </ProjectFormFieldItem>
        <ProjectFormFieldItem
          name='depositReceivedDate'
          label='Deposit Received Date'
          fieldCategory={fieldCategory}
          colSpan={4}
          validationStage={validationStage}
          getValueProps={(i) => ({
            value: isEmpty(i) ? null : dayjs(i, DateFormat),
          })}
        >
          <DatePicker
            allowClear
            size='small'
            onChange={(x) => {
              form.setFieldValue(
                "depositReceivedDate",
                isEmpty(x) ? null : dayjs(x, DateFormat)
              );
            }}
            format={DateFormat}
          />
        </ProjectFormFieldItem>
        <ProjectFormFieldItem
          name='depositReceivedAmount'
          label='Deposit Received Amount'
          fieldCategory={fieldCategory}
          colSpan={4}
          validationStage={validationStage}
        >
          <MoneyInput size='small' />
        </ProjectFormFieldItem>
        <ProjectFormFieldItem
          name='depositClearedDate'
          label='Deposit Cleared Date'
          fieldCategory={fieldCategory}
          colSpan={4}
          validationStage={validationStage}
          getValueProps={(i) => ({
            value: isEmpty(i) ? null : dayjs(i, DateFormat),
          })}
        >
          <DatePicker
            allowClear
            size='small'
            onChange={(x) => {
              form.setFieldValue(
                "depositClearedDate",
                isEmpty(x) ? null : dayjs(x, DateFormat)
              );
            }}
            format={DateFormat}
          />
        </ProjectFormFieldItem>
        <ProjectFormFieldItem
          name='billedIncentiveDate'
          label='Billed Incentive Date'
          fieldCategory={fieldCategory}
          colSpan={4}
          validationStage={validationStage}
          getValueProps={(i) => ({
            value: isEmpty(i) ? null : dayjs(i, DateFormat),
          })}
        >
          <DatePicker
            allowClear
            size='small'
            onChange={(x) => {
              form.setFieldValue(
                "billedIncentiveDate",
                isEmpty(x) ? null : dayjs(x, DateFormat)
              );
            }}
            format={DateFormat}
          />
        </ProjectFormFieldItem>
        <ProjectFormFieldItem
          name='billedIncentiveAmount'
          label='Billed Incentive Amount'
          fieldCategory={fieldCategory}
          colSpan={4}
          validationStage={validationStage}
        >
          <MoneyInput size='small' />
        </ProjectFormFieldItem>
        <ProjectFormFieldItem
          name='billedIncentiveReceivedDate'
          label='Billed Incentive Received Date'
          fieldCategory={fieldCategory}
          colSpan={4}
          validationStage={validationStage}
          getValueProps={(i) => ({
            value: isEmpty(i) ? null : dayjs(i, DateFormat),
          })}
        >
          <DatePicker
            allowClear
            size='small'
            onChange={(x) => {
              form.setFieldValue(
                "billedIncentiveReceivedDate",
                isEmpty(x) ? null : dayjs(x, DateFormat)
              );
            }}
            format={DateFormat}
          />
        </ProjectFormFieldItem>
        <ProjectFormFieldItem
          name='billedIncentiveReceivedAmount'
          label='Billed Incentive Received Amount'
          fieldCategory={fieldCategory}
          colSpan={4}
          validationStage={validationStage}
        >
          <MoneyInput size='small' />
        </ProjectFormFieldItem>
        <ProjectFormFieldItem
          name='billedFinancedPortionDate'
          label='Billed Financed Portion Date'
          fieldCategory={fieldCategory}
          colSpan={4}
          validationStage={validationStage}
          getValueProps={(i) => ({
            value: isEmpty(i) ? null : dayjs(i, DateFormat),
          })}
        >
          <DatePicker
            allowClear
            size='small'
            onChange={(x) => {
              form.setFieldValue(
                "billedFinancedPortionDate",
                isEmpty(x) ? null : dayjs(x, DateFormat)
              );
            }}
            format={DateFormat}
          />
        </ProjectFormFieldItem>
        <ProjectFormFieldItem
          name='billedFinancedPortionAmount'
          label='Billed Financed Portion Amount'
          fieldCategory={fieldCategory}
          colSpan={4}
          validationStage={validationStage}
        >
          <MoneyInput size='small' />
        </ProjectFormFieldItem>
        <ProjectFormFieldItem
          name='financedPortionReceivedDate'
          label='Financed Portion Received Date'
          fieldCategory={fieldCategory}
          colSpan={4}
          validationStage={validationStage}
          getValueProps={(i) => ({
            value: isEmpty(i) ? null : dayjs(i, DateFormat),
          })}
        >
          <DatePicker
            allowClear
            size='small'
            onChange={(x) => {
              form.setFieldValue(
                "financedPortionReceivedDate",
                isEmpty(x) ? null : dayjs(x, DateFormat)
              );
            }}
            format={DateFormat}
          />
        </ProjectFormFieldItem>
        <ProjectFormFieldItem
          name='financedPortionReceivedAmount'
          label='Financed Portion Received Amount'
          fieldCategory={fieldCategory}
          colSpan={4}
          validationStage={validationStage}
        >
          <MoneyInput size='small' />
        </ProjectFormFieldItem>
        <ProjectFormFieldItem
          name='overageCustomerPortionDate'
          label='Overage Customer Portion Date'
          fieldCategory={fieldCategory}
          colSpan={4}
          validationStage={validationStage}
          getValueProps={(i) => ({
            value: isEmpty(i) ? null : dayjs(i, DateFormat),
          })}
        >
          <DatePicker
            allowClear
            size='small'
            onChange={(x) => {
              form.setFieldValue(
                "overageCustomerPortionDate",
                isEmpty(x) ? null : dayjs(x, DateFormat)
              );
            }}
            format={DateFormat}
          />
        </ProjectFormFieldItem>
        <ProjectFormFieldItem
          name='overageCustomerPortionAmount'
          label='Overage Customer Portion Amount'
          fieldCategory={fieldCategory}
          colSpan={4}
          validationStage={validationStage}
        >
          <MoneyInput size='small' />
        </ProjectFormFieldItem>
        <ProjectFormFieldItem
          name='overageCustomerPortionReceivedDate'
          label='Overage Customer Portion Received Date'
          fieldCategory={fieldCategory}
          colSpan={4}
          validationStage={validationStage}
          getValueProps={(i) => ({
            value: isEmpty(i) ? null : dayjs(i, DateFormat),
          })}
        >
          <DatePicker
            allowClear
            size='small'
            onChange={(x) => {
              form.setFieldValue(
                "overageCustomerPortionReceivedDate",
                isEmpty(x) ? null : dayjs(x, DateFormat)
              );
            }}
            format={DateFormat}
          />
        </ProjectFormFieldItem>
        <ProjectFormFieldItem
          name='overageCustomerPortionReceivedAmount'
          label='Overage Customer Portion Received Amount'
          fieldCategory={fieldCategory}
          colSpan={4}
          validationStage={validationStage}
        >
          <MoneyInput size='small' />
        </ProjectFormFieldItem>
        <ProjectFormFieldItem
          name='accrualDate'
          label='Accrual Date'
          fieldCategory={fieldCategory}
          colSpan={4}
          validationStage={validationStage}
          getValueProps={(i) => ({
            value: isEmpty(i) ? null : dayjs(i, DateFormat),
          })}
        >
          <DatePicker
            allowClear
            size='small'
            onChange={(x) => {
              form.setFieldValue(
                "accrualDate",
                isEmpty(x) ? null : dayjs(x, DateFormat)
              );
            }}
            format={DateFormat}
          />
        </ProjectFormFieldItem>
      </Row>
    </div>
  ) : null;
};
