import { auditService } from "audit/services";
import { useState } from "react";

import { useBoundStore } from "store";

export const useGetAuditInfo = () => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState<Error | undefined>(undefined);
  const { setAuditAppointments } = useBoundStore();

  const getAuditInfo = async () => {
    try {
      setLoading(true);
      setSuccess(false);
      setError(undefined);

      const auditInfo = await auditService.getAuditInfo();

      setAuditAppointments(auditInfo?.auditAppointments);

      setSuccess(true);
    } catch (error) {
      setSuccess(false);
      setError(error as Error);
    } finally {
      setLoading(false);
    }
  };

  return {
    getAuditInfo,
    loading,
    success,
    error,
  };
};
