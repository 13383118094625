import { Card } from "antd";

import "./FinanceManagerDashboardNotesCard.scss";
import { NotesList } from "notes/components";

export const FinanceManagerDashboardNotesCard = () => {
  return (
    <Card className='finance-manager-dashboard-notes-card-container'>
      <NotesList title='My Project Notes' height={875} />
    </Card>
  );
};
