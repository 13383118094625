import { Link } from "react-router-dom";

import logoFull from "../../assets/images/tracpro.svg";
import logoCollapsed from "../../assets/images/tracpro-collapsed.svg";

type Props = {
  className?: string;
  collapsed?: boolean;
};

export const Logo = ({ className, collapsed = false }: Props) => {
  const logo = collapsed ? logoCollapsed : logoFull;
  
  return (
    <Link to='/'>
      <img src={logo} alt='logo' className={className} />
    </Link>
  );
};
