import { useState } from "react";

import { authService } from "auth/services";
import { isEmpty } from "lodash";
import { createSearchParams, useNavigate } from "react-router-dom";
import { VerificationTypes } from "auth/constants";
import { useBoundStore } from "store";

export const useUpdateLoginAndSecuritySettings = () => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState<Error | undefined>(undefined);
  const navigate = useNavigate();
  const user = useBoundStore((state) => state.user);

  const updateLoginAndSecuritySettings = async (
    email: string,
    oldPassword: string,
    newPassword: string
  ) => {
    try {
      setLoading(true);
      setSuccess(false);
      setError(undefined);

      if (!isEmpty(oldPassword) && !isEmpty(newPassword))
        await authService.changePassword(oldPassword, newPassword);

      if (!isEmpty(email) && !isEmpty(user?.email)) {
        await authService.changeEmail(email);

        navigate({
          pathname: "/verify",
          search: createSearchParams({
            email: user.email ?? "",
            type: VerificationTypes.EMAIL,
          }).toString(),
        });
      }

      setSuccess(true);
    } catch (error) {
      setSuccess(false);
      setError(error as Error);
    } finally {
      setLoading(false);
    }
  };

  return {
    updateLoginAndSecuritySettings,
    loading,
    success,
    error,
  };
};
