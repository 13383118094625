import { projectService } from "projects/services";
import { useState } from "react";

import { useBoundStore } from "store";

export const useGetProjectsInfo = () => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState<Error | undefined>(undefined);
  const {
    setProjectsByStage,
    setDeficientProjects,
    setProjectsTimeTrackingSummary,
    setHvacInstallationsBySubcontractor,
    setLightingInstallationsBySubcontractor,
    setOutstandingContractorQuotes,
    setEngineeringProjects,
    setInstallationsBySubcontractor,
    setCloseoutsBySubcontractor,
    setProjectsInCloseoutStage,
    setProjectsDetails,
    setInstallationsRecap,
    setSubcontractorProjects,
  } = useBoundStore();

  const getProjectsInfo = async () => {
    try {
      setLoading(true);
      setSuccess(false);
      setError(undefined);

      const projectsInfo = await projectService.getProjectsInfo();

      setProjectsByStage(projectsInfo?.projectsByStage);
      setProjectsTimeTrackingSummary(projectsInfo?.projectsTimeTrackingSummary);
      setDeficientProjects(projectsInfo?.deficientProjects);
      setHvacInstallationsBySubcontractor(
        projectsInfo?.hvacInstallationsBySubcontractor
      );
      setLightingInstallationsBySubcontractor(
        projectsInfo?.lightingInstallationsBySubcontractor
      );
      setOutstandingContractorQuotes(projectsInfo?.outstandingContractorQuotes);
      setEngineeringProjects(projectsInfo?.engineeringProjects);
      setInstallationsBySubcontractor(
        projectsInfo?.installationsBySubcontractor
      );
      setCloseoutsBySubcontractor(projectsInfo?.closeoutsBySubcontractor);
      setProjectsInCloseoutStage(projectsInfo?.projectsInCloseoutStage);
      setProjectsDetails(projectsInfo?.projectsDetails);
      setSubcontractorProjects(projectsInfo?.subcontractorProjects);
      setInstallationsRecap(projectsInfo?.installationsRecap);

      setSuccess(true);
    } catch (error) {
      setSuccess(false);
      setError(error as Error);
    } finally {
      setLoading(false);
    }
  };

  return {
    getProjectsInfo,
    loading,
    success,
    error,
  };
};
