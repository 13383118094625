import { Col, Row, Typography } from "antd";
import { Cell, Legend, Pie, PieChart, ResponsiveContainer } from "recharts";

import { useBoundStore } from "store";

import "./ProjectsByStage.scss";
import { utilityService } from "shared/services";

type Props = {
  dataLoading?: boolean;
};

export const ProjectsByStage = ({ dataLoading }: Props) => {
  const { projectsByStage } = useBoundStore();
  const COLORS = ["#13B76E", "#FAF119", "#6499FF", "#7041A9"];

  return (
    <>
      <Row>
        <Typography.Title className='projects-by-stage-title' level={3}>
          Projects By Stage
        </Typography.Title>
      </Row>
      <Row>
        <Col span={24}>
          <ResponsiveContainer width='100%' height={400}>
            <PieChart>
              <Pie
                dataKey='actualSales'
                nameKey='stage'
                data={projectsByStage}
                innerRadius={70}
                outerRadius={100}
              >
                {projectsByStage?.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
              <Legend
                layout='vertical'
                verticalAlign='bottom'
                payload={projectsByStage?.map((item, index) => ({
                  id: item.stage,
                  type: "square",
                  value: `${item.stage} (${item.totalProjects ?? 0}) $${utilityService.statisticsFormatter(item.actualSales)}`,
                  color: COLORS[index % COLORS.length],
                }))}
                wrapperStyle={{ left: 0 }}
              />
            </PieChart>
          </ResponsiveContainer>
        </Col>
      </Row>
    </>
  );
};
