import React from "react";
import { Form, Select, Tag } from "antd";
import type { SelectProps } from "antd";

import "./MultiSelectBox.scss";
import { ISelectOption } from "shared/models";
import { Rule } from "antd/es/form";

type TagRender = SelectProps["tagRender"];

type Props = {
  placeHolder?: string;
  options: ISelectOption[];
  name: string;
  label: React.ReactNode;
  rules?: Rule[];
  minWidth?: number;
};

export const tagRender: TagRender = (props) => {
  const { label, closable, onClose } = props;
  const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };
  return (
    <Tag
      color='#13b76e'
      onMouseDown={onPreventMouseDown}
      closable={closable}
      onClose={onClose}
      style={{ marginInlineEnd: 4 }}
    >
      {label}
    </Tag>
  );
};

export const MultiSelectBox = ({
  placeHolder = "Select...",
  options,
  name,
  label,
  rules,
  minWidth = 200,
}: Props) => (
  <Form.Item name={name} label={label} rules={rules}>
    <Select
      mode='multiple'
      tagRender={tagRender}
      options={options}
      className='multi-select-box'
      placeholder={placeHolder}
      style={{ minWidth }}
    />
  </Form.Item>
);
