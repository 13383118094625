import { GetProp, TableProps } from "antd";
import { utilityService } from "shared/services";
import { IProjectInCloseoutStage } from "projects/models";

type ColumnsType<T extends object> = GetProp<TableProps<T>, "columns">;

export const ProjectsInCloseoutStageColumns: ColumnsType<IProjectInCloseoutStage> =
  [
    {
      title: "",
      dataIndex: "id",
      width: 100,
      fixed: "left",
      render: (value, record, index) => index + 1,
    },
    {
      title: "Project",
      dataIndex: "projectName",
      render: (value, record, index) => utilityService.truncateField(value),
    },
    {
      title: "Latest Project Note",
      dataIndex: "latestProjectNote",
      render: (value, record, index) =>
        utilityService.truncateField(value?.content),
    },
    {
      title: "Project Installation Completed Date",
      dataIndex: "projectInstallationCompletedDate",
      render: (value, record, index) => utilityService.dateFormatter(value),
    },
    {
      title: "Completion Document Sent To Customer",
      dataIndex: "completionDocumentSentToCustomer",
      render: (value, record, index) => utilityService.dateFormatter(value),
    },
    {
      title: "Total Project Value",
      dataIndex: "totalProjectValue",
      render: (value, record, index) =>
        `$${utilityService.statisticsFormatter(value)}`,
    },
  ];
