import {
  AdvancedMarker,
  Map,
  Pin,
  useMap,
  useMapsLibrary,
} from "@vis.gl/react-google-maps";
import { useGetGeocoder } from "audit/hooks/useGetGeocoder";
import { useEffect } from "react";
import { isEmpty } from "lodash";
import { useBoundStore } from "store";

import "./AuditMap.scss";
import { EmptyData } from "shared/components";

type Poi = {
  key: string;
  location: google.maps.LatLngLiteral;
  scheduled: boolean;
};

export const AuditMap = () => {
  const defaultZoom = 9;
  const { auditAppointments } = useBoundStore();
  const map = useMap();
  const geocodingLib = useMapsLibrary(
    "geocoding"
  ) as google.maps.GeocodingLibrary;
  const { getPois, success, error, data } = useGetGeocoder();

  useEffect(() => {
    if (isEmpty(map) || isEmpty(geocodingLib) || isEmpty(auditAppointments))
      return;

    getPois(geocodingLib, auditAppointments);
  }, [map, geocodingLib, auditAppointments]);

  useEffect(() => {
    if (success) {
      map?.setZoom(defaultZoom);
      map?.setCenter(data[0]?.location);
    }
  }, [success, error, data]);

  return (
    <>
      {!isEmpty(data) ? (
        <Map
          mapId={process.env.REACT_APP_GOOGLE_MAPS_MAP_ID}
          defaultZoom={defaultZoom}
          defaultCenter={data[0]?.location ?? { lat: 0, lng: 0 }}
          gestureHandling={"greedy"}
          disableDefaultUI
          style={{ width: "100%", height: "100vh" }}
        >
          {data?.map((poi: Poi) => {
            const color = poi.scheduled ? "#13B76E" : "#FAF119";

            return (
              <AdvancedMarker key={poi.key} position={poi.location}>
                <Pin
                  background={color}
                  glyphColor='#ffffff'
                  borderColor='#000000'
                />
              </AdvancedMarker>
            );
          })}
        </Map>
      ) : (
        <EmptyData />
      )}
    </>
  );
};
