import { DatePicker, Form, notification, Select } from "antd";
import dayjs, { Dayjs } from "dayjs";
import quarterOfYear from "dayjs/plugin/quarterOfYear";
import { useEffect } from "react";

import "./SalesTargetTimePeriods.scss";
import { useBoundStore } from "store";
import { UtilityPrograms } from "projects/constants";
import { useUpdateUser } from "users/hooks";

dayjs.extend(quarterOfYear);

type Props = {
  name: string;
  onChange?: () => void;
};

interface Values {
  salesTargetYear: Dayjs;
  salesTargetMonth: Dayjs;
  salesTargetQuarter: Dayjs;
  utilityProgram: string;
}

export const SalesTargetTimePeriods = ({ name, onChange }: Props) => {
  const [form] = Form.useForm();
  const { updateUser, error, success } = useUpdateUser();
  const [api, notificationContextHolder] = notification.useNotification();
  const { user } = useBoundStore();

  const onChangeHandler = (
    _: Values,
    {
      salesTargetYear,
      salesTargetMonth,
      salesTargetQuarter,
      utilityProgram,
    }: Values
  ) => {
    const year = salesTargetYear?.year() ?? null;
    const month = salesTargetMonth?.month() ?? null;
    const quarter = salesTargetQuarter?.quarter() ?? null;
    const utility = utilityProgram ?? null;

    updateUser({
      configuration: {
        salesTargetYear: year,
        salesTargetMonth: month,
        salesTargetQuarter: quarter,
        utilityProgram: utility,
      },
    });
  };

  useEffect(() => {
    if (success && onChange) {
      onChange();
    } else if (error) {
      api.error({
        message: "Update user sales target error",
        description: error?.message,
      });
    }
  }, [success, error, api]);

  return (
    <>
      {notificationContextHolder}
      <Form
        form={form}
        name={`${name}_form`}
        data-testid={`${name}_testid`}
        onValuesChange={onChangeHandler}
        requiredMark={false}
        layout='inline'
        initialValues={{
          salesTargetYear:
            user?.configuration?.salesTargetYear !== undefined
              ? dayjs().year(user?.configuration?.salesTargetYear ?? 0)
              : undefined,
          salesTargetMonth:
            user?.configuration?.salesTargetMonth !== undefined
              ? dayjs().month(user?.configuration?.salesTargetMonth ?? 0)
              : undefined,
          salesTargetQuarter:
            user?.configuration?.salesTargetQuarter !== undefined
              ? dayjs().quarter(user?.configuration?.salesTargetQuarter ?? 0)
              : undefined,
          utilityProgram: user?.configuration?.utilityProgram,
        }}
      >
        <Form.Item
          name='salesTargetYear'
          data-testid={`${name}_salesTargetYear_testid`}
        >
          <DatePicker
            placeholder='Year'
            mode='year'
            picker='year'
            allowClear
            size='small'
            variant='borderless'
            minDate={dayjs()}
          />
        </Form.Item>
        <Form.Item
          name='salesTargetQuarter'
          data-testid={`${name}_salesTargetQuarter_testid`}
        >
          <DatePicker
            placeholder='Quarter'
            mode='quarter'
            picker='quarter'
            allowClear
            size='small'
            variant='borderless'
            format={(value) => `Q${value.format("Q")}`}
          />
        </Form.Item>
        <Form.Item
          name='salesTargetMonth'
          data-testid={`${name}_salesTargetMonth_testid`}
        >
          <DatePicker
            placeholder='Month'
            mode='month'
            picker='month'
            allowClear
            size='small'
            variant='borderless'
            format='MMMM'
          />
        </Form.Item>
        <Form.Item
          name='utilityProgram'
          data-testid={`${name}_utilityProgram_testid`}
        >
          <Select
            allowClear
            placeholder='Utility Program'
            size='small'
            variant='borderless'
            options={UtilityPrograms}
            onSelect={(value) => {
              form.setFieldValue("utilityProgram", value);
            }}
          />
        </Form.Item>
      </Form>
    </>
  );
};
