import { Row, Table, TableProps, Typography } from "antd";
import "./OpenProjectsDepositsReceived.scss";
import React from "react";
import { useBoundStore } from "store";
import { OpenProjectsDepositsReceivedColumns } from "financials/constants";
import { IOpenProjectsDepositsReceived } from "financials/models";
import { utilityService } from "shared/services";

type Props = {
  dataLoading?: boolean;
};

export const OpenProjectsDepositsReceived = ({ dataLoading }: Props) => {
  const tblRef: Parameters<typeof Table>[0]["ref"] = React.useRef(null);
  const tableColumns = OpenProjectsDepositsReceivedColumns.map((item) => ({
    ...item,
  }));
  const tableProps: TableProps<IOpenProjectsDepositsReceived> = {
    loading: dataLoading,
  };
  const { openProjectsDepositsReceived } = useBoundStore();

  return (
    <>
      <Row>
        <Typography.Title
          className='open-projects-deposits-received-title'
          level={3}
        >
          Open Projects Deposits Received
        </Typography.Title>
      </Row>
      <Row>
        <Table
          {...tableProps}
          columns={tableColumns}
          dataSource={openProjectsDepositsReceived}
          className='open-projects-deposits-received-table'
          ref={tblRef}
          rowKey='id'
          pagination={false}
          summary={(pageData) => {
            let totalProjectAmount = 0;
            let totalDepositAmountReceived = 0;

            pageData.forEach(({ projectAmount, depositAmountReceived }) => {
              totalProjectAmount += Number(projectAmount ?? 0);
              totalDepositAmountReceived += Number(depositAmountReceived ?? 0);
            });

            return (
              <>
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0}></Table.Summary.Cell>
                  <Table.Summary.Cell index={1}>
                    <Typography.Text strong>TOTALS</Typography.Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={2}></Table.Summary.Cell>
                  <Table.Summary.Cell index={3}></Table.Summary.Cell>
                  <Table.Summary.Cell index={4}>
                    <Typography.Text strong>
                      ${utilityService.statisticsFormatter(totalProjectAmount)}
                    </Typography.Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={5}>
                    <Typography.Text strong>
                      $
                      {utilityService.statisticsFormatter(
                        totalDepositAmountReceived
                      )}
                    </Typography.Text>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </>
            );
          }}
        />
      </Row>
    </>
  );
};
