import { Link } from "react-router-dom";

import "./ForgotPasswordLink.scss";

export const ForgotPasswordLink = () => {
  return (
    <Link to='/recovery/password' className='forgot-password-link'>
      Forgot Password
    </Link>
  );
};
