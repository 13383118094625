import { isEmpty } from "lodash";
import { IDisplayFieldConfig } from "projects/models";
import { useBoundStore } from "store";

export const useDisplayFieldByStage = () => {
  const { user, stage } = useBoundStore();

  const getDisplayFieldConfigByStage = () =>
    user?.role?.configuration?.project?.stage?.find(
      (x: IDisplayFieldConfig) =>
        x?.name?.toLowerCase() === (isEmpty(stage) ? "default" : stage)
    );

  const displayFieldByStage = (fieldCategory: string, fieldName: string) => {
    try {
      const config = getDisplayFieldConfigByStage();

      if (!config) return false;

      return config?.display[`${fieldCategory}`]?.includes(fieldName);
    } catch (error) {
      console.log(error);
    }
  };

  const displayFieldCategoryByStage = (fieldCategory: string) => {
    try {
      const config = getDisplayFieldConfigByStage();

      if (!config) return false;

      return !isEmpty(config?.display[`${fieldCategory}`]);
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  return {
    displayFieldByStage,
    displayFieldCategoryByStage,
  };
};
