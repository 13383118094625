import { Checkbox, Col, Form, Input, Row } from "antd";

import { PhoneNumber, SubmitButton } from "shared/components";
import { SplitPageLayout } from "shared/components";
import { SignInLink } from "../../components";
import { useSignUp } from "../../hooks";

export const SignUp = () => {
  const { signUp, error } = useSignUp();
  const [form] = Form.useForm();

  const onFinishHandler = async (values: any) => {
    const email = values.email.trim();
    const password = values.password.trim();
    const firstName = values.firstName.trim();
    const lastName = values.lastName.trim();
    const phoneNumber = `+1${values.phoneNumber.trim()}`;

    await signUp(email, password, firstName, lastName, phoneNumber);
    form.resetFields();
  };

  return (
    <SplitPageLayout
      title='Sign Up'
      subtitle="Let's get you all setup so you can access your personal account."
      error={error}
    >
      <Form
        form={form}
        name='sign-up-form'
        data-testid='sign-up-form'
        layout='vertical'
        onFinish={onFinishHandler}
        requiredMark={false}
      >
        <Row gutter={{ xs: 4, sm: 8, md: 16, lg: 20 }}>
          <Col span={12}>
            <Form.Item
              name='firstName'
              data-testid='firstName'
              label='First Name'
              rules={[
                {
                  required: true,
                  message: "Please enter your first name.",
                },
                {
                  type: "string",
                  max: 50,
                  message: "Your first name must be 50 characters or less.",
                },
              ]}
            >
              <Input size='large' />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name='lastName'
              data-testid='lastName'
              label='Last Name'
              rules={[
                {
                  required: true,
                  message: "Please enter your last name.",
                },
                {
                  type: "string",
                  max: 50,
                  message: "Your last name must be 50 characters or less.",
                },
              ]}
            >
              <Input size='large' />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={{ xs: 4, sm: 8, md: 16, lg: 20 }}>
          <Col span={12}>
            {" "}
            <Form.Item
              name='email'
              data-testid='email'
              label='Email'
              rules={[
                {
                  required: true,
                  type: "email",
                  message: "Please enter a valid email address.",
                },
              ]}
            >
              <Input size='large' />
            </Form.Item>
          </Col>
          <Col span={12}>
            <PhoneNumber />
          </Col>
        </Row>
        <Form.Item
          name='password'
          data-testid='password'
          label='Password'
          rules={[
            {
              required: true,
              message: "Please enter a password.",
            },
            {
              type: "string",
              min: 8,
              message:
                "Your password is too short. It should be at least 8 characters long.",
            },
            {
              type: "string",
              pattern: /[0-9]/,
              message: "Password requires a number.",
            },
            {
              type: "string",
              pattern: /[a-z]/,
              message: "Password requires a lowercase letter.",
            },
            {
              type: "string",
              pattern: /[A-Z]/,
              message: "Password requires an uppercase letter.",
            },
            {
              type: "string",
              pattern: /[^\w]/,
              message: "Password requires a special character.",
            },
          ]}
        >
          <Input.Password size='large' />
        </Form.Item>
        <Form.Item
          name='confirm-password'
          data-testid='confirm-password'
          label='Confirm Password'
          rules={[
            {
              required: true,
              message: "Please confirm your password.",
            },
            ({ getFieldValue }) => ({
              async validator(_, value) {
                const password = getFieldValue("password") ?? "";

                if (value?.trim() !== password.trim()) {
                  return Promise.reject(
                    new Error("Your passwords are not matching.")
                  );
                }

                return Promise.resolve();
              },
            }),
          ]}
        >
          <Input.Password size='large' />
        </Form.Item>
        <Form.Item
          name='termsAndConditions'
          className='sign-up-termsAndConditions'
          data-testid='sign-up-termsAndConditions'
          valuePropName='checked'
          noStyle
          rules={[
            {
              validator: async (_, checked) => {
                if (!checked) {
                  return Promise.reject(
                    new Error("Please accept the terms & conditions.")
                  );
                }

                return Promise.resolve();
              },
            },
          ]}
        >
          <Checkbox>
            I agree to all the{" "}
            <a
              href='https://mmenergysolutions.com/terms-and-conditions/'
              target='_blank'
              rel='noopener noreferrer'
            >
              Terms & Conditions
            </a>{" "}
            and{" "}
            <a
              href='https://mmenergysolutions.com/privacy-policy/'
              target='_blank'
              rel='noopener noreferrer'
            >
              Privacy Policies
            </a>
          </Checkbox>
        </Form.Item>
        <SubmitButton
          className='sign-up-button'
          testId='sign-up-button'
          form={form}
        >
          Create Account
        </SubmitButton>
      </Form>
      <SignInLink />
    </SplitPageLayout>
  );
};
