import { useCallback, useState } from "react";
import { ISelectOption } from "shared/models";
import { subcontractorService } from "subcontractors/services";

export const useGetSubcontractorMemberSelectOptions = () => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState<Error | undefined>(undefined);
  const [data, setData] = useState<ISelectOption[] | undefined>();

  const getSubcontractorMemberSelectOptions = useCallback(
    async (id?: string) => {
      try {
        setLoading(true);
        setSuccess(false);
        setData(undefined);
        setError(undefined);

        const options =
          await subcontractorService.getSubcontractorMemberSelectOptions(id);

        setData(options);
        setSuccess(true);
      } catch (error) {
        setSuccess(false);
        setError(error as Error);
      } finally {
        setLoading(false);
      }
    },
    []
  );

  return {
    getSubcontractorMemberSelectOptions,
    loading,
    success,
    error,
    data,
  };
};
