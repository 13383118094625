import { Col, Row, Typography, Card, Skeleton } from "antd";

import "./SalesRepDashboardSalesPipelineCard.scss";
import { SalesTargetTimePeriods } from "users/components";

import { SalesPipelineProjects } from "sales/components";
import { PipelineByProjectsStage } from "sales/components/pipeline-by-projects-stage/PipelineByProjectsStage";
import { PipelineDetails } from "sales/components/pipeline-details/PipelineDetails";

type Props = {
  onSalesTargetTimePeriodChange?: () => void;
  loaded?: boolean;
};

export const SalesRepDashboardSalesPipelineCard = ({
  onSalesTargetTimePeriodChange,
  loaded,
}: Props) => {
  return (
    <Card className='sales-rep-dashboard-sales-pipeline-card-container'>
      {loaded ? (
        <>
          <Row
            gutter={{ xs: 4, sm: 8, md: 16, lg: 20 }}
            justify={"space-between"}
          >
            <Col>
              <Typography.Title
                className='sales-rep-dashboard-sales-pipeline-card-title'
                level={3}
              >
                My Sales Pipeline
              </Typography.Title>
            </Col>
            <Col>
              <SalesTargetTimePeriods
                name='sales-target-time-periods'
                onChange={onSalesTargetTimePeriodChange}
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <SalesPipelineProjects />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <PipelineDetails />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <PipelineByProjectsStage />
            </Col>
          </Row>
        </>
      ) : (
        <Skeleton />
      )}
    </Card>
  );
};
