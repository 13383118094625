import { Col, notification, Row } from "antd";

import "./AuditAdminDashboard.scss";
import { useEffect } from "react";
import { useGetAuditInfo } from "audit/hooks";
import { AuditAppointmentsCard } from "./audit-appointments-card/AuditAppointmentsCard";
import { AuditMapCard } from "./audit-map-card/AuditMapCard";
import { AuditAdminDashboardNotesCard } from "./audit-admin-dashboard-notes-card/AuditAdminDashboardNotesCard";

export const AuditAdminDashboard = () => {
  const { getAuditInfo, error, success } = useGetAuditInfo();
  const [api, notificationContextHolder] = notification.useNotification();

  useEffect(() => {
    getAuditInfo();
  }, []);

  useEffect(() => {
    if (error) {
      api.error({
        message: "Error",
        description: error?.message,
      });
    }
  }, [error, api]);

  return (
    <>
      {notificationContextHolder}
      <Col span={24} className='audit-admin-dashboard'>
        <Row
          gutter={{ xs: 4, sm: 8, md: 16, lg: 20 }}
          justify={"space-between"}
        >
          <Col span={16}>
            <AuditMapCard loaded={success} />
          </Col>
          <Col span={8}>
            <AuditAdminDashboardNotesCard />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <AuditAppointmentsCard loaded={success} />
          </Col>
        </Row>
      </Col>
    </>
  );
};
