import { useState } from "react";

import { authService } from "../services";
import { VerificationTypes } from "auth/constants";
import { IVerifyCodeResult } from "auth/models";

export const useVerifyCode = () => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [data, setData] = useState<IVerifyCodeResult | undefined>(undefined);
  const [error, setError] = useState<Error | undefined>(undefined);

  const verifyCode = async (
    email: string,
    verificationCode: string,
    verificationType: string
  ) => {
    try {
      setLoading(true);
      setSuccess(false);
      setError(undefined);
      setData(undefined);

      switch (verificationType) {
        case VerificationTypes.SIGN_UP:
          await authService.confirmSignUp(email, verificationCode);
          setData({
            description:
              "Your account verification has been successfully completed. You can now enjoy full access to all the features and benefits that our platform offers.",
            message: "Account verification complete",
          });
          break;

        default:
          await authService.verifyAttribute(verificationType, verificationCode);
          setData({
            description:
              "Your verification has been successfully completed. You can now enjoy full access to all the features and benefits that our platform offers.",
            message: "Verification complete",
          });
          break;
      }

      setSuccess(true);
    } catch (error) {
      setSuccess(false);
      setError(error as Error);
    } finally {
      setLoading(false);
    }
  };

  return {
    verifyCode,
    loading,
    success,
    error,
    data,
  };
};
