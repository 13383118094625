import React, { useCallback, useEffect, useMemo, useState } from "react";
import { CaretDownFilled, CaretRightFilled } from "@ant-design/icons";
import { Space, Table } from "antd";
import { isEmpty } from "lodash";
import { useSearchParams } from "react-router-dom";

import "./ProjectList.scss";
import { ProjectFilters } from "../project-filters/ProjectFilters";
import { IProject } from "projects/models";
import { useGetProjects, useUpdateProject } from "projects/hooks";
import { getProjectColumns } from "projects/constants";
import { eachPropertyNulltoUndefined, ProjectListForm } from "..";
import { useBoundStore } from "store";
import { EditableRow } from "shared/components/editable-row/EditableRow";
import { EditableCell } from "shared/components/editable-cell/EditableCell";

export const ProjectList = () => {
  const [searchParams] = useSearchParams();
  const [scrollKey, setScrollKey] = useState<string>();
  const tblRef: Parameters<typeof Table>[0]["ref"] = React.useRef(null);
  const { loading, data, getProjects, hasMore, lastEvaluatedKey } =
    useGetProjects();
  const { updateProject, success: updateProjectSuccess } = useUpdateProject();
  const { filters, stage, showArchived } = useBoundStore();
  const defaultProjectId = searchParams?.get("id")?.trim() ?? "";
  const tableColumns = getProjectColumns(
    useCallback(
      async (project: IProject) => {
        if (isEmpty(project?.id)) return;
        await updateProject(
          eachPropertyNulltoUndefined({
            ...project,
            mmProjectId: undefined,
            metadata: undefined,
            gsi: undefined,
          })
        );
      },
      [updateProject]
    )
  );
  const onFilterChange = useCallback(async () => {
    if (filters) {
      const status = filters.projectStatus.join(",");
      const subcontractor = filters.subcontractor.join(",");
      const utilityProgram = filters.utilityProgram.join(",");
      const accountManager = filters.accountManager.join(",");
      const customerPaymentMethod = filters.customerPaymentMethod.join(",");
      await getProjects(
        defaultProjectId,
        status,
        subcontractor,
        utilityProgram,
        accountManager,
        customerPaymentMethod,
        stage,
        showArchived,
        false
      );
    }
  }, [defaultProjectId, filters, getProjects, showArchived, stage]);

  const expandable = useMemo(() => {
    return {
      expandedRowRender: (record: IProject) => (
        <ProjectListForm project={record} onFinish={onFilterChange} />
      ),
      expandIcon: ({ expanded, onExpand, record }: any) =>
        expanded ? (
          <CaretDownFilled
            className='project-list-caret-icon'
            onClick={(e) => onExpand(record, e)}
          />
        ) : (
          <CaretRightFilled
            className='project-list-caret-icon'
            onClick={(e) => onExpand(record, e)}
          />
        ),
      defaultExpandedRowKeys: [defaultProjectId],
    };
  }, [defaultProjectId, onFilterChange]);

  useEffect(() => {
    onFilterChange();
  }, [onFilterChange]);

  useEffect(() => {
    tblRef?.current?.scrollTo({
      key: scrollKey,
    });
  }, [scrollKey]);

  useEffect(() => {
    if (updateProjectSuccess) {
      getProjects();
    }
  }, [getProjects, updateProjectSuccess]);

  return (
    <>
      <ProjectFilters />
      <Table
        components={{
          body: {
            row: EditableRow,
            cell: EditableCell,
          },
        }}
        size='middle'
        loading={loading}
        expandable={expandable}
        pagination={false}
        scroll={{ y: 600 }}
        columns={tableColumns}
        dataSource={data}
        className='project-list-table'
        ref={tblRef}
        rowKey='id'
        footer={() => <Space />}
        onScroll={async (e: React.UIEvent<HTMLElement, UIEvent>) => {
          if (
            e.currentTarget.scrollHeight - e.currentTarget.scrollTop <=
              e.currentTarget.clientHeight + 75 &&
            hasMore &&
            !loading
          ) {
            setScrollKey(data?.at(-1)?.id);
            const status = filters?.projectStatus?.join(",");
            const subcontractor = filters?.subcontractor?.join(",");
            const utilityProgram = filters?.utilityProgram?.join(",");
            const accountManager = filters?.accountManager?.join(",");
            const customerPaymentMethod =
              filters?.customerPaymentMethod?.join(",");
            await getProjects(
              defaultProjectId,
              status,
              subcontractor,
              utilityProgram,
              accountManager,
              customerPaymentMethod,
              stage,
              showArchived,
              hasMore,
              lastEvaluatedKey,
              data
            );
          }
        }}
      />
    </>
  );
};
