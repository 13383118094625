import { Button, Form, FormInstance } from "antd";
import React, { ReactNode, useEffect, useState } from "react";

import "./SubmitButton.scss";

type Props = {
  form: FormInstance;
  children?: ReactNode;
  className?: string;
  testId?: string;
  overrideDisabled?: boolean;
};

export const SubmitButton = ({
  form,
  children,
  className,
  testId,
  overrideDisabled,
}: Props) => {
  const [submittable, setSubmittable] = useState<boolean>(false);

  // Watch all values
  const values = Form.useWatch([], form);

  useEffect(() => {
    form
      .validateFields({ validateOnly: true })
      .then(() => setSubmittable(true))
      .catch(() => setSubmittable(false));
  }, [form, values]);

  return (
    <Button
      type='primary'
      size='large'
      block
      className={`submit-button ${className ?? ""}`}
      htmlType='submit'
      data-testid={testId}
      disabled={
        (!submittable && overrideDisabled === undefined) || overrideDisabled
      }
    >
      {children}
    </Button>
  );
};
