import { Col, Row, Typography, Card, Skeleton } from "antd";

import "./FinanceManagerFinancialsChartsCard.scss";
import {
  DepositStatuses,
  OpenAccountsReceivableStatuses,
} from "financials/components";
import { SalesTargetTimePeriods } from "users/components";

type Props = {
  onSalesTargetTimePeriodChange?: () => void;
  loaded?: boolean;
};

export const FinanceManagerFinancialsChartsCard = ({
  onSalesTargetTimePeriodChange,
  loaded,
}: Props) => {
  return (
    <Card className='finance-manager-financials-charts-card-container'>
      {loaded ? (
        <>
          <Row
            gutter={{ xs: 4, sm: 8, md: 16, lg: 20 }}
            justify={"space-between"}
          >
            <Col>
              <Typography.Title
                className='finance-manager-financials-charts-card-title'
                level={3}
              >
                Financials
              </Typography.Title>
            </Col>
            <Col>
              <SalesTargetTimePeriods
                name='sales-target-time-periods'
                onChange={onSalesTargetTimePeriodChange}
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <DepositStatuses />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <OpenAccountsReceivableStatuses />
            </Col>
          </Row>
        </>
      ) : (
        <Skeleton />
      )}
    </Card>
  );
};
