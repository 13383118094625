import { Col, Row, Typography } from "antd";
import { Cell, Legend, Pie, PieChart, ResponsiveContainer } from "recharts";

import { useBoundStore } from "store";

import "./OpenAccountsReceivableStatuses.scss";

export const OpenAccountsReceivableStatuses = () => {
  const { openAccountsReceivableStatuses } = useBoundStore();
  const COLORS = ["#13B76E", "#FF5E4B"];

  return (
    <>
      <Row>
        <Typography.Title
          className='open-accounts-receivable-statuses-title'
          level={3}
        >
          Open Accounts Receivable Statuses
        </Typography.Title>
      </Row>
      <Row>
        <Col span={24}>
          <ResponsiveContainer width='100%' height={400}>
            <PieChart>
              <Pie
                dataKey='totalProjects'
                nameKey='status'
                data={openAccountsReceivableStatuses}
                innerRadius={70}
                outerRadius={100}
              >
                {openAccountsReceivableStatuses?.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
              <Legend
                layout='vertical'
                align='right'
                verticalAlign='middle'
                payload={openAccountsReceivableStatuses?.map((item, index) => ({
                  id: item.status,
                  type: "square",
                  value: `${item.status} (${item.totalProjects ?? 0})`,
                  color: COLORS[index % COLORS.length],
                }))}
              />
            </PieChart>
          </ResponsiveContainer>
        </Col>
      </Row>
    </>
  );
};
