import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { authService } from "../services";
import { useBoundStore } from "store";
import { subcontractorService } from "subcontractors/services";
import { userService } from "users/services";

export const useLogin = () => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState<Error | undefined>(undefined);
  const {
    setUser,
    setSubcontractorsSelectOptions,
    setSalesRepsSelectOptions,
    setTokens,
  } = useBoundStore();
  const navigate = useNavigate();

  const login = async (email: string, password: string) => {
    try {
      setLoading(true);
      setSuccess(false);
      setError(undefined);

      const data = await authService.login(
        email,
        password,
        (accessToken, idToken) => {
          setTokens(accessToken, idToken);
        }
      );

      if (data) {
        if (data.role) {
          const subcontractors =
            await subcontractorService.getSimpleSubcontractors();
          const salesReps = await userService.getSalesReps();
          setSalesRepsSelectOptions(salesReps);
          setSubcontractorsSelectOptions(subcontractors);
        }

        setUser(data);
        setSuccess(true);
      }

      navigate("/dashboard");
    } catch (error) {
      setSuccess(false);
      setError(error as Error);
    } finally {
      setLoading(false);
    }
  };

  return {
    login,
    loading,
    success,
    error,
  };
};
