import { Card } from "antd";

import { NotesList } from "notes/components";
import "./OwnerDashboardNotesCard.scss";

export const OwnerDashboardNotesCard = () => {
  return (
    <Card className='owner-dashboard-notes-card-container'>
      <NotesList title={"My Project Notes"} />
    </Card>
  );
};
