import { Col, notification, Row } from "antd";

import "./EngineerDashboard.scss";
import { useEffect } from "react";
import { useGetProjectsInfo } from "projects/hooks/useGetProjectsInfo";
import { EngineerDashboardProjectsCard } from "./engineer-dashboard-projects-card/EngineerDashboardProjectsCard";
import { EngineerDashboardNotesCard } from "./engineer-dashboard-notes-card/EngineerDashboardNotesCard";

export const EngineerDashboard = () => {
  const { getProjectsInfo, error, success } = useGetProjectsInfo();
  const [api, notificationContextHolder] = notification.useNotification();

  const onSalesTargetTimePeriodChange = () => {
    getProjectsInfo();
  };

  useEffect(() => {
    getProjectsInfo();
  }, []);

  useEffect(() => {
    if (error) {
      api.error({
        message: "Error",
        description: error?.message,
      });
    }
  }, [error, api]);

  return (
    <>
      {notificationContextHolder}
      <Col span={24} className='engineer-dashboard'>
        <Row
          gutter={{ xs: 4, sm: 8, md: 16, lg: 20 }}
          justify={"space-between"}
        >
          <Col span={16}>
            <EngineerDashboardProjectsCard
              onSalesTargetTimePeriodChange={onSalesTargetTimePeriodChange}
              loaded={success}
            />
          </Col>
          <Col span={8}>
            <EngineerDashboardNotesCard />
          </Col>
        </Row>
      </Col>
    </>
  );
};
