import React from "react";
import { Col, Form, Input, Row } from "antd";

import "./PasswordRecoveryForm.scss";
import { SplitPageLayout } from "shared/components";
import { SubmitButton } from "shared/components";
import { useForgotPassword } from "../../hooks";
import { SignUpLink } from "../sign-up-link/SignUpLink";

type Props = {
  onSuccess?: (email: string) => void;
};

export const PasswordRecoveryForm = ({ onSuccess }: Props) => {
  const [form] = Form.useForm();
  const { forgotPassword, error } = useForgotPassword();

  const onFinishHandler = async (values: any) => {
    const recoveryEmail = values.email.trim();
    await forgotPassword(recoveryEmail);

    if (onSuccess) onSuccess(recoveryEmail);

    form.resetFields();
  };

  return (
    <SplitPageLayout
      title='Forgot your password?'
      subtitle="Don't worry, it happens to all of us.
      Enter your email below and click &ldquo;submit&rdquo; to recover
      your password."
      showBackToLoginLink={true}
      error={error}
    >
      <Form
        form={form}
        name='password-recovery-form'
        data-testid='password-recovery-form'
        layout='vertical'
        onFinish={onFinishHandler}
        requiredMark={false}
      >
        <Row>
          <Col span={24}>
            {" "}
            <Form.Item
              name='email'
              data-testid='email'
              label='Email'
              rules={[
                {
                  required: true,
                  message: "Please enter a valid email address.",
                },
                {
                  type: "email",
                  message: "Please enter a valid email address.",
                },
              ]}
            >
              <Input size='large' />
            </Form.Item>
          </Col>
        </Row>
        <SubmitButton
          className='password-recovery-button'
          testId='password-recovery-button'
          form={form}
        >
          Submit
        </SubmitButton>
      </Form>
      <SignUpLink />
    </SplitPageLayout>
  );
};
