import { Alert, Col, Row, Typography } from "antd";
import { Link } from "react-router-dom";
import { ReactNode } from "react";
import { LeftOutlined } from "@ant-design/icons";

import "./SplitPageLayout.scss";
import { Logo } from "shared/components";

type Props = {
  title?: string;
  subtitle?: string | ReactNode;
  showBackToLoginLink?: boolean;
  children?: ReactNode;
  error?: Error;
};

export const SplitPageLayout = ({
  title,
  subtitle,
  showBackToLoginLink,
  children,
  error,
}: Props) => {
  return (
    <Row>
      <Col span={12} className='layout-container'>
        <Col span={18}>
          <Logo className='layout-logo' />
          {showBackToLoginLink && (
            <Row>
              <Col span={24}>
                <Link to='/login'>
                  <Typography.Link className='password-recovery-login-link'>
                    <LeftOutlined /> Back to login
                  </Typography.Link>
                </Link>
              </Col>
            </Row>
          )}
          {title && <Typography.Title level={1}>{title}</Typography.Title>}
          {subtitle && typeof subtitle === "string" ? (
            <Typography.Paragraph type='secondary' className='layout-subtitle'>
              {subtitle}
            </Typography.Paragraph>
          ) : (
            <>{subtitle}</>
          )}
          {error && (
            <Alert
              data-testid='layout-error'
              className='layout-error'
              message='Error'
              description={error?.message}
              type='error'
              showIcon
            />
          )}
          {children}
        </Col>
      </Col>
      <Col span={12} className='layout-background-image-container'></Col>
    </Row>
  );
};
