import { isEmpty } from "lodash";
import { useCallback, useState } from "react";
import { useBoundStore } from "store";
import { userService } from "users/services";

export const useGetUserMentionOptions = () => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState<Error | undefined>(undefined);
  const { setUserMentionOptions, userMentionOptions } = useBoundStore();

  const getUserMentionOptions = useCallback(async () => {
    try {
      setLoading(true);
      setSuccess(false);
      setError(undefined);

      if (!isEmpty(userMentionOptions)) return;

      const options = await userService.getUserMentionOptions();

      setUserMentionOptions(options);
      setSuccess(true);
    } catch (error) {
      setSuccess(false);
      setError(error as Error);
    } finally {
      setLoading(false);
    }
  }, []);

  return {
    getUserMentionOptions,
    loading,
    success,
    error,
  };
};
