import { Row, Typography } from "antd";
import { Cell, Legend, Pie, PieChart } from "recharts";

import { useBoundStore } from "store";

import "./SalesByUtility.scss";
import { utilityService } from "shared/services";
import { projectService } from "projects/services";

type Props = {
  dataLoading?: boolean;
};

export const SalesByUtility = ({ dataLoading }: Props) => {
  const { salesByUtility } = useBoundStore();
  const COLORS = ["#13B76E", "#FAF119", "#6499FF", "#7041A9"];

  return (
    <>
      <Row>
        <Typography.Title className='sales-by-utility-title' level={3}>
          Sales By Utility
        </Typography.Title>
      </Row>
      <Row>
        <PieChart width={400} height={300} margin={{ left: 25, right: 25 }}>
          <Pie
            dataKey='actualSales'
            nameKey='utilityProgram'
            data={salesByUtility}
            innerRadius={70}
            outerRadius={100}
          >
            {salesByUtility?.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
          </Pie>
          <Legend
            layout='vertical'
            verticalAlign='bottom'
            payload={salesByUtility?.map((item, index) => ({
              id: item.utilityProgram,
              type: "square",
              value: `${projectService.getUtilityProgramLabel(item.utilityProgram)} (${item.totalProjects ?? 0}) $${utilityService.statisticsFormatter(item.actualSales)}`,
              color: COLORS[index % COLORS.length],
            }))}
            wrapperStyle={{ left: 0 }}
          />
        </PieChart>
      </Row>
    </>
  );
};
