import { Col, Row, Typography } from "antd";
import { Cell, Legend, Pie, PieChart, ResponsiveContainer } from "recharts";

import { useBoundStore } from "store";

import "./DepositStatuses.scss";
import { ChartColors } from "shared/constants";

export const DepositStatuses = () => {
  const { depositStatuses } = useBoundStore();

  return (
    <>
      <Row>
        <Typography.Title className='deposit-statuses-title' level={3}>
          Deposit Statuses
        </Typography.Title>
      </Row>
      <Row>
        <Col span={24}>
          <ResponsiveContainer width='100%' height={400}>
            <PieChart>
              <Pie
                dataKey='totalProjects'
                nameKey='status'
                data={depositStatuses}
                innerRadius={70}
                outerRadius={100}
              >
                {depositStatuses?.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={ChartColors[index % ChartColors.length]}
                  />
                ))}
              </Pie>
              <Legend
                layout='vertical'
                align='right'
                verticalAlign='middle'
                payload={depositStatuses?.map((item, index) => ({
                  id: item.status,
                  type: "square",
                  value: `${item.status} (${item.totalProjects ?? 0})`,
                  color: ChartColors[index % ChartColors.length],
                }))}
              />
            </PieChart>
          </ResponsiveContainer>
        </Col>
      </Row>
    </>
  );
};
