import { useCallback, useState } from "react";

import { IProject } from "projects/models";
import { subcontractorService } from "subcontractors/services";
import { ISimpleSubcontractor } from "subcontractors/models";

export const useUpsertSubcontractor = () => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState<Error | undefined>(undefined);
  const [data, setData] = useState<IProject | undefined>(undefined);

  const upsertSubcontractor = useCallback(
    async (subcontractor: ISimpleSubcontractor) => {
      try {
        setLoading(true);
        setSuccess(false);
        setError(undefined);
        setData(undefined);

        let newSubcontractor;

        if (subcontractor.id) {
          newSubcontractor =
            await subcontractorService.updateSubcontractor(subcontractor);
        } else {
          newSubcontractor =
            await subcontractorService.createSubcontractor(subcontractor);
        }

        setData(newSubcontractor);
        setSuccess(true);
      } catch (error) {
        setSuccess(false);
        setError(error as Error);
      } finally {
        setLoading(false);
      }
    },
    []
  );

  return {
    upsertSubcontractor,
    loading,
    success,
    error,
    data,
  };
};
