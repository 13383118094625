import { useState } from "react";

import { salesService } from "sales/services";
import { useBoundStore } from "store";

export const useGetSalesPipelineInfo = () => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState<Error | undefined>(undefined);
  const {
    setSalesPipelineProjects,
    setPipelineDetails,
    setPipelineByProjectStages,
  } = useBoundStore();

  const getSalesPipelineInfo = async () => {
    try {
      setLoading(true);
      setSuccess(false);
      setError(undefined);

      const salesPipelineInfo = await salesService.getSalesPipelineInfo();

      setSalesPipelineProjects(salesPipelineInfo.salesPipelineProjects);
      setPipelineDetails(salesPipelineInfo?.pipelineDetails);
      setPipelineByProjectStages(salesPipelineInfo?.pipelineByProjectStages);
      setSuccess(true);
    } catch (error) {
      setSuccess(false);
      setError(error as Error);
    } finally {
      setLoading(false);
    }
  };

  return {
    getSalesPipelineInfo,
    loading,
    success,
    error,
  };
};
