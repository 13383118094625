import { StateCreator } from "zustand";

import {
  ICashFlow,
  IDepositStatus,
  IFinancialsSlice,
  IOpenAccountsReceivableCustomerDirect,
  IOpenAccountsReceivableFinanced,
  IOpenAccountsReceivableIncentive,
  IOpenAccountsReceivableStatus,
  IOpenProjectsDepositsReceived,
} from "financials/models";
import { sliceResetFns } from "store";

const initialState = {
  cashFlow: {
    remainingOverhead: 0,
    cashFlow: 0,
    expectedProfit: 0,
  },
  depositStatuses: [],
  openAccountsReceivableStatuses: [],
  openAccountsReceivableIncentive: [],
  openAccountsReceivableFinanced: [],
  openAccountsReceivableCustomerDirect: [],
  openProjectsDepositsReceived: [],
};

export const createFinancialsSlice: StateCreator<
  IFinancialsSlice,
  [["zustand/devtools", never]],
  [],
  IFinancialsSlice
> = (set) => {
  sliceResetFns.add(() => set(initialState));
  return {
    ...initialState,
    setCashFlow: (cashFlow: ICashFlow) =>
      set((state) => ({
        cashFlow: cashFlow,
      })),
    setDepositStatuses: (depositStatuses: IDepositStatus[]) =>
      set((state) => ({
        depositStatuses: depositStatuses,
      })),
    setOpenAccountsReceivableStatuses: (
      openAccountsReceivableStatuses: IOpenAccountsReceivableStatus[]
    ) =>
      set((state) => ({
        openAccountsReceivableStatuses: openAccountsReceivableStatuses,
      })),
    setOpenAccountsReceivableIncentive: (
      openAccountsReceivableIncentive: IOpenAccountsReceivableIncentive[]
    ) =>
      set((state) => ({
        openAccountsReceivableIncentive: openAccountsReceivableIncentive,
      })),
    setOpenAccountsReceivableFinanced: (
      openAccountsReceivableFinanced: IOpenAccountsReceivableFinanced[]
    ) =>
      set((state) => ({
        openAccountsReceivableFinanced: openAccountsReceivableFinanced,
      })),
    setOpenAccountsReceivableCustomerDirect: (
      openAccountsReceivableCustomerDirect: IOpenAccountsReceivableCustomerDirect[]
    ) =>
      set((state) => ({
        openAccountsReceivableCustomerDirect:
          openAccountsReceivableCustomerDirect,
      })),
    setOpenProjectsDepositsReceived: (
      openProjectsDepositsReceived: IOpenProjectsDepositsReceived[]
    ) =>
      set((state) => ({
        openProjectsDepositsReceived: openProjectsDepositsReceived,
      })),
    resetFinancialsSlice: () => set(() => initialState),
  };
};
