import { Button, Flex, Typography } from "antd";
import { useNavigate } from "react-router-dom";

import "./Forbidden.scss";
import { Logo } from "shared/components";

export const Forbidden = () => {
  const navigate = useNavigate();

  return (
    <Flex justify='top' align='center' vertical className='forbidden-container'>
      <Logo className='forbidden-logo' />
      <Typography.Title level={1}>403 Forbidden</Typography.Title>
      <Typography.Title level={2} className='forbidden-subtitle'>
        Oops! You do not have access to this resource. <br /> Please contract
        your project administrator in order to gain access.
      </Typography.Title>
      <Button
        type='primary'
        size='large'
        block
        className='forbidden-button'
        onClick={() => {
          navigate("/dashboard");
        }}
      >
        Back to dashboard
      </Button>
    </Flex>
  );
};
