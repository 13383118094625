import { useState } from "react";
import { createSearchParams, useNavigate } from "react-router-dom";

import { VerificationTypes } from "auth/constants";
import { useBoundStore } from "store";
import { ISimpleUser } from "users/models";
import { userService } from "users/services";
import { RcFile } from "antd/es/upload";
import { omit } from "lodash";

export const useUpdateUser = () => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [data, setData] = useState<ISimpleUser | undefined>(undefined);
  const [error, setError] = useState<Error | undefined>(undefined);
  const { setUser } = useBoundStore();
  const currentUser = useBoundStore((state) => state.user);
  const navigate = useNavigate();

  const updateUser = async (
    userToUpdate: ISimpleUser = {},
    id: string | undefined = currentUser?.id,
    file?: RcFile
  ) => {
    try {
      setLoading(true);
      setSuccess(false);
      setError(undefined);
      setData(undefined);

      if (!id) {
        throw new Error("User id is required.");
      }

      const user = await userService.updateUser(
        id,
        omit({ ...currentUser, ...userToUpdate }, [
          "role",
          "roleId",
          "uid",
          "configuration",
          "profilePicture",
          "id",
          "gsi",
        ]),
        file
      );

      setUser(user);

      if (currentUser?.email !== user?.email) {
        navigate({
          pathname: "/verify",
          search: createSearchParams({
            email: user.email ?? "",
            type: VerificationTypes.EMAIL,
          }).toString(),
        });
      }

      setData(user);
      setSuccess(true);
    } catch (error) {
      setData(undefined);
      setSuccess(false);
      setError(error as Error);
    } finally {
      setLoading(false);
    }
  };

  return {
    updateUser,
    data,
    loading,
    success,
    error,
  };
};
