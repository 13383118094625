import { useState } from "react";
import { IUserRoleSelectOption } from "users/models";
import { userService } from "users/services";

export const useGetUserSelectOptions = () => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState<Error | undefined>(undefined);
  const [data, setData] = useState<IUserRoleSelectOption[] | undefined>();

  const getUserSelectOptions = async () => {
    try {
      setLoading(true);
      setSuccess(false);
      setData(undefined);
      setError(undefined);

      const options = await userService.getUserSelectOptions();

      setData(options);
      setSuccess(true);
    } catch (error) {
      setSuccess(false);
      setError(error as Error);
    } finally {
      setLoading(false);
    }
  };

  return {
    getUserSelectOptions,
    loading,
    success,
    error,
    data,
  };
};
