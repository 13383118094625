import { GetProp, TableProps } from "antd";
import { utilityService } from "shared/services";
import { IOpenAccountsReceivableFinanced } from "financials/models";

type ColumnsType<T extends object> = GetProp<TableProps<T>, "columns">;

export const OpenAccountsReceivableFinancedColumns: ColumnsType<IOpenAccountsReceivableFinanced> =
  [
    {
      title: "",
      dataIndex: "id",
      width: 100,
      fixed: "left",
      render: (value, record, index) => index + 1,
    },
    {
      title: "Project",
      dataIndex: "projectName",
      render: (value, record, index) => utilityService.truncateField(value),
    },
    {
      title: "Billed Finance Amount Date",
      dataIndex: "billedFinancedAmountDate",
      render: (value, record, index) => utilityService.dateFormatter(value),
    },
    {
      title: "Billed Finance Amount",
      dataIndex: "billedFinancedAmount",
      render: (value, record, index) =>
        `$${utilityService.statisticsFormatter(value)}`,
    },
    {
      title: "Accrual / Batch Date",
      dataIndex: "accrualBatchDate",
      render: (value, record, index) => utilityService.dateFormatter(value),
    },
    {
      title: "Days Aging",
      dataIndex: "daysAging",
    },
    {
      title: "Customer Financed Portion Received Date",
      dataIndex: "customerFinancedPortionReceivedDate",
      render: (value, record, index) => utilityService.dateFormatter(value),
    },
    {
      title: "Customer Financed Portion Received Amount",
      dataIndex: "customerFinancedPortionReceivedAmount",
      render: (value, record, index) =>
        `$${utilityService.statisticsFormatter(value)}`,
    },
  ];
