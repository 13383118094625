import { Col, Row, Typography } from "antd";
import {
  Bar,
  BarChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import { useBoundStore } from "store";

import "./TargetVsActualSales.scss";

type Props = {
  dataLoading?: boolean;
};

export const TargetVsActualSales = ({ dataLoading }: Props) => {
  const { targetVsActualSales } = useBoundStore();

  return (
    <>
      <Row>
        <Typography.Title className='target-vs-actual-sales-title' level={3}>
          Target vs Actual Sales
        </Typography.Title>
      </Row>
      <Row>
        <Col span={24}>
          <ResponsiveContainer width='100%' height={400}>
            <BarChart data={targetVsActualSales}>
              <XAxis dataKey='month' tickLine={false} axisLine={false} />
              <YAxis tickLine={false} axisLine={false} />
              <Tooltip />
              <Bar
                dataKey='targetSalesAmount'
                fill='#FAF119'
                radius={[10, 10, 10, 10]}
              />
              <Bar
                dataKey='actualSalesAmount'
                fill='#13B76E'
                radius={[10, 10, 10, 10]}
              />
            </BarChart>
          </ResponsiveContainer>
        </Col>
      </Row>
    </>
  );
};
