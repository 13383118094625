import { Space, Typography } from "antd";
import { Link } from "react-router-dom";

import "./SignInLink.scss";

export const SignInLink = () => {
  return (
    <Typography.Paragraph strong className='sign-in-link'>
      <Space>
        Already have an account?
        <Link to='/login'>Sign In</Link>
      </Space>
    </Typography.Paragraph>
  );
};
