import { useState } from "react";
import { createSearchParams, useNavigate } from "react-router-dom";
import { authService } from "../services";
import { VerificationTypes } from "auth/constants";

export const useSignUp = () => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState<Error | undefined>(undefined);
  const navigate = useNavigate();

  const signUp = async (
    email: string,
    password: string,
    firstName: string,
    lastName: string,
    phoneNumber: string
  ) => {
    try {
      setLoading(true);
      setSuccess(false);
      setError(undefined);

      await authService.signUp(
        email,
        password,
        firstName,
        lastName,
        phoneNumber
      );

      setSuccess(true);

      navigate({
        pathname: "/verify",
        search: createSearchParams({
          email: email,
          type: VerificationTypes.SIGN_UP,
        }).toString(),
      });
    } catch (error) {
      setSuccess(false);
      setError(error as Error);
    } finally {
      setLoading(false);
    }
  };

  return {
    signUp,
    loading,
    success,
    error,
  };
};
