import { GetProp, TableProps } from "antd";
import { utilityService } from "shared/services";
import { IOpenAccountsReceivableCustomerDirect } from "financials/models";

type ColumnsType<T extends object> = GetProp<TableProps<T>, "columns">;

export const OpenAccountsReceivableCustomerDirectColumns: ColumnsType<IOpenAccountsReceivableCustomerDirect> =
  [
    {
      title: "",
      dataIndex: "id",
      width: 100,
      fixed: "left",
      render: (value, record, index) => index + 1,
    },
    {
      title: "Project",
      dataIndex: "projectName",
      render: (value, record, index) => utilityService.truncateField(value),
    },
    {
      title: "Customer Deposit Amount Received",
      dataIndex: "customerDepositAmountReceived",
      render: (value, record, index) =>
        `$${utilityService.statisticsFormatter(value)}`,
    },
    {
      title: "Customer Days Aging",
      dataIndex: "customerDaysAging",
    },
    {
      title: "Final Customer Portion - Billed Date",
      dataIndex: "finalCustomerPortionBilledDate",
      render: (value, record, index) => utilityService.dateFormatter(value),
    },
    {
      title: "Final Customer Billed Amount",
      dataIndex: "finalCustomerBilledAmount",
      render: (value, record, index) =>
        `$${utilityService.statisticsFormatter(value)}`,
    },
    {
      title: "Final Customer Direct Amount Received",
      dataIndex: "finalCustomerDirectAmountReceived",
      render: (value, record, index) =>
        `$${utilityService.statisticsFormatter(value)}`,
    },
    {
      title: "Final Customer Direct Received Date",
      dataIndex: "finalCustomerDirectReceivedDate",
      render: (value, record, index) => utilityService.dateFormatter(value),
    },
    {
      title: "Total Customer Cost",
      dataIndex: "totalCustomerCost",
      render: (value, record, index) =>
        `$${utilityService.statisticsFormatter(value)}`,
    },
  ];
