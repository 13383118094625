import { Col, Row, Typography } from "antd";
import { Cell, Legend, Pie, PieChart, ResponsiveContainer } from "recharts";

import { useBoundStore } from "store";

import "./InstallationsBySubcontractor.scss";
import { utilityService } from "shared/services";

export const InstallationsBySubcontractor = () => {
  const { installationsBySubcontractor } = useBoundStore();
  const COLORS = ["#FF5E4B", "#FB9D11", "#FAF119", "#13B76E"];

  return (
    <>
      <Row>
        <Typography.Title
          className='installations-by-subcontractor-title'
          level={3}
        >
          Installations By Subcontractor
        </Typography.Title>
      </Row>
      <Row>
        <Col span={24}>
          <ResponsiveContainer width='100%' height={400}>
            <PieChart>
              <Pie
                dataKey='totalProjects'
                nameKey='status'
                data={installationsBySubcontractor}
                innerRadius={70}
                outerRadius={100}
              >
                {installationsBySubcontractor?.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
              <Legend
                layout='vertical'
                align='right'
                verticalAlign='middle'
                payload={installationsBySubcontractor?.map((item, index) => ({
                  id: item.subcontractor,
                  type: "square",
                  value: `${item.subcontractor} (${item.totalProjects ?? 0}) $${utilityService.statisticsFormatter(item.amount)}`,
                  color: COLORS[index % COLORS.length],
                }))}
              />
            </PieChart>
          </ResponsiveContainer>
        </Col>
      </Row>
    </>
  );
};
