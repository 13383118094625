import { Col, Row, Image } from "antd";

import { StatisticCard } from "shared/components";
import { utilityService } from "shared/services";
import searchIcon from "projects/assets/images/search.svg";
import prepIcon from "projects/assets/images/prep.svg";
import engineeringIcon from "projects/assets/images/engineering.svg";

import "./EngineeringProjects.scss";
import { useBoundStore } from "store";

export const EngineeringProjects = () => {
  const { engineeringProjects } = useBoundStore();
  return (
    <Row
      className='engineering-projects'
      gutter={{ xs: 4, sm: 8, md: 16, lg: 20 }}
      justify={"space-between"}
    >
      <Col span={8}>
        <StatisticCard
          title='Projects in Auditing Stage'
          value={engineeringProjects?.projectsInAuditingStage}
          formatter={utilityService.statisticsFormatter}
          backgroundColor='#FFF4DE'
          Icon={() => (
            <Image
              src={searchIcon}
              alt='Search Icon'
              preview={false}
              width={35}
            />
          )}
          textAlign='left'
        />
      </Col>
      <Col span={8}>
        <StatisticCard
          title='Projects in Engineering Prep Stage'
          value={engineeringProjects?.projectsInEngineeringPrepStage}
          formatter={utilityService.statisticsFormatter}
          backgroundColor='#E2FFF3'
          Icon={() => (
            <Image src={prepIcon} alt='Prep Icon' preview={false} width={35} />
          )}
          textAlign='left'
        />
      </Col>
      <Col span={8}>
        <StatisticCard
          title='Projects in Engineering Stage'
          value={engineeringProjects?.projectsInEngineeringStage}
          precision={0}
          backgroundColor='#EBF3FE'
          Icon={() => (
            <Image
              src={engineeringIcon}
              alt='Engineering Icon'
              preview={false}
              width={35}
            />
          )}
          textAlign='left'
        />
      </Col>
    </Row>
  );
};
