import { authService } from "auth/services";
import { useState } from "react";

export const useConfirmForgotPassword = () => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState<Error | undefined>(undefined);

  const confirmForgotPassword = async (
    email: string,
    verificationCode: string,
    newPassword: string
  ) => {
    try {
      setLoading(true);
      setSuccess(false);
      setError(undefined);

      await authService.confirmForgotPassword(
        email,
        verificationCode,
        newPassword
      );

      setSuccess(true);
    } catch (error) {
      setSuccess(false);
      setError(error as Error);
    } finally {
      setLoading(false);
    }
  };

  return {
    confirmForgotPassword,
    loading,
    success,
    error,
  };
};
