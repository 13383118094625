import { Row, Table, TableProps } from "antd";
import React from "react";

import { useBoundStore } from "store";
import { AuditAppointmentsColumns } from "audit/constants";
import { IAuditAppointment } from "audit/models";

import "./AuditAppointments.scss";

type Props = {
  dataLoading?: boolean;
};

export const AuditAppointments = ({ dataLoading }: Props) => {
  const tblRef: Parameters<typeof Table>[0]["ref"] = React.useRef(null);
  const tableColumns = AuditAppointmentsColumns.map((item) => ({
    ...item,
  }));
  const tableProps: TableProps<IAuditAppointment> = {
    loading: dataLoading,
  };
  const { auditAppointments } = useBoundStore();

  return (
    <Row>
      <Table
        {...tableProps}
        columns={tableColumns}
        dataSource={auditAppointments}
        className='audit-appointments-table'
        ref={tblRef}
        rowKey='id'
        pagination={false}
      />
    </Row>
  );
};
