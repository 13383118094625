import { IAuditAppointment, IAuditSlice } from "audit/models";
import { sliceResetFns } from "store";
import { StateCreator } from "zustand";

const initialState = {
  auditAppointments: [],
};

export const createAuditSlice: StateCreator<
  IAuditSlice,
  [["zustand/devtools", never]],
  [],
  IAuditSlice
> = (set) => {
  sliceResetFns.add(() => set(initialState));
  return {
    ...initialState,
    setAuditAppointments: (auditAppointments: IAuditAppointment[]) =>
      set((state) => ({
        auditAppointments: auditAppointments,
      })),
    resetAuditSlice: () => set(() => initialState),
  };
};
