import { Row, Table, TableProps, Typography } from "antd";
import "./OutstandingContractorQuotes.scss";
import React from "react";
import { useBoundStore } from "store";
import { OutstandingContractorQuotesColumns } from "projects/constants";
import { IOutstandingContractorQuote } from "projects/models";

export const OutstandingContractorQuotes = () => {
  const tblRef: Parameters<typeof Table>[0]["ref"] = React.useRef(null);
  const tableColumns = OutstandingContractorQuotesColumns.map((item) => ({
    ...item,
  }));
  const tableProps: TableProps<IOutstandingContractorQuote> = {};
  const { outstandingContractorQuotes } = useBoundStore();

  return (
    <>
      <Row>
        <Typography.Title
          className='outstanding-contractor-quotes-title'
          level={3}
        >
          Outstanding Contractor Quotes ({">"} 2 weeks)
        </Typography.Title>
      </Row>
      <Row>
        <Table
          {...tableProps}
          columns={tableColumns}
          dataSource={outstandingContractorQuotes}
          className='outstanding-contractor-quotes-table'
          ref={tblRef}
          rowKey='id'
          pagination={false}
        />
      </Row>
    </>
  );
};
