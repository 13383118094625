import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserAttribute,
  CognitoUserPool,
  CognitoUserSession,
  ICognitoUserAttributeData,
  ISignUpResult,
} from "amazon-cognito-identity-js";
import { includes, isEmpty } from "lodash";

import { MissingParameterError } from "auth/models/MissingParameterError";
import { useBoundStore } from "store";
import { userService } from "users/services";
import { ISimpleUser } from "users/models";

export const userPool = new CognitoUserPool({
  UserPoolId: process.env.REACT_APP_USERPOOL_ID ?? "",
  ClientId: process.env.REACT_APP_APPCLIENT_ID ?? "",
});

export const signUp = (
  email: string,
  password: string,
  firstName: string,
  lastName: string,
  phoneNumber: string
): Promise<ISignUpResult | undefined> =>
  new Promise(async (resolve, reject) => {
    if (
      isEmpty(email) ||
      isEmpty(password) ||
      isEmpty(firstName) ||
      isEmpty(lastName) ||
      isEmpty(phoneNumber)
    ) {
      reject(new MissingParameterError());
      return;
    }

    const newUser = await userService.addUser({
      email,
      phoneNumber,
      firstName,
      lastName,
    });

    const userAttributeList = [
      new CognitoUserAttribute({
        Name: "email",
        Value: email.trim(),
      }),
      new CognitoUserAttribute({
        Name: "name",
        Value: `${firstName} ${lastName}`.trim(),
      }),
      new CognitoUserAttribute({
        Name: "given_name",
        Value: firstName.trim(),
      }),
      new CognitoUserAttribute({
        Name: "family_name",
        Value: lastName.trim(),
      }),
      new CognitoUserAttribute({
        Name: "phone_number",
        Value: phoneNumber.trim(),
      }),
    ];

    if (newUser?.id) {
      userAttributeList.push(
        new CognitoUserAttribute({
          Name: "custom:userid",
          Value: newUser.id,
        })
      );
    }

    userPool.signUp(
      email,
      password,
      userAttributeList,
      [],
      async (err, result) => {
        if (err) {
          reject(err);
          return;
        }

        resolve(result);
      }
    );
  });

export const login = async (
  email: string,
  password: string,
  tokenStorageCallback: (accessToken: string, idToken: string) => void
): Promise<ISimpleUser | undefined> =>
  new Promise((resolve, reject) => {
    if (isEmpty(email)) {
      reject(new MissingParameterError());
      return;
    }

    const cognitoUser = new CognitoUser({
      Username: email,
      Pool: userPool,
    });

    const authenticationDetails = new AuthenticationDetails({
      Username: email,
      Password: password,
    });

    const defaultError = new Error(
      "Authentication failed. Please check your credentials and try again."
    );

    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: (session) => {
        cognitoUser.getUserAttributes(async (err, result) => {
          if (err) {
            reject(defaultError);
            return;
          }

          const id = result?.find((x) => x.Name === "custom:userid")?.Value;
          const accessToken = session.getAccessToken().getJwtToken();
          const idToken = session.getIdToken().getJwtToken();

          if (isEmpty(id) || isEmpty(accessToken) || isEmpty(idToken)) {
            reject(defaultError);
            return;
          }

          try {
            tokenStorageCallback(accessToken, idToken);

            const user = await userService.getUser(id as string);
            resolve(user);
          } catch (error: any) {
            reject(defaultError);
          }
        });
      },
      onFailure: () => {
        reject(defaultError);
      },
    });
  });

export const resendConfirmationCode = async (email: string): Promise<void> =>
  new Promise((resolve, reject) => {
    if (isEmpty(email)) {
      reject(new MissingParameterError());
      return;
    }
    const cognitoUser = new CognitoUser({
      Username: email,
      Pool: userPool,
    });

    cognitoUser.resendConfirmationCode((err, _) => {
      if (err) {
        reject(err);
        return;
      }

      resolve();
    });
  });

export const forgotPassword = async (email: string): Promise<void> =>
  new Promise((resolve, reject) => {
    if (isEmpty(email)) {
      reject(new MissingParameterError());
      return;
    }
    const cognitoUser = new CognitoUser({
      Username: email,
      Pool: userPool,
    });

    cognitoUser.forgotPassword({
      onSuccess: () => {
        resolve();
      },
      onFailure: (err) => {
        reject(err);
      },
    });
  });

export const confirmSignUp = async (
  email: string,
  verificationCode: string
): Promise<void> =>
  new Promise((resolve, reject) => {
    if (isEmpty(email) || isEmpty(verificationCode)) {
      reject(new MissingParameterError());
      return;
    }

    const cognitoUser = new CognitoUser({
      Username: email,
      Pool: userPool,
    });

    cognitoUser.confirmRegistration(verificationCode, true, (err: Error, _) => {
      if (err) {
        reject(err);
        return;
      }

      resolve();
    });
  });

export const confirmForgotPassword = async (
  email: string,
  verificationCode: string,
  newPassword: string
): Promise<void> =>
  new Promise((resolve, reject) => {
    if (isEmpty(email) || isEmpty(verificationCode) || isEmpty(newPassword)) {
      reject(new MissingParameterError());
      return;
    }

    const cognitoUser = new CognitoUser({
      Username: email,
      Pool: userPool,
    });

    cognitoUser.confirmPassword(verificationCode, newPassword, {
      onSuccess: () => {
        resolve();
      },
      onFailure: (err) => {
        reject(err);
      },
    });
  });

export const logout = (): Promise<void> =>
  new Promise(async (resolve, reject) => {
    try {
      const cognitoUser = await getCurrentUser();

      cognitoUser?.signOut(() => {
        useBoundStore.persist.clearStorage();
        resolve();
      });
    } catch (error) {
      reject(error as Error);
    }
  });

export const isAuthenticated = async () => {
  let authenticated = false;

  try {
    const session = await getSession();
    authenticated = session?.isValid() ?? false;
  } finally {
    return authenticated;
  }
};

export const hasPermissions = (
  user: ISimpleUser,
  permissionsNeededToAccessRoute: string[]
) =>
  isEmpty(permissionsNeededToAccessRoute)
    ? true
    : permissionsNeededToAccessRoute?.every((permission) =>
        includes(user?.role?.permissions, permission)
      );

export const changePassword = async (
  oldPassword: string,
  newPassword: string
): Promise<void> =>
  new Promise(async (resolve, reject) => {
    if (isEmpty(oldPassword) || isEmpty(newPassword)) {
      reject(new MissingParameterError());
      return;
    }

    try {
      const cognitoUser = await getCurrentUser();

      cognitoUser?.changePassword(oldPassword, newPassword, (error, result) => {
        if (error) {
          reject(error);
          return;
        }

        resolve();
      });
    } catch (err) {
      reject(err);
    }
  });

export const changeEmail = async (email: string): Promise<void> => {
  try {
    await updateAttributes([
      new CognitoUserAttribute({
        Name: "email",
        Value: email.trim(),
      }),
    ]);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updateAttributes = async (
  attributes: (CognitoUserAttribute | ICognitoUserAttributeData)[]
): Promise<void> =>
  new Promise(async (resolve, reject) => {
    if (isEmpty(attributes)) {
      reject(new MissingParameterError());
      return;
    }

    const cognitoUser = await getCurrentUser();

    if (!cognitoUser) {
      reject(new Error("Current user was not found."));
      return;
    }

    cognitoUser?.updateAttributes(attributes, (e, _) => {
      if (e) {
        reject(e);
        return;
      }

      resolve();
    });
  });

export const verifyAttribute = async (
  attributeName: string,
  verificationCode: string
): Promise<void> =>
  new Promise(async (resolve, reject) => {
    if (isEmpty(verificationCode)) {
      reject(new MissingParameterError());
      return;
    }

    try {
      const cognitoUser = await getCurrentUser();

      cognitoUser?.verifyAttribute(attributeName, verificationCode, {
        onSuccess: () => {
          resolve();
        },
        onFailure: (err) => {
          reject(err);
        },
      });
    } catch (error) {
      reject(error);
    }
  });

const getSession = async (): Promise<CognitoUserSession | undefined> =>
  new Promise(async (resolve, reject) => {
    const cognitoUser = userPool.getCurrentUser();

    if (!cognitoUser) {
      reject(new Error("Current user was not found."));
      return;
    }

    cognitoUser?.getSession(
      (error: Error | null, session: CognitoUserSession) => {
        if (error) reject(error);

        resolve(session);
      }
    );
  });

const getCurrentUser = async (): Promise<CognitoUser | null | undefined> => {
  try {
    const cognitoUser = await getUser();
    return cognitoUser;
  } catch (error) {
    Promise.reject(error);
  }
};

const getUser = async (email?: string): Promise<CognitoUser | null> =>
  new Promise((resolve, reject) => {
    let cognitoUser: CognitoUser | null;

    if (isEmpty(email)) {
      cognitoUser = userPool?.getCurrentUser();
    } else {
      cognitoUser = new CognitoUser({
        Username: email ?? "",
        Pool: userPool,
      });
    }

    cognitoUser?.getSession((error: Error | null, _: CognitoUserSession) => {
      if (error) reject(error);

      resolve(cognitoUser);
      return;
    });

    resolve(null);
  });
