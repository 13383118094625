import { Col, Row, Image } from "antd";

import { StatisticCard } from "shared/components";
import { utilityService } from "shared/services";
import projectsIcon from "projects/assets/images/projects.svg";
import actualSalesIcon from "sales/assets/images/actual_sales.svg";

import "./SubcontractorProjects.scss";
import { useBoundStore } from "store";

type Props = {
  dataLoading?: boolean;
};

export const SubcontractorProjects = ({ dataLoading }: Props) => {
  const { subcontractorProjects } = useBoundStore();
  return (
    <Row
      className='subcontractor-projects'
      gutter={{ xs: 4, sm: 8, md: 16, lg: 20 }}
    >
      <Col span={8}>
        <StatisticCard
          title='Number of Projects'
          value={subcontractorProjects?.totalProjects}
          formatter={utilityService.statisticsFormatter}
          backgroundColor='#FFF4DE'
          Icon={() => (
            <Image
              src={projectsIcon}
              alt='Projects Icon'
              preview={false}
              width={35}
            />
          )}
          textAlign='left'
        />
      </Col>
      <Col span={8}>
        <StatisticCard
          title='Total Projects Value'
          value={subcontractorProjects?.totalProjectsValue}
          prefix='$'
          formatter={utilityService.statisticsFormatter}
          backgroundColor='#E2FFF3'
          Icon={() => (
            <Image
              src={actualSalesIcon}
              alt='Sales Icon'
              preview={false}
              width={35}
            />
          )}
          textAlign='left'
        />
      </Col>
    </Row>
  );
};
