import { Col, notification, Row } from "antd";

import "./ContractorDashboard.scss";
import { useEffect } from "react";
import { useGetProjectsInfo } from "projects/hooks/useGetProjectsInfo";
import { ContractorDashboardProjectsCard } from "./contractor-dashboard-projects-card/ContractorDashboardProjectsCard";
import { ContractorDashboardDeficientProjectsCard } from "./contractor-dashboard-deficient-projects-card/ContractorDashboardDeficientProjectsCard";
import { ContractorDashboardNotesCard } from "./contractor-dashboard-notes-card/ContractorDashboardNotesCard";

export const ContractorDashboard = () => {
  const { getProjectsInfo, error, success } = useGetProjectsInfo();
  const [api, notificationContextHolder] = notification.useNotification();

  const onSalesTargetTimePeriodChange = () => {
    getProjectsInfo();
  };

  useEffect(() => {
    getProjectsInfo();
  }, []);

  useEffect(() => {
    if (error) {
      api.error({
        message: "Error",
        description: error?.message,
      });
    }
  }, [error, api]);

  return (
    <>
      {notificationContextHolder}
      <Col span={24} className='contractor-dashboard'>
        <Row
          gutter={{ xs: 4, sm: 8, md: 16, lg: 20 }}
          justify={"space-between"}
        >
          <Col span={16}>
            <ContractorDashboardProjectsCard
              onSalesTargetTimePeriodChange={onSalesTargetTimePeriodChange}
              loaded={success}
            />
          </Col>
          <Col span={8}>
            <ContractorDashboardNotesCard />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <ContractorDashboardDeficientProjectsCard loaded={success} />
          </Col>
        </Row>
      </Col>
    </>
  );
};
