import { ComponentType, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useAuth } from "../../hooks";

type Props = {
  ComponentRoute: ComponentType;
  permissionsNeededToAccessRoute?: string[];
};

export const ProtectedRoute = ({
  ComponentRoute,
  permissionsNeededToAccessRoute = [],
}: Props) => {
  const { isAuthenticated, hasPermissions } = useAuth();
  const navigate = useNavigate();
  const [showComponent, setShowComponent] = useState(false);

  useEffect(() => {
    const checkAuth = async () => {
      const authenticated = await isAuthenticated();

      if (!authenticated) {
        navigate("/login");
      }

      setShowComponent(authenticated);
    };

    const checkPermissions = async () => {
      const authorized = hasPermissions(permissionsNeededToAccessRoute);

      if (!authorized) {
        navigate("/forbidden");
      }

      setShowComponent(authorized);
    };

    checkAuth();
    checkPermissions();
  }, [navigate, isAuthenticated]);

  return <>{showComponent && <ComponentRoute />}</>;
};
