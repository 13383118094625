import { Col, Row } from "antd";

import "./Projects.scss";
import { SideNavPageLayout } from "shared/components";
import { ProjectList, ProjectStageSelection } from "projects/components";

export const Projects = () => {
  return (
    <SideNavPageLayout title='Projects' defaultSelectedMenuItemKey='projects'>
      <Row>
        <Col span={24}>
          <ProjectStageSelection />
        </Col>
      </Row>
      <Row className='projects-project-list-container'>
        <Col span={24}>
          <ProjectList />
        </Col>
      </Row>
    </SideNavPageLayout>
  );
};
