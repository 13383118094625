import React, { useState } from "react";
import { isEmpty } from "lodash";

import {
  PasswordRecoveryConfirmationForm,
  PasswordRecoveryForm,
} from "../../components";

export const PasswordRecovery = () => {
  const [email, setEmail] = useState("");

  const onPasswordRecoverySuccessHandler = async (recoveryEmail: string) => {
    setEmail(recoveryEmail ?? "");
  };

  return (
    <>
      {isEmpty(email) && (
        <PasswordRecoveryForm onSuccess={onPasswordRecoverySuccessHandler} />
      )}
      {!isEmpty(email) && <PasswordRecoveryConfirmationForm email={email} />}
    </>
  );
};
