import { Row, Table, TableProps, Typography } from "antd";
import "./OpenAccountsReceivableCustomerDirect.scss";
import React from "react";
import { useBoundStore } from "store";
import { OpenAccountsReceivableCustomerDirectColumns } from "financials/constants";
import { IOpenAccountsReceivableCustomerDirect } from "financials/models";
import { utilityService } from "shared/services";

type Props = {
  dataLoading?: boolean;
};

export const OpenAccountsReceivableCustomerDirect = ({
  dataLoading,
}: Props) => {
  const tblRef: Parameters<typeof Table>[0]["ref"] = React.useRef(null);
  const tableColumns = OpenAccountsReceivableCustomerDirectColumns.map(
    (item) => ({ ...item })
  );
  const tableProps: TableProps<IOpenAccountsReceivableCustomerDirect> = {
    loading: dataLoading,
  };
  const { openAccountsReceivableCustomerDirect } = useBoundStore();

  return (
    <>
      <Row>
        <Typography.Title
          className='open-accounts-receivable-customer-direct-title'
          level={3}
        >
          Open Accounts Receivable - Customer Direct
        </Typography.Title>
      </Row>
      <Row>
        <Table
          {...tableProps}
          columns={tableColumns}
          dataSource={openAccountsReceivableCustomerDirect}
          className='open-accounts-receivable-customer-direct-table'
          ref={tblRef}
          rowKey='id'
          pagination={false}
          summary={(pageData) => {
            let totalCustomerDepositAmountReceived = 0;
            let totalFinalCustomerBilledAmount = 0;
            let totalFinalCustomerDirectAmountReceived = 0;
            let totalCustomerCosts = 0;

            pageData.forEach(
              ({
                customerDepositAmountReceived,
                finalCustomerBilledAmount,
                finalCustomerDirectAmountReceived,
                totalCustomerCost,
              }) => {
                totalCustomerDepositAmountReceived += Number(
                  customerDepositAmountReceived
                );
                totalFinalCustomerBilledAmount += Number(
                  finalCustomerBilledAmount
                );
                totalFinalCustomerDirectAmountReceived += Number(
                  finalCustomerDirectAmountReceived
                );
                totalCustomerCosts += Number(totalCustomerCost);
              }
            );

            return (
              <>
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0}></Table.Summary.Cell>
                  <Table.Summary.Cell index={1}>
                    <Typography.Text strong>TOTALS</Typography.Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={2} colSpan={3}>
                    <Typography.Text strong>
                      $
                      {utilityService.statisticsFormatter(
                        totalCustomerDepositAmountReceived
                      )}
                    </Typography.Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={2}>
                    <Typography.Text strong>
                      $
                      {utilityService.statisticsFormatter(
                        totalFinalCustomerBilledAmount
                      )}
                    </Typography.Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={3} colSpan={2}>
                    <Typography.Text strong>
                      $
                      {utilityService.statisticsFormatter(
                        totalFinalCustomerDirectAmountReceived
                      )}
                    </Typography.Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={4}>
                    <Typography.Text strong>
                      ${utilityService.statisticsFormatter(totalCustomerCosts)}
                    </Typography.Text>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </>
            );
          }}
        />
      </Row>
    </>
  );
};
