import {
  Button,
  Col,
  Flex,
  Form,
  Row,
  Select,
  Space,
  Typography,
  Image,
  Modal,
  notification,
  Alert,
} from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import "./ProjectListForm.scss";
import { IProject } from "projects/models";
import { ProjectStages, ProjectStatuses } from "projects/constants";
import { SubmitButton } from "shared/components";
import { ProjectAccountingInformation } from "../project-accounting-information/ProjectAccountingInformation";
import { ProjectContactInformation } from "../project-contact-information/ProjectContactInformation";
import { ProjectInformation } from "../project-information/ProjectInformation";
import { ProjectInstallationInformation } from "../project-installation-information/ProjectInstallationInformation";
import { ProjectSalesInformation } from "../project-sales-information/ProjectSalesInformation";
import numberOneImage from "../../assets/images/number1.svg";
import numberTwoImage from "../../assets/images/number2.svg";
import numberThreeImage from "../../assets/images/number3.svg";
import selectProjectStage from "../../assets/images/select-project-stage.svg";
import projectStatusIcon from "../../assets/images/project-status.svg";
import { useUpdateProject } from "projects/hooks";
import dayjs from "dayjs";
import { isEmpty, omit, sortBy } from "lodash";
import { memo, useCallback, useEffect, useRef } from "react";
import { AlertRef } from "antd/es/alert/Alert";
import MentionsComponent from "shared/components/mentions/Mentions";
import { useGetUserMentionOptions } from "users/hooks";
import { useBoundStore } from "store";
import { NotesList } from "notes/components";
import { UserRoles } from "admin/constants";

type Props = {
  project: IProject;
  onFinish?: () => Promise<void>;
};

export const eachPropertyNulltoUndefined = (obj: any) => {
  for (const key in obj) {
    if (obj[key] === null) {
      obj[key] = undefined;
    }
  }
  return obj;
};

export const ProjectListForm = memo(({ project, onFinish }: Props) => {
  const [form] = Form.useForm();
  const { error, data, updateProject, success } = useUpdateProject();
  const [modal, contextHolder] = Modal.useModal();
  const [api, notificationContextHolder] = notification.useNotification();
  const errorRef = useRef<null | AlertRef>(null);
  const { getUserMentionOptions } = useGetUserMentionOptions();
  const { userMentionOptions, user } = useBoundStore();

  const executeScroll = useCallback(
    () => errorRef?.current?.nativeElement?.scrollIntoView(true),
    []
  );

  const updateProjectWithDefaultValues = useCallback(
    (p: IProject) => ({
      ...p,
      lightingSubcontractorId: p?.lightingSubcontractorId ?? "",
      hvacSubcontractorId: p?.hvacSubcontractorId ?? "",
      refrigerationSubcontractorId: p?.refrigerationSubcontractorId ?? "",
      accountManagerId: p?.accountManagerId ?? "",
      leadGeneratorId: p?.leadGeneratorId ?? "",
      salesSupportId: p?.salesSupportId ?? "",
      latestProjectNote: undefined,
      stage: p?.stage ?? "",
      status: p?.status ?? "",
      ownerName: p?.ownerName ?? "",
      utilityProgram: p?.utilityProgram ?? "",
      projectTypes: p?.projectTypes ?? [],
      customerPaymentMethod: p?.customerPaymentMethod ?? "",
      ownerOrTenantPayee: p?.ownerOrTenantPayee ?? "",
      utilityBillsReceivedAndReviewed: !isEmpty(
        p?.utilityBillsReceivedAndReviewed
      )
        ? dayjs(p?.utilityBillsReceivedAndReviewed)
        : null,
      auditRequestFormReceivedReviewed: !isEmpty(
        p?.auditRequestFormReceivedReviewed
      )
        ? dayjs(p?.auditRequestFormReceivedReviewed)
        : null,
      auditScheduleDate: !isEmpty(p?.auditScheduleDate)
        ? dayjs(p?.auditScheduleDate)
        : null,
      onsiteAuditDone: !isEmpty(p?.onsiteAuditDone)
        ? dayjs(p?.onsiteAuditDone)
        : null,
      ceToolDone: !isEmpty(p?.ceToolDone) ? dayjs(p?.ceToolDone) : null,
      hvacAssessmentFormDone: !isEmpty(p?.hvacAssessmentFormDone)
        ? dayjs(p?.hvacAssessmentFormDone)
        : null,
      hvacPrePhotosReceived: !isEmpty(p?.hvacPrePhotosReceived)
        ? dayjs(p?.hvacPrePhotosReceived)
        : null,
      hvacQuoteSubsCertsApproved: !isEmpty(p?.hvacQuoteSubsCertsApproved)
        ? dayjs(p?.hvacQuoteSubsCertsApproved)
        : null,
      proposalSentToSalesRep: !isEmpty(p?.proposalSentToSalesRep)
        ? dayjs(p?.proposalSentToSalesRep)
        : null,
      estimatedSaleDate: !isEmpty(p?.estimatedSaleDate)
        ? dayjs(p?.estimatedSaleDate)
        : null,
      soldYear: p?.soldYear,
      soldMonth: p?.soldMonth,
      soldQuarter: p?.soldQuarter,
      reviewedSignedContractDocuments: !isEmpty(
        p?.reviewedSignedContractDocuments
      )
        ? dayjs(p?.reviewedSignedContractDocuments)
        : null,
      submittedToUtilityForApproval: !isEmpty(p?.submittedToUtilityForApproval)
        ? dayjs(p?.submittedToUtilityForApproval)
        : null,
      financingApproved: !isEmpty(p?.financingApproved)
        ? dayjs(p?.financingApproved)
        : null,
      utilityPreInspectionDate: !isEmpty(p?.utilityPreInspectionDate)
        ? dayjs(p?.utilityPreInspectionDate)
        : null,
      utilityPreInspectionDone: !isEmpty(p?.utilityPreInspectionDone)
        ? dayjs(p?.utilityPreInspectionDone)
        : null,
      utilityApprovalLetter: !isEmpty(p?.utilityApprovalLetter)
        ? dayjs(p?.utilityApprovalLetter)
        : null,
      customerWelcomeLetterSent: !isEmpty(p?.customerWelcomeLetterSent)
        ? dayjs(p?.customerWelcomeLetterSent)
        : null,
      mechanicalPOSent: !isEmpty(p?.mechanicalPOSent)
        ? dayjs(p?.mechanicalPOSent)
        : null,
      lightingLaborPOSent: !isEmpty(p?.lightingLaborPOSent)
        ? dayjs(p?.lightingLaborPOSent)
        : null,
      lightingMaterialPOSent: !isEmpty(p?.lightingMaterialPOSent)
        ? dayjs(p?.lightingMaterialPOSent)
        : null,
      refrigerationPOSent: !isEmpty(p?.refrigerationPOSent)
        ? dayjs(p?.refrigerationPOSent)
        : null,
      refrigerationETA: !isEmpty(p?.refrigerationETA)
        ? dayjs(p?.refrigerationETA)
        : null,
      hvacETA: !isEmpty(p?.hvacETA) ? dayjs(p?.hvacETA) : null,
      lightingETA: !isEmpty(p?.lightingETA) ? dayjs(p?.lightingETA) : null,
      refrigerationInstalled: !isEmpty(p?.refrigerationInstalled)
        ? dayjs(p?.refrigerationInstalled)
        : null,
      hvacInstalled: !isEmpty(p?.hvacInstalled)
        ? dayjs(p?.hvacInstalled)
        : null,
      lightingInstalled: !isEmpty(p?.lightingInstalled)
        ? dayjs(p?.lightingInstalled)
        : null,
      lightingMaterialPOAmount: p?.lightingMaterialPOAmount,
      lightingLaborPOAmount: p?.lightingLaborPOAmount,
      mechanicalPOAmount: p?.mechanicalPOAmount,
      refrigerationPOAmount: p?.refrigerationPOAmount,
      completionDocumentSentToCustomer: !isEmpty(
        p?.completionDocumentSentToCustomer
      )
        ? dayjs(p?.completionDocumentSentToCustomer)
        : null,
      closeoutPackageSubmittedToUtility: !isEmpty(
        p?.closeoutPackageSubmittedToUtility
      )
        ? dayjs(p?.closeoutPackageSubmittedToUtility)
        : null,
      utilityPostInspectionScheduleDate: !isEmpty(
        p?.utilityPostInspectionScheduleDate
      )
        ? dayjs(p?.utilityPostInspectionScheduleDate)
        : null,
      utilityPostInspectionCompleteDate: !isEmpty(
        p?.utilityPostInspectionCompleteDate
      )
        ? dayjs(p?.utilityPostInspectionCompleteDate)
        : null,
      accountManagerCommissionRate: p?.accountManagerCommissionRate,
      accountManagerCommissionAmount: p?.accountManagerCommissionAmount,
      leadGeneratorCommissionRate: p?.leadGeneratorCommissionRate,
      leadGeneratorCommissionAmount: p?.leadGeneratorCommissionAmount,
      salesSupportCommissionRate: p?.salesSupportCommissionRate,
      salesSupportCommissionAmount: p?.salesSupportCommissionAmount,
      commissionPaid: !isEmpty(p?.commissionPaid)
        ? dayjs(p?.commissionPaid)
        : null,
      soldTotalProjectValue: p?.soldTotalProjectValue,
      soldTotalIncentive: p?.soldTotalIncentive,
      soldTotalCustomerPortion: p?.soldTotalCustomerPortion,
      soldLtgValue: p?.soldLtgValue,
      soldHvacValue: p?.soldHvacValue,
      soldRefrigerationValue: p?.soldRefrigerationValue,
      soldBudgetCosts: p?.soldBudgetCosts,
      soldProfit: p?.soldProfit,
      soldMargin: (Number(p?.soldMargin ?? 0) * 100)?.toFixed(2),
      billedDepositDate: !isEmpty(p?.billedDepositDate)
        ? dayjs(p?.billedDepositDate)
        : null,
      billedDepositAmount: p?.billedDepositAmount,
      depositReceivedDate: !isEmpty(p?.depositReceivedDate)
        ? dayjs(p?.depositReceivedDate)
        : null,
      depositReceivedAmount: p?.depositReceivedAmount,
      depositClearedDate: !isEmpty(p?.depositClearedDate)
        ? dayjs(p?.depositClearedDate)
        : null,
      billedIncentiveDate: !isEmpty(p?.billedIncentiveDate)
        ? dayjs(p?.billedIncentiveDate)
        : null,
      billedIncentiveAmount: p?.billedIncentiveAmount,
      billedIncentiveReceivedDate: !isEmpty(p?.billedIncentiveReceivedDate)
        ? dayjs(p?.billedIncentiveReceivedDate)
        : null,
      billedIncentiveReceivedAmount: p?.billedIncentiveReceivedAmount,
      billedFinancedPortionDate: !isEmpty(p?.billedFinancedPortionDate)
        ? dayjs(p?.billedFinancedPortionDate)
        : null,
      billedFinancedPortionAmount: p?.billedFinancedPortionAmount,
      financedPortionReceivedDate: !isEmpty(p?.financedPortionReceivedDate)
        ? dayjs(p?.financedPortionReceivedDate)
        : null,
      financedPortionReceivedAmount: p?.financedPortionReceivedAmount,
      overageCustomerPortionDate: !isEmpty(p?.overageCustomerPortionDate)
        ? dayjs(p?.overageCustomerPortionDate)
        : null,
      overageCustomerPortionAmount: p?.overageCustomerPortionAmount,
      overageCustomerPortionReceivedDate: !isEmpty(
        p?.overageCustomerPortionReceivedDate
      )
        ? dayjs(p?.overageCustomerPortionReceivedDate)
        : null,
      overageCustomerPortionReceivedAmount:
        p?.overageCustomerPortionReceivedAmount,
      accrualDate: !isEmpty(p?.accrualDate) ? dayjs(p?.accrualDate) : null,
      financeLender: p?.financeLender ?? "",
      financeExpirationDate: !isEmpty(p?.financeExpirationDate)
        ? dayjs(p?.financeExpirationDate)
        : null,
      preFunding: p?.preFunding ?? false,
      deferment: p?.deferment ?? "",
      fiftyPercentPrefundInvoiceDate: !isEmpty(
        p?.fiftyPercentPrefundInvoiceDate
      )
        ? dayjs(p?.fiftyPercentPrefundInvoiceDate)
        : null,
      twentyFivePercentPrefundInvoiceDate: !isEmpty(
        p?.twentyFivePercentPrefundInvoiceDate
      )
        ? dayjs(p?.twentyFivePercentPrefundInvoiceDate)
        : null,
    }),
    []
  );

  const onFinishHandler = useCallback(
    async (p: IProject) => {
      modal.confirm({
        title: "Confirm Project Updates",
        icon: <ExclamationCircleOutlined />,
        content: "Are you sure you want to save these project updates?",
        okText: "Save",
        cancelText: "Cancel",
        onOk: async () => {
          const projectToUpdate = {
            ...omit(project, [
              "mmProjectId",
              "metadata",
              "gsi",
              "searchName",
              "mmProjectIdNumber",
            ]),
            ...eachPropertyNulltoUndefined(updateProjectWithDefaultValues(p)),
            id: project.id,
            latestProjectNote: p.latestProjectNote,
          };
          await updateProject(projectToUpdate);
        },
      });
    },
    [modal, project, updateProject, updateProjectWithDefaultValues]
  );

  useEffect(() => {
    getUserMentionOptions();
  }, [getUserMentionOptions, project?.id]);

  useEffect(() => {
    if (success) {
      api.success({
        duration: 3,
        message: "Update project complete",
        description: "Your project has been updated successfully.",
      });
      if (onFinish) onFinish();
    } else if (error) {
      api.error({
        message: "Update project error",
        description: error?.message,
      });
      executeScroll();
    }
  }, [success, error, api, executeScroll, onFinish]);

  useEffect(() => {
    if (!data) return;

    form.setFieldsValue(updateProjectWithDefaultValues(data));
  }, [form, updateProjectWithDefaultValues, data]);

  return (
    <div key={project?.id}>
      {notificationContextHolder}
      {contextHolder}
      <Form
        form={form}
        name={`projectForm-${project?.id}`}
        data-testid={`projectForm-${project?.id}`}
        className='update-project-form'
        layout='vertical'
        requiredMark={false}
        initialValues={updateProjectWithDefaultValues(project)}
        onFinish={onFinishHandler}
        scrollToFirstError
      >
        <Flex vertical gap='2rem 0'>
          {error && (
            <Alert
              ref={errorRef}
              message='Error'
              description={error?.message}
              type='error'
              showIcon
            />
          )}
          <ProjectContactInformation form={form} />
          <ProjectInformation form={form} />
          <ProjectInstallationInformation form={form} />
          <ProjectAccountingInformation form={form} />
          <ProjectSalesInformation form={form} />
        </Flex>
        <Flex vertical={false} gap='2rem 0' justify='space-between'>
          <Col span={11} className='project-list-project-notes-container'>
            <Row>
              <Col span={24}>
                <NotesList
                  title='Notes'
                  height={400}
                  projectId={project.id}
                  allowDismiss={false}
                  showProjectName={false}
                />
              </Col>
            </Row>
          </Col>
          <Col span={11} className='project-list-update-form-container'>
            <Row>
              <Col span={24}>
                <Flex
                  justify='flex-start'
                  gap={"0 0.5rem"}
                  align='flex-start'
                  className='project-list-project-form-title-container'
                >
                  <Image
                    src={numberOneImage}
                    alt='Step one'
                    preview={false}
                    width={36}
                  />
                  <Typography.Title
                    className='project-list-project-form-title-medium'
                    level={3}
                  >
                    Add Project Note
                  </Typography.Title>
                </Flex>
                <Form.Item
                  name='latestProjectNote'
                  data-testid='latestProjectNote'
                  rules={[
                    {
                      required: true,
                      message: "Please enter a note.",
                    },
                  ]}
                >
                  <MentionsComponent
                    options={userMentionOptions ?? []}
                    onMouseLeave={(content, mentionIds) => {
                      if (isEmpty(content)) {
                        form.setFieldValue("latestProjectNote", undefined);
                        return;
                      }

                      form.setFieldValue("latestProjectNote", {
                        content,
                        mentionIds,
                      });
                    }}
                    autoSize={{ minRows: 8, maxRows: 8 }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Flex
              justify='flex-start'
              gap={"0 0.5rem"}
              align='flex-start'
              className='project-list-project-form-title-container'
            >
              <Image
                src={numberTwoImage}
                alt='Step two'
                preview={false}
                width={36}
              />
              <Typography.Title
                className='project-list-project-form-title-medium'
                level={3}
              >
                Update Project
              </Typography.Title>
            </Flex>
            <Row gutter={{ xs: 4, sm: 8, md: 16, lg: 20 }}>
              <Col>
                <Form.Item
                  name='stage'
                  data-testid='stage'
                  label={
                    <>
                      <Space>
                        <Image
                          src={selectProjectStage}
                          alt='Select Project Stage'
                          preview={false}
                          width={22}
                        />
                        <Typography.Text className='project-list-project-form-title-small'>
                          Select Project Stage
                        </Typography.Text>
                      </Space>
                    </>
                  }
                >
                  <Select
                    size='large'
                    disabled={user?.role?.name === UserRoles.Subcontractor}
                    options={[
                      { value: "", label: "Select..." },
                      ...sortBy(ProjectStages, (s) => s.label),
                    ]}
                    onSelect={(value) => {
                      form.setFieldValue("stage", value);
                    }}
                  />
                </Form.Item>
              </Col>

              <Col>
                <Form.Item
                  name='status'
                  data-testid='status'
                  label={
                    <>
                      <Space>
                        <Image
                          src={projectStatusIcon}
                          alt='Select Project Status'
                          preview={false}
                          width={22}
                        />
                        <Typography.Text className='project-list-project-form-title-small'>
                          Project Status
                        </Typography.Text>
                      </Space>
                    </>
                  }
                >
                  <Select
                    size='large'
                    disabled={user?.role?.name === UserRoles.Subcontractor}
                    options={[
                      { value: "", label: "Select..." },
                      ...ProjectStatuses,
                    ]}
                    onSelect={(value) => {
                      form.setFieldValue("status", value);
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Flex
              justify='flex-start'
              gap={"0 0.5rem"}
              align='flex-start'
              className='project-list-project-form-title-container'
            >
              <Image
                src={numberThreeImage}
                alt='Step three'
                preview={false}
                width={40}
              />
              <SubmitButton
                overrideDisabled={false}
                className='project-list-project-form-submit-button'
                testId='project-list-project-form-submit-button'
                form={form}
              >
                Send Note & Save Updates
              </SubmitButton>
            </Flex>
            <Flex
              gap={"0 0.5rem"}
              className='project-list-project-form-title-container'
            >
              <div style={{ width: 40 }}></div>
              <Button
                ghost
                block
                type='primary'
                className='project-list-project-form-reset-button'
                size='large'
                onClick={() => {
                  form.resetFields();
                }}
              >
                Clear all updates
              </Button>
            </Flex>
          </Col>
        </Flex>
      </Form>
    </div>
  );
});
