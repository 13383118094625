import { Col, Row, Card, Skeleton } from "antd";

import "./FinanceManagerFinancialsDatatablesCard.scss";

import {
  OpenAccountsReceivableIncentive,
  OpenAccountsReceivableFinanced,
  OpenAccountsReceivableCustomerDirect,
  OpenProjectsDepositsReceived,
} from "financials/components";

type Props = {
  loaded?: boolean;
};

export const FinanceManagerFinancialsDatatablesCard = ({ loaded }: Props) => {
  return (
    <Card className='finance-manager-financials-datatables-card-container'>
      {loaded ? (
        <>
          <Row>
            <Col span={24}>
              <OpenAccountsReceivableIncentive />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <OpenAccountsReceivableFinanced />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <OpenAccountsReceivableCustomerDirect />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <OpenProjectsDepositsReceived />
            </Col>
          </Row>
        </>
      ) : (
        <Skeleton />
      )}
    </Card>
  );
};
