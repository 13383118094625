import { Col, Row, Typography } from "antd";

import "./InstallationsRecap.scss";
import { StatisticCard } from "shared/components";
import { utilityService } from "shared/services";
import { useBoundStore } from "store";

export const InstallationsRecap = () => {
  const { installationsRecap } = useBoundStore();
  return (
    <div className='installations-recap-container'>
      <Row>
        <Typography.Title className='installations-recap-title' level={3}>
          Installations Recap
        </Typography.Title>
      </Row>
      <Row
        gutter={{ xs: 4, sm: 8, md: 16, lg: 20 }}
        justify={"space-between"}
        className='installations-recap-row'
      >
        <Col span={16}>
          <Typography.Text>Total # of Projects</Typography.Text>
        </Col>
        <Col span={8}>
          <StatisticCard
            value={installationsRecap?.totalProjects}
            formatter={utilityService.statisticsFormatter}
            backgroundColor='#EBF3FE'
          />
        </Col>
      </Row>
      <Row
        gutter={{ xs: 4, sm: 8, md: 16, lg: 20 }}
        justify={"space-between"}
        className='installations-recap-row'
      >
        <Col span={16}>
          <Typography.Text>HVAC Backlog</Typography.Text>
        </Col>
        <Col span={8}>
          <StatisticCard
            value={installationsRecap?.hvacBacklog}
            prefix='$'
            formatter={utilityService.statisticsFormatter}
            backgroundColor='#E2FFF3'
          />
        </Col>
      </Row>
      <Row
        gutter={{ xs: 4, sm: 8, md: 16, lg: 20 }}
        justify={"space-between"}
        className='installations-recap-row'
      >
        <Col span={16}>
          <Typography.Text>Lighting Backlog</Typography.Text>
        </Col>
        <Col span={8}>
          <StatisticCard
            value={installationsRecap?.lightingBacklog}
            prefix='$'
            formatter={utilityService.statisticsFormatter}
            backgroundColor='#E2FFF3'
          />
        </Col>
      </Row>
      <Row
        gutter={{ xs: 4, sm: 8, md: 16, lg: 20 }}
        justify={"space-between"}
        className='installations-recap-row'
      >
        <Col span={16}>
          <Typography.Text>Refrigeration Backlog</Typography.Text>
        </Col>
        <Col span={8}>
          <StatisticCard
            value={installationsRecap?.refBacklog}
            prefix='$'
            formatter={utilityService.statisticsFormatter}
            backgroundColor='#E2FFF3'
          />
        </Col>
      </Row>
      <Row
        gutter={{ xs: 4, sm: 8, md: 16, lg: 20 }}
        justify={"space-between"}
        className='installations-recap-row'
      >
        <Col span={16}>
          <Typography.Text>Total # of Projects in Closeout</Typography.Text>
        </Col>
        <Col span={8}>
          <StatisticCard
            value={installationsRecap?.totalProjectsInCloseout}
            formatter={utilityService.statisticsFormatter}
            backgroundColor='#EBF3FE'
          />
        </Col>
      </Row>
      <Row
        gutter={{ xs: 4, sm: 8, md: 16, lg: 20 }}
        justify={"space-between"}
        className='installations-recap-row'
      >
        <Col span={16}>
          <Typography.Text>
            Revenue of Projects in Closeout Status
          </Typography.Text>
        </Col>
        <Col span={8}>
          <StatisticCard
            value={installationsRecap?.totalProjectsInCloseoutRevenue}
            prefix='$'
            formatter={utilityService.statisticsFormatter}
            backgroundColor='#E2FFF3'
          />
        </Col>
      </Row>
    </div>
  );
};
