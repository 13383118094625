import { GetProp, Popconfirm, TableProps } from "antd";

import { utilityService } from "shared/services";
import { ISubcontractorMember } from "subcontractors/models";

type ColumnsType<T extends object> = GetProp<TableProps<T>, "columns">;

export const SubcontractorMemberColumns = (
  onDelete: (id: string | undefined) => void
): ColumnsType<ISubcontractorMember> => [
  {
    title: "",
    dataIndex: "id",
    width: 100,
    fixed: "left",
    render: (_value: string, _record: ISubcontractorMember, index: number) =>
      index + 1,
  },
  {
    title: "Name",
    dataIndex: "name",
    sorter: (a, b) => (a.name ?? "").localeCompare(b.name ?? ""),
    render: (value: string, _record: ISubcontractorMember, _index: number) =>
      utilityService.truncateField(value),
  },
  {
    title: "",
    dataIndex: "delete",
    render: (_, record) => (
      <Popconfirm
        title='Sure to delete?'
        onConfirm={() => onDelete(record?.id)}
      >
        <a>Delete</a>
      </Popconfirm>
    ),
  },
];
