import { Card } from "antd";

import "./ProgramManagerDashboardNotesCard.scss";

import { NotesList } from "notes/components";

export const ProgramManagerDashboardNotesCard = () => {
  return (
    <Card className='program-manager-dashboard-notes-card-container'>
      <NotesList title='My Project Notes' height={900} />
    </Card>
  );
};
