import { Row, Table, TableProps, Typography } from "antd";
import "./PipelineDetails.scss";
import React from "react";
import { useBoundStore } from "store";
import { utilityService } from "shared/services";
import { PipelineDetailsColumns } from "sales/constants";
import { IPipelineDetails } from "sales/models";

export const PipelineDetails = () => {
  const tblRef: Parameters<typeof Table>[0]["ref"] = React.useRef(null);
  const tableColumns = PipelineDetailsColumns.map((item) => ({
    ...item,
  }));
  const tableProps: TableProps<IPipelineDetails> = {};
  const { pipelineDetails } = useBoundStore();

  return (
    <>
      <Row>
        <Typography.Title className='pipeline-details-title' level={3}>
          Pipeline Details
        </Typography.Title>
      </Row>
      <Row>
        <Table
          {...tableProps}
          columns={tableColumns}
          dataSource={pipelineDetails}
          className='pipeline-details-table'
          ref={tblRef}
          rowKey='id'
          pagination={false}
          summary={(pageData) => {
            let totalCommission = 0;
            let totalRevenue = 0;

            pageData.forEach(({ commission, revenue }) => {
              totalRevenue += Number(revenue);
              totalCommission += Number(commission);
            });

            return (
              <>
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0}></Table.Summary.Cell>
                  <Table.Summary.Cell index={1}>
                    <Typography.Text strong>TOTALS</Typography.Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={2}>
                    <Typography.Text strong>
                      ${utilityService.statisticsFormatter(totalRevenue)}
                    </Typography.Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={3}>
                    <Typography.Text strong>
                      ${utilityService.statisticsFormatter(totalCommission)}
                    </Typography.Text>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </>
            );
          }}
        />
      </Row>
    </>
  );
};
