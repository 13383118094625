import { useBoundStore } from "store";
import { authService } from "../services";

export const useAuth = () => {
  const { user } = useBoundStore();
  const isAuthenticated = async () => await authService.isAuthenticated();
  const hasPermissions = (permissionsNeededToAccessRoute: string[]) =>
    authService.hasPermissions(user, permissionsNeededToAccessRoute);

  return {
    isAuthenticated,
    hasPermissions,
  };
};
