import { Outlet } from "react-router-dom";

import { SideNavPageLayout } from "shared/components";

export const Dashboard = () => {
  return (
    <SideNavPageLayout>
      <Outlet />
    </SideNavPageLayout>
  );
};
