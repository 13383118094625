import { StateCreator } from "zustand";

import { ISimpleUser, IUserSlice } from "../models";
import { ISelectOption } from "shared/models";
import { sliceResetFns } from "store";

const initialState = {
  user: {
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    role: undefined,
    configuration: undefined,
  },
  userMentionOptions: [],
};

export const createUserSlice: StateCreator<
  IUserSlice,
  [["zustand/devtools", never]],
  [],
  IUserSlice
> = (set) => {
  sliceResetFns.add(() => set(initialState));
  return {
    ...initialState,
    setUser: (user: ISimpleUser) =>
      set((state) => ({
        user: {
          ...state.user,
          ...user,
        },
      })),
    setUserMentionOptions: (userMentionOptions: ISelectOption[]) =>
      set((state) => ({
        userMentionOptions: [
          ...state.userMentionOptions,
          ...userMentionOptions,
        ],
      })),
    resetUserSlice: () => set(() => initialState),
  };
};
