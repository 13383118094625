import {
  Alert,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Typography,
  notification,
} from "antd";

import "./AddProjectModal.scss";
import {
  CustomerPaymentMethods,
  ProjectTypes,
  UtilityPrograms,
} from "projects/constants";
import { useBoundStore } from "store";
import { useEffect, useState } from "react";
import { useAddProject } from "projects/hooks";
import { MultiSelectBox } from "shared/components";
import MentionsComponent from "shared/components/mentions/Mentions";
import { isEmpty } from "lodash";
import { useGetUserMentionOptions } from "users/hooks";
type Props = {
  show: boolean;
  onClose: () => void;
};

export const AddProjectModal = ({ show, onClose }: Props) => {
  const [submittable, setSubmittable] = useState<boolean>(false);
  const [form] = Form.useForm();
  const { salesRepsSelectOptions } = useBoundStore();
  const { success, loading, error, addProject } = useAddProject();
  const [api, contextHolder] = notification.useNotification();
  const { getUserMentionOptions } = useGetUserMentionOptions();
  const { userMentionOptions } = useBoundStore();

  const onFinishHandler = async (values: any) => {
    await addProject(values);
    if (onClose) onClose();
    form.resetFields();
  };

  const handleCancel = () => {
    if (onClose) onClose();
  };

  const values = Form.useWatch([], form);

  useEffect(() => {
    getUserMentionOptions();
  }, [getUserMentionOptions]);

  useEffect(() => {
    form
      .validateFields({ validateOnly: true })
      .then(() => setSubmittable(true))
      .catch(() => setSubmittable(false));
  }, [form, values]);

  useEffect(() => {
    if (success) {
      api.success({
        duration: 3,
        message: "Project Added Successfully",
        description: "Your project has been added.",
      });
    } else if (error) {
      api.error({
        message: "Error",
        description: error?.message,
      });
    }
  }, [success, error, api]);

  return (
    <div>
      {contextHolder}
      <Modal
        title='Add New Project'
        okText='Create Project'
        okButtonProps={{
          style: {
            borderRadius: 8,
          },
          disabled: !submittable,
        }}
        cancelButtonProps={{
          ghost: true,
          type: "primary",
          style: {
            borderRadius: 8,
            marginRight: 16,
          },
        }}
        open={show}
        onOk={form.submit}
        onCancel={handleCancel}
        width='80%'
        className='add-project-container'
        confirmLoading={loading}
      >
        <Typography.Text style={{ fontSize: "1rem", color: "#161717" }}>
          Please enter in the (*) required information, then click “Create
          Project” below.
        </Typography.Text>
        <Form
          form={form}
          className='add-project-form'
          name='add-project-form'
          data-testid='add-project-form'
          layout='vertical'
          onFinish={onFinishHandler}
        >
          {error && (
            <Alert
              data-testid='add-project-error'
              className='add-project-error'
              message='Error'
              description={error?.message}
              type='error'
              showIcon
            />
          )}
          <Row gutter={{ xs: 4, sm: 8, md: 16, lg: 20 }}>
            <Col span={6}>
              <Form.Item
                name='name'
                data-testid='name'
                label='Project Name'
                rules={[
                  {
                    required: true,
                    message: "Please enter project name.",
                  },
                  {
                    type: "string",
                    max: 50,
                    message: "Project name must be 50 characters or less.",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name='address'
                data-testid='address'
                label='Address'
                rules={[
                  {
                    required: true,
                    message: "Please enter address.",
                  },
                  {
                    type: "string",
                    max: 100,
                    message: "Your address must be 100 characters or less.",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={3}>
              <MultiSelectBox
                options={ProjectTypes}
                name='projectTypes'
                label='Project Type(s)'
                rules={[
                  {
                    required: true,
                    message: "Please enter project type.",
                  },
                ]}
                minWidth={0}
              />
            </Col>
            <Col span={3}>
              <Form.Item
                name='accountManagerId'
                data-testid='accountManagerId'
                label='Account Manager'
              >
                <Select
                  defaultValue=''
                  options={[
                    { value: "", label: "Select..." },
                    ...salesRepsSelectOptions,
                  ]}
                  onSelect={(value) => {
                    form.setFieldValue("accountManagerId", value);
                  }}
                />{" "}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={{ xs: 4, sm: 8, md: 16, lg: 20 }}>
            <Col span={12}>
              <Row gutter={{ xs: 4, sm: 8, md: 16, lg: 20 }}>
                <Col span={7}>
                  <Form.Item
                    name='city'
                    data-testid='city'
                    label='City'
                    rules={[
                      {
                        required: true,
                        message: "Please enter city.",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={7}>
                  {" "}
                  <Form.Item
                    name='state'
                    data-testid='state'
                    label='State'
                    rules={[
                      {
                        required: true,
                        message: "Please enter state.",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={10}>
                  <Form.Item
                    name='zip'
                    data-testid='zip'
                    label='Zip'
                    rules={[
                      {
                        required: true,
                        message: "Please enter zip code.",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={6}>
              {" "}
              <Form.Item
                name='utilityProjectId'
                data-testid='utilityProjectId'
                label='Utility Project ID'
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={3}>
              {" "}
              <Form.Item
                name='utilityProgram'
                data-testid='utilityProgram'
                label='Utility Program'
                rules={[
                  {
                    required: true,
                    message: "Please enter utility program.",
                  },
                ]}
              >
                <Select
                  defaultValue=''
                  options={[
                    { value: "", label: "Select..." },
                    ...UtilityPrograms,
                  ]}
                  onSelect={(value) => {
                    form.setFieldValue("utilityProgram", value);
                  }}
                />{" "}
              </Form.Item>
            </Col>
            <Col span={3}>
              <Form.Item
                name='leadGeneratorId'
                data-testid='leadGeneratorId'
                label='Lead Generator'
              >
                <Select
                  options={[
                    { value: "", label: "Select..." },
                    ...salesRepsSelectOptions,
                  ]}
                  defaultValue=''
                  onSelect={(value) => {
                    form.setFieldValue("leadGeneratorId", value);
                  }}
                />{" "}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={{ xs: 4, sm: 8, md: 16, lg: 20 }}>
            <Col span={6}>
              {" "}
              <Form.Item name='neifId' data-testid='neifId' label='NEIF ID'>
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name='ownerName'
                data-testid='ownerName'
                label='Owner Name'
                rules={[
                  {
                    required: true,
                    message: "Please enter owner name.",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={3}>
              <Form.Item
                name='customerPaymentMethod'
                data-testid='customerPaymentMethod'
                label='Customer Payment Method'
                rules={[
                  {
                    required: true,
                    message: "Please enter customer payment method.",
                  },
                ]}
              >
                <Select
                  options={[
                    { value: "", label: "Select..." },
                    ...CustomerPaymentMethods,
                  ]}
                  defaultValue=''
                  onSelect={(value) => {
                    form.setFieldValue("customerPaymentMethod", value);
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={3}>
              <Form.Item
                name='salesSupportId'
                data-testid='salesSupportId'
                label='Sales Support'
              >
                <Select
                  options={[
                    { value: "", label: "Select..." },
                    ...salesRepsSelectOptions,
                  ]}
                  defaultValue=''
                  onSelect={(value) => {
                    form.setFieldValue("salesSupportId", value);
                  }}
                />{" "}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={{ xs: 4, sm: 8, md: 16, lg: 20 }}>
            <Col span={6}>
              <Form.Item
                name='ownerEmail'
                data-testid='ownerEmail'
                label='Owner Email'
                rules={[
                  {
                    required: true,
                    type: "email",
                    message: "Please enter owner email.",
                  },
                ]}
              >
                <Input type='email' />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                name='ownerPhone'
                data-testid='ownerPhone'
                label='Owner Phone'
                rules={[
                  {
                    required: true,
                    message: "Please enter owner phone.",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={{ xs: 4, sm: 8, md: 16, lg: 20 }}>
            <Col span={24}>
              <Form.Item
                name='latestProjectNote'
                data-testid='latestProjectNote'
                label='Add Project Note (Optional)'
              >
                <MentionsComponent
                  options={userMentionOptions ?? []}
                  onMouseLeave={(content, mentionIds) => {
                    if (isEmpty(content)) {
                      form.setFieldValue("latestProjectNote", undefined);
                      return;
                    }

                    form.setFieldValue("latestProjectNote", {
                      content,
                      mentionIds,
                    });
                  }}
                  autoSize={{ minRows: 8, maxRows: 8 }}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
};
