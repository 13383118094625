import { useState } from "react";

import { projectService } from "../services";
import { IProject } from "projects/models";

export const useUpdateProject = () => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState<Error | undefined>(undefined);
  const [data, setData] = useState<IProject | undefined>(undefined);

  const updateProject = async (project: IProject) => {
    try {
      setLoading(true);
      setSuccess(false);
      setError(undefined);
      setData(undefined);

      if (!project?.id) throw new Error("Project not found.");

      const updatedProject = await projectService.updateProject(
        project.id,
        project
      );

      setData(updatedProject);
      setSuccess(true);
    } catch (error) {
      setSuccess(false);
      setError(error as Error);
    } finally {
      setLoading(false);
    }
  };

  return {
    updateProject,
    loading,
    success,
    error,
    data,
  };
};
