import { Space, Typography } from "antd";
import { Link } from "react-router-dom";

import "./SignUpLink.scss";

export const SignUpLink = () => {
  return (
    <Typography.Paragraph strong className='sign-up-link'>
      <Space>
        Don't have an account?
        <Link to='/signup'>Sign Up</Link>
      </Space>
    </Typography.Paragraph>
  );
};
