import { httpService } from "shared/services";
import { ISalesInfo, ISalesPipelineInfo } from "sales/models";

const apiPath = `${process.env.REACT_APP_API_BASE_URL}/api/v1/sales`;

export const getSalesInfo = async () => {
  const res = await httpService.instance.get<ISalesInfo>(`${apiPath}/info`);
  return res?.data;
};

export const getSalesPipelineInfo = async () => {
  const res = await httpService.instance.get<ISalesPipelineInfo>(
    `${apiPath}/pipeline/info`
  );
  return res?.data;
};
