import { Card } from "antd";

import "./OperationsManagerDashboardNotesCard.scss";

import { NotesList } from "notes/components";

export const OperationsManagerDashboardNotesCard = () => {
  return (
    <Card className='operations-manager-dashboard-notes-card-container'>
      <NotesList title='My Project Notes' height={1625} />
    </Card>
  );
};
