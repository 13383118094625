import { Col, notification, Row } from "antd";

import "./FieldAuditorDashboard.scss";
import { useEffect } from "react";
import { useGetAuditInfo } from "audit/hooks";
import { FieldAuditorDashboardAuditAppointmentsCard } from "./field-auditor-dashboard-audit-appointments-card/FieldAuditorDashboardAuditAppointmentsCard";
import { FieldAuditorDashboardNotesCard } from "./field-auditor-dashboard-notes-card/FieldAuditorDashboardNotesCard";
import { FieldAuditorMapCard } from "./field-auditor-map-card/FieldAuditorMapCard";

export const FieldAuditorDashboard = () => {
  const { getAuditInfo, error, success } = useGetAuditInfo();
  const [api, notificationContextHolder] = notification.useNotification();

  useEffect(() => {
    getAuditInfo();
  }, []);

  useEffect(() => {
    if (error) {
      api.error({
        message: "Error",
        description: error?.message,
      });
    }
  }, [error, api]);

  return (
    <>
      {notificationContextHolder}
      <Col span={24} className='field-auditor-dashboard'>
        <Row
          gutter={{ xs: 4, sm: 8, md: 16, lg: 20 }}
          justify={"space-between"}
        >
          <Col span={16}>
            <FieldAuditorMapCard loaded={success} />
          </Col>
          <Col span={8}>
            <FieldAuditorDashboardNotesCard />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <FieldAuditorDashboardAuditAppointmentsCard loaded={success} />
          </Col>
        </Row>
      </Col>
    </>
  );
};
