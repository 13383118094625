import { useState } from "react";
import { subcontractorService } from "subcontractors/services";

export const useDeleteSubcontractor = () => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState<Error | undefined>(undefined);

  const deleteSubcontractor = async (subcontractorId: string) => {
    try {
      setLoading(true);
      setSuccess(false);
      setError(undefined);

      await subcontractorService.deleteSubcontractor(subcontractorId);

      setSuccess(true);
    } catch (error) {
      setSuccess(false);
      setError(error as Error);
    } finally {
      setLoading(false);
    }
  };

  return {
    deleteSubcontractor,
    loading,
    success,
    error,
  };
};
