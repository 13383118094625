import { IAuditAppointment } from "audit/models";
import { head, isEmpty } from "lodash";
import { useState } from "react";

type Poi = {
  key: string;
  location: google.maps.LatLngLiteral;
  scheduled: boolean;
};

export const useGetGeocoder = () => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState<Error | undefined>(undefined);
  const [data, setData] = useState<Poi[]>([]);

  const getPois = async (
    geocodingLib: google.maps.GeocodingLibrary,
    addresses: IAuditAppointment[]
  ) => {
    try {
      setLoading(true);
      setSuccess(false);
      setError(undefined);
      setData([]);

      const geocoder = new geocodingLib.Geocoder();
      const pois = await convertAddressesToGeocodes(geocoder, addresses);

      setData(pois);
      setSuccess(true);
    } catch (error) {
      setSuccess(false);
      setError(error as Error);
      setData([]);
    } finally {
      setLoading(false);
    }
  };

  const addressToGeocode = (
    geocoder: google.maps.Geocoder,
    address: string
  ): Promise<google.maps.LatLngLiteral> =>
    new Promise((resolve, reject) => {
      geocoder.geocode(
        { address: address },
        (results: any, status: google.maps.GeocoderStatus) => {
          if (status !== google.maps.GeocoderStatus.OK) reject();

          const latitude = results[0].geometry.location.lat();
          const longitude = results[0].geometry.location.lng();
          resolve({ lat: latitude, lng: longitude });
        }
      );
    });

  const convertAddressesToGeocodes = (
    geocoder: google.maps.Geocoder,
    locations: IAuditAppointment[]
  ): Promise<Poi[]> => {
    return Promise.all(
      locations.map(async (l) => ({
        key: l.projectName,
        location: await addressToGeocode(geocoder, l.address),
        scheduled: !isEmpty(l.auditScheduleDate),
      }))
    );
  };

  return {
    getPois,
    loading,
    success,
    error,
    data,
  };
};
