import { Button, Divider, Flex, Form, Typography, Image, Switch } from "antd";
import { mapValues, unionBy } from "lodash";

import filterProjectsIcon from "../../assets/images/filter-projects.svg";
import paymentMethodIcon from "../../assets/images/payment-method.svg";
import projectStatusIcon from "../../assets/images/project-status.svg";
import salesPersonIcon from "../../assets/images/sales-person.svg";
import subcontractorIcon from "../../assets/images/subcontractor.svg";
import utilityProgramIcon from "../../assets/images/utility-program.svg";
import { MultiSelectBox } from "shared/components";
import {
  CustomerPaymentMethods,
  ProjectStatuses,
  UtilityPrograms,
} from "projects/constants";
import { IProjectFilter } from "projects/models";
import { useBoundStore } from "store";
import "./ProjectFilters.scss";

export const ProjectFilters = () => {
  const [form] = Form.useForm();
  const {
    setFilters,
    clearFilters,
    hvacSubcontractorsSelectOptions,
    lightingSubcontractorsSelectOptions,
    refrigerationSubcontractorsSelectOptions,
    salesRepsSelectOptions,
    setShowArchived,
    filters,
    showArchived,
  } = useBoundStore();

  const clearFiltersHandler = () => {
    form.setFieldsValue({
      projectStatus: [],
      subcontractor: [],
      utilityProgram: [],
      accountManager: [],
      customerPaymentMethod: [],
    });
    clearFilters();
  };

  const onValuesChangeHandler = async (
    changedValues: any,
    allValues: IProjectFilter
  ) => {
    const filters = mapValues(allValues, (x) => x ?? []);
    setFilters(filters);
  };

  const onShowArchivedSwitchChangeHandler = (checked: boolean) => {
    setShowArchived(checked);
  };

  return (
    <>
      <Flex gap='middle'>
        <Flex gap='small'>
          <Image
            src={filterProjectsIcon}
            alt='Filter Projects'
            preview={false}
            width={20}
          />
          <Typography.Title className='project-filters-title' level={2}>
            Filter Projects
          </Typography.Title>
        </Flex>
        <Button ghost type='primary' size='small' onClick={clearFiltersHandler}>
          Clear all filters
        </Button>
        <Flex gap='middle' className='project-filters-show-archived-switch'>
          <Typography.Text>Show Archived</Typography.Text>
          <Switch
            defaultValue={showArchived}
            onChange={onShowArchivedSwitchChangeHandler}
          />
        </Flex>
      </Flex>
      <Divider />
      <Form
        form={form}
        name='projectFiltersForm'
        layout='vertical'
        initialValues={{
          projectStatus: [...filters?.projectStatus],
          subcontractor: [...filters?.subcontractor],
          utilityProgram: [...filters?.utilityProgram],
          accountManager: [...filters?.accountManager],
          customerPaymentMethod: [...filters?.customerPaymentMethod],
        }}
        onValuesChange={onValuesChangeHandler}
      >
        <Flex justify='space-between'>
          <MultiSelectBox
            options={ProjectStatuses}
            name='projectStatus'
            label={
              <Flex gap='small'>
                <Image
                  src={projectStatusIcon}
                  alt='Project Status'
                  preview={false}
                  width={20}
                />
                Project Status
              </Flex>
            }
          />
          <MultiSelectBox
            options={unionBy(
              [
                ...hvacSubcontractorsSelectOptions,
                ...lightingSubcontractorsSelectOptions,
                ...refrigerationSubcontractorsSelectOptions,
              ],
              "value"
            )}
            name='subcontractor'
            label={
              <Flex gap='small'>
                <Image
                  src={subcontractorIcon}
                  alt='Subcontractor'
                  preview={false}
                  width={20}
                />
                Subcontractor
              </Flex>
            }
          />
          <MultiSelectBox
            options={UtilityPrograms}
            name='utilityProgram'
            label={
              <Flex gap='small'>
                <Image
                  src={utilityProgramIcon}
                  alt='Utility Program'
                  preview={false}
                  width={20}
                />
                Utility Program
              </Flex>
            }
          />
          <MultiSelectBox
            options={salesRepsSelectOptions}
            name='accountManager'
            label={
              <Flex gap='small'>
                <Image
                  src={salesPersonIcon}
                  alt='Account Manager'
                  preview={false}
                  width={20}
                />
                Account Manager
              </Flex>
            }
          />
          <MultiSelectBox
            options={CustomerPaymentMethods}
            name='customerPaymentMethod'
            label={
              <Flex gap='small'>
                <Image
                  src={paymentMethodIcon}
                  alt='Customer Payment Method'
                  preview={false}
                  width={20}
                />
                Customer Payment Method
              </Flex>
            }
          />
        </Flex>
      </Form>
    </>
  );
};
