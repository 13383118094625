// modules
import React from "react";

// styling
import "./PhoneNumber.scss";
import { Col, Form, Input, Row } from "antd";
import { isEmpty } from "lodash";

export const PhoneNumber = () => {
  return (
    <>
      <Form.Item
        name='phoneNumber'
        data-testid='phoneNumber'
        initialValue=''
        hidden
      >
        <Input />
      </Form.Item>
      <Row
        className='phone-number-container'
        gutter={{ xs: 4, sm: 8, md: 16, lg: 20 }}
      >
        <Col span={7}>
          <Form.Item
            name='areaCode'
            data-testid='areaCode'
            label='Phone'
            rules={[
              {
                required: true,
                message: "Please enter a valid phone number.",
              },
              ({ getFieldValue, setFields }) => ({
                async validator(_, value) {
                  const phoneNumberOne = getFieldValue("phoneNumberOne") ?? "";
                  const phoneNumberTwo = getFieldValue("phoneNumberTwo") ?? "";
                  const phoneNumber = `${value ?? ""}${phoneNumberOne}${phoneNumberTwo}`;

                  if (isEmpty(phoneNumber.trim())) return;

                  if (phoneNumber?.length !== 10) {
                    setFields([
                      {
                        name: "phoneNumberOne",
                        value: phoneNumberOne,
                        errors: [" "],
                      },
                      {
                        name: "phoneNumberTwo",
                        value: phoneNumberTwo,
                        errors: [" "],
                      },
                    ]);

                    return Promise.reject(
                      new Error("Your phone number must be 10 characters long.")
                    );
                  }

                  setFields([
                    {
                      name: "phoneNumberOne",
                      value: phoneNumberOne,
                      errors: [],
                    },
                    {
                      name: "phoneNumberTwo",
                      value: phoneNumberTwo,
                      errors: [],
                    },
                    {
                      name: "phoneNumber",
                      value: phoneNumber,
                      errors: [],
                    },
                  ]);

                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input size='large' />
          </Form.Item>
        </Col>
        <Col span={7}>
          <Form.Item
            name='phoneNumberOne'
            data-testid='phoneNumberOne'
            label=' '
            rules={[
              ({ getFieldValue, setFields }) => ({
                async validator(_, value) {
                  const areaCode = getFieldValue("areaCode") ?? "";
                  const phoneNumberTwo = getFieldValue("phoneNumberTwo") ?? "";
                  const phoneNumber = `${areaCode}${value ?? ""}${phoneNumberTwo}`;

                  if (isEmpty(phoneNumber.trim())) return;

                  if (phoneNumber?.length !== 10) {
                    setFields([
                      {
                        name: "areaCode",
                        value: areaCode,
                        errors: [
                          "Your phone number must be 10 characters long.",
                        ],
                      },
                      {
                        name: "phoneNumberTwo",
                        value: phoneNumberTwo,
                        errors: [" "],
                      },
                    ]);

                    return Promise.reject(new Error(" "));
                  }

                  setFields([
                    {
                      name: "areaCode",
                      value: areaCode,
                      errors: [],
                    },
                    {
                      name: "phoneNumberTwo",
                      value: phoneNumberTwo,
                      errors: [],
                    },
                    {
                      name: "phoneNumber",
                      value: phoneNumber,
                      errors: [],
                    },
                  ]);

                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input size='large' />
          </Form.Item>
        </Col>
        <Col span={10}>
          <Form.Item
            name='phoneNumberTwo'
            data-testid='phoneNumberTwo'
            label=' '
            rules={[
              ({ getFieldValue, setFields }) => ({
                async validator(_, value) {
                  const areaCode = getFieldValue("areaCode") ?? "";
                  const phoneNumberOne = getFieldValue("phoneNumberOne") ?? "";
                  const phoneNumber = `${areaCode}${phoneNumberOne}${value ?? ""}`;

                  if (isEmpty(phoneNumber.trim())) return;

                  if (phoneNumber?.length !== 10) {
                    setFields([
                      {
                        name: "areaCode",
                        value: areaCode,
                        errors: [
                          "Your phone number must be 10 characters long.",
                        ],
                      },
                      {
                        name: "phoneNumberOne",
                        value: phoneNumberOne,
                        errors: [" "],
                      },
                    ]);

                    return Promise.reject(new Error(" "));
                  }

                  setFields([
                    {
                      name: "areaCode",
                      value: areaCode,
                      errors: [],
                    },
                    {
                      name: "phoneNumberOne",
                      value: phoneNumberOne,
                      errors: [],
                    },
                    {
                      name: "phoneNumber",
                      value: phoneNumber,
                      errors: [],
                    },
                  ]);

                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input size='large' />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};
