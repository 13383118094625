import { ISelectOption } from "shared/models";

export const DefermentOptions: ISelectOption[] = [
  {
    label: "Not Offered",
    value: "not_offered",
  },
  {
    label: "1 Month",
    value: "one_month",
  },
  {
    label: "2 Months",
    value: "two_months",
  },
  {
    label: "3 Months",
    value: "three_months",
  },
  {
    label: "4 Months",
    value: "four_months",
  },
  {
    label: "5 Months",
    value: "five_months",
  },
  {
    label: "6 Months",
    value: "six_months",
  },
];
