import { Col, Row, Typography, Card, Skeleton } from "antd";

import "./FieldAuditorMapCard.scss";
import { AuditMap } from "audit/components";
import { APIProvider } from "@vis.gl/react-google-maps";

type Props = {
  loaded?: boolean;
};

export const FieldAuditorMapCard = ({ loaded }: Props) => {
  return (
    <Card className='field-auditor-map-card-container'>
      {loaded ? (
        <>
          <Row
            gutter={{ xs: 4, sm: 8, md: 16, lg: 20 }}
            justify={"space-between"}
          >
            <Col>
              <Typography.Title
                className='field-auditor-map-card-title'
                level={3}
              >
                Audit Map
              </Typography.Title>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <APIProvider
                apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY ?? ""}
                libraries={["marker"]}
              >
                <AuditMap />
              </APIProvider>
            </Col>
          </Row>
        </>
      ) : (
        <Skeleton />
      )}
    </Card>
  );
};
