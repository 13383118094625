import { sortBy } from "lodash";
import { ISelectOption } from "shared/models";

export const UtilityPrograms: ISelectOption[] = sortBy(
  [
    {
      label: "JCPL (NJ)",
      value: "jcpl_nj",
    },
    {
      label: "PSEG (NJ)",
      value: "pseg_nj",
    },
    {
      label: "NJNG (NJ)",
      value: "njng_nj",
    },
    {
      label: "O&R (NJ)",
      value: "or_nj",
    },
    {
      label: "ConEd (NY)",
      value: "con_ed_ny",
    },
    {
      label: "Eversource (CT)",
      value: "eversource_ct",
    },
    {
      label: "ACE (NJ)",
      value: "ace_nj",
    },
    {
      label: "TBD",
      value: "tbd",
    },
  ],
  (o) => o.label
);
