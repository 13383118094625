import {
  OwnerDashboard,
  FinanceManagerDashboard,
  AuditAdminDashboard,
  OperationsManagerDashboard,
  ContractorDashboard,
  EngineerDashboard,
  ProgramManagerDashboard,
  FieldAuditorDashboard,
  SalesRepDashboard,
  SubcontractorDashboard,
} from "dashboard/components/dashboards";

export const dashboardsByRole = {
  owner: OwnerDashboard,
  program_manager: ProgramManagerDashboard,
  audit_admin: AuditAdminDashboard,
  contractor_ops: ContractorDashboard,
  engineer: EngineerDashboard,
  operations_manager: OperationsManagerDashboard,
  finance_manager: FinanceManagerDashboard,
  sales_rep: SalesRepDashboard,
  field_auditor: FieldAuditorDashboard,
  subcontractor: SubcontractorDashboard,
  basic: () => <></>,
};
