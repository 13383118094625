import { Card, Statistic, Typography } from "antd";
import { Formatter, valueType } from "antd/es/statistic/utils";

import "./StatisticCard.scss";

type Props = {
  Icon?: () => JSX.Element;
  title?: string;
  value: valueType;
  precision?: number;
  prefix?: string;
  formatter?: Formatter;
  backgroundColor?: string;
  textAlign?:
    | "right"
    | "-webkit-match-parent"
    | "center"
    | "end"
    | "justify"
    | "left"
    | "match-parent"
    | "start";
};

export const StatisticCard = ({
  title,
  value,
  precision = 2,
  prefix,
  formatter,
  backgroundColor,
  Icon,
  textAlign = "right",
}: Props) => (
  <Card
    bordered={false}
    className='statistic-card-container'
    style={{ backgroundColor: backgroundColor }}
  >
    <Statistic
      title={Icon ? <Icon /> : ""}
      value={value}
      precision={precision}
      prefix={prefix}
      formatter={formatter}
      valueStyle={{ fontWeight: 600, textAlign: textAlign }}
    />
    {title && (
      <Typography.Text className='statistic-card-title'>
        {title}
      </Typography.Text>
    )}
  </Card>
);
