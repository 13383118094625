import { GetProp, TableProps } from "antd";
import { IOpenProjectsDepositsReceived } from "financials/models";
import { utilityService } from "shared/services";

type ColumnsType<T extends object> = GetProp<TableProps<T>, "columns">;

export const OpenProjectsDepositsReceivedColumns: ColumnsType<IOpenProjectsDepositsReceived> =
  [
    {
      title: "",
      dataIndex: "id",
      width: 100,
      fixed: "left",
      render: (value, record, index) => index + 1,
    },
    {
      title: "Project",
      dataIndex: "projectName",
      render: (value, record, index) => utilityService.truncateField(value),
    },
    {
      title: "Deposit Invoiced Date",
      dataIndex: "depositInvoicedDate",
      render: (value, record, index) => utilityService.dateFormatter(value),
    },
    {
      title: "ReceivedDate",
      dataIndex: "receivedDate",
      render: (value, record, index) => utilityService.dateFormatter(value),
    },
    {
      title: "Deposit Amount Received",
      dataIndex: "depositAmountReceived",
      render: (value, record, index) =>
        `$${utilityService.statisticsFormatter(value)}`,
    },
    {
      title: "Project Amount",
      dataIndex: "projectAmount",
      render: (value, record, index) =>
        `$${utilityService.statisticsFormatter(value)}`,
    },
  ];
