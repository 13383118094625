import { Mentions } from "antd";
import { isEmpty } from "lodash";
import { useState } from "react";
import { ISelectOption } from "shared/models";

const { Option } = Mentions;

type Props = {
  onSearch?: (text: string, prefix: string) => void;
  onMouseLeave: (content: string, mentionIds: string[]) => void;
  placeHolder?: string;
  options: ISelectOption[];
  autoSize?: boolean | any | undefined;
};

const MentionsComponent = ({
  onSearch,
  onMouseLeave,
  placeHolder,
  options,
  autoSize,
}: Props) => {
  const [selectedMentions, setSelectedMentions] = useState<ISelectOption[]>([]);

  const extractMentionIds = (content: string): string[] => {
    const mentionIds = selectedMentions
      .filter((sm) => !isEmpty(sm?.label) && content?.includes(`@${sm.label}`))
      .map((sm) => sm.value?.toString() as string);
    return mentionIds;
  };

  const handleMouseLeave = ({
    currentTarget,
  }: React.MouseEvent<HTMLTextAreaElement>) => {
    const content = currentTarget?.value ?? "";
    const mentionIds = extractMentionIds(content);

    if (onMouseLeave) onMouseLeave(content, mentionIds);
  };

  const handleSearch = (text: string, prefix: string) => {
    if (onSearch) onSearch(text, prefix);
  };

  return (
    <Mentions
      style={{ width: "100%" }}
      onMouseLeave={handleMouseLeave}
      onSelect={(o) => {
        setSelectedMentions([
          ...selectedMentions,
          {
            value: o.key,
            label: o.value,
          },
        ]);
      }}
      onSearch={handleSearch}
      placeholder={placeHolder}
      autoSize={autoSize}
    >
      {options
        .filter((option) => !isEmpty(option))
        .map((option) => (
          <Option key={option.value as string} value={option.label as string}>
            {option.label}
          </Option>
        ))}
    </Mentions>
  );
};

export default MentionsComponent;
