import { GetProp, TableProps } from "antd";
import { utilityService } from "shared/services";
import { IOutstandingContractorQuote } from "projects/models";

type ColumnsType<T extends object> = GetProp<TableProps<T>, "columns">;

export const OutstandingContractorQuotesColumns: ColumnsType<IOutstandingContractorQuote> =
  [
    {
      title: "",
      dataIndex: "id",
      width: 100,
      fixed: "left",
      render: (value, record, index) => index + 1,
    },
    {
      title: "Project",
      dataIndex: "projectName",
      render: (value, record, index) => utilityService.truncateField(value),
    },
    {
      title: "Subcontractor",
      dataIndex: "subcontractor",
      render: (value, record, index) => utilityService.truncateField(value),
    },
    {
      title: "Days Since HVAC Assessment Sent",
      dataIndex: "daysSinceHvacAssessmentSent",
      render: (value, record, index) =>
        utilityService.truncateField(`${value} days`),
    },
  ];
