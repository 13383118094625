import { Card, Col, Progress, Row, Typography } from "antd";

import { useBoundStore } from "store";

import "./ProjectsTimeTrackingSummary.scss";

type Props = {
  dataLoading?: boolean;
};

export const ProjectsTimeTrackingSummary = ({ dataLoading }: Props) => {
  const { projectsTimeTrackingSummary } = useBoundStore();

  return (
    <>
      <Row>
        <Typography.Title
          className='projects-time-tracking-summary-title'
          level={3}
        >
          All Projects - Time Tracking Summary
        </Typography.Title>
      </Row>
      <Row
        gutter={[
          { xs: 4, sm: 8, md: 16, lg: 20 },
          { xs: 4, sm: 8, md: 16, lg: 20 },
        ]}
        justify={"space-between"}
      >
        {projectsTimeTrackingSummary?.map((p) => (
          <Col span={12}>
            <Card className='projects-time-tracking-summary-card'>
              <Row>
                <Col span={24}>
                  <Typography.Text>{p.stage}</Typography.Text>
                </Col>
              </Row>
              <Row
                gutter={{ xs: 4, sm: 8, md: 16, lg: 20 }}
                justify={"space-between"}
              >
                <Col span={20}>
                  <Progress percent={p.percentage} showInfo={false} />
                </Col>
                <Col span={4}>
                  <Typography.Text strong>
                    {p.expectedDays} days{" "}
                  </Typography.Text>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Typography.Text>
                    <Typography.Text strong>
                      {p.daysLeftFromTarget} days{" "}
                    </Typography.Text>
                    left from target
                  </Typography.Text>
                </Col>
              </Row>
            </Card>
          </Col>
        ))}
      </Row>
    </>
  );
};
