import { Col, Row } from "antd";

import "./ProgramManagerDashboard.scss";
import { ProgramManagerDashboardSalesCard } from "./program-manager-dashboard-sales-card/ProgramManagerDashboardSalesCard";
import { ProgramManagerDashboardProjectCard } from "./program-manager-dashboard-project-card/ProgramManagerDashboardProjectCard";
import { ProgramManagerDashboardNotesCard } from "./program-manager-dashboard-notes-card/ProgramManagerDashboardNotesCard";

export const ProgramManagerDashboard = () => {
  return (
    <Col span={24} className='program-manager-dashboard'>
      <Row gutter={{ xs: 4, sm: 8, md: 16, lg: 20 }} justify={"space-between"}>
        <Col span={16}>
          <ProgramManagerDashboardSalesCard />
        </Col>
        <Col span={8}>
          <ProgramManagerDashboardNotesCard />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <ProgramManagerDashboardProjectCard />
        </Col>
      </Row>
    </Col>
  );
};
