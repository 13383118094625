import { Row, Table, TableProps, Typography } from "antd";
import "./ProjectsDetails.scss";
import React from "react";
import { useBoundStore } from "store";
import { ProjectsDetailsColumns } from "projects/constants";
import { IProjectDetails } from "projects/models";
import { utilityService } from "shared/services";

type Props = {
  dataLoading?: boolean;
};

export const ProjectsDetails = ({ dataLoading }: Props) => {
  const tblRef: Parameters<typeof Table>[0]["ref"] = React.useRef(null);
  const tableColumns = ProjectsDetailsColumns.map((item) => ({
    ...item,
  }));
  const tableProps: TableProps<IProjectDetails> = {
    loading: dataLoading,
  };
  const { projectsDetails } = useBoundStore();

  return (
    <>
      <Row>
        <Typography.Title className='projects-details-title' level={3}>
          My Projects Details
        </Typography.Title>
      </Row>
      <Row>
        <Table
          {...tableProps}
          columns={tableColumns}
          dataSource={projectsDetails}
          className='projects-details-table'
          ref={tblRef}
          rowKey='id'
          pagination={false}
          summary={(pageData) => {
            let totalProjectValue = 0;

            pageData.forEach(({ projectValue }) => {
              totalProjectValue += Number(projectValue ?? 0);
            });

            return (
              <>
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0}></Table.Summary.Cell>
                  <Table.Summary.Cell index={1}>
                    <Typography.Text strong>TOTALS</Typography.Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={2}>
                    <Typography.Text strong>
                      ${utilityService.statisticsFormatter(totalProjectValue)}
                    </Typography.Text>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </>
            );
          }}
        />
      </Row>
    </>
  );
};
