import { Col, Form } from "antd";
import { ReactNode } from "react";

import { useDisplayFieldByStage } from "projects/hooks";
import "./ProjectFormFieldItem.scss";
import { ProjectValidationSchema } from "projects/constants";
import { isEmpty } from "lodash";
import { Rule } from "antd/es/form";

type Props = {
  children: ReactNode;
  fieldCategory: string;
  name: string;
  label: string;
  colSpan?: number;
  validationStage?: string;
  getValueProps?: ((value: any) => Record<string, unknown>) | undefined;
  rules?: Rule[] | undefined;
  valuePropName?: string | undefined;
};

export const ProjectFormFieldItem = ({
  children,
  fieldCategory,
  name,
  label,
  colSpan = 24,
  validationStage = "",
  getValueProps,
  rules,
  valuePropName,
}: Props) => {
  const { displayFieldByStage } = useDisplayFieldByStage();
  const schema = ProjectValidationSchema.find(
    (s) => s.name?.toLowerCase() === name.toLowerCase()
  );
  const required = isEmpty(validationStage)
    ? false
    : schema?.required?.stages?.includes(validationStage) ?? false;

  return (
    <>
      {displayFieldByStage(fieldCategory, name) && (
        <Col span={colSpan}>
          <Form.Item
            name={name}
            data-testid={name}
            label={label}
            rules={[
              ...(rules ?? []),
              {
                required,
                message: `${label} is required.`,
              },
            ]}
            getValueProps={getValueProps}
            valuePropName={valuePropName}
          >
            {children}
          </Form.Item>
        </Col>
      )}
    </>
  );
};
