import { Button, Flex, Typography } from "antd";
import { useNavigate } from "react-router-dom";

import "./PageNotFound.scss";
import { Logo } from "shared/components";

export const PageNotFound = () => {
  const navigate = useNavigate();

  return (
    <Flex
      justify='top'
      align='center'
      vertical
      className='page-not-found-container'
    >
      <Logo className='page-not-found-logo' />
      <Typography.Title level={1}>404 Not Found</Typography.Title>
      <Typography.Title level={2} className='page-not-found-subtitle'>
        Oops! Don’t worry, it happens sometimes. <br /> Click “Back to home” to
        start over.
      </Typography.Title>
      <Button
        type='primary'
        size='large'
        block
        className='page-not-found-button'
        onClick={() => {
          navigate("/login");
        }}
      >
        Back to home
      </Button>
    </Flex>
  );
};
