import React from "react";
import { Checkbox, Form, Input } from "antd";

import "./Logout.scss";
import { SplitPageLayout } from "shared/components";
import { SubmitButton } from "shared/components";
import { ForgotPasswordLink, SignUpLink } from "../../components";
import { useLogin } from "../../hooks";

export const Logout = () => {
  const { login, error } = useLogin();
  const [form] = Form.useForm();

  const onFinishHandler = (values: any) => {
    login(values.email, values.password);
  };

  return (
    <SplitPageLayout
      title='Logout'
      subtitle='You have successfully logged out of your TracPro account.
      Log back in by entering in your information below.'
      error={error}
    >
      <Form
        form={form}
        name='logout-form'
        data-testid='logout-form'
        layout='vertical'
        onFinish={onFinishHandler}
        requiredMark={false}
      >
        <Form.Item
          name='email'
          data-testid='email'
          label='Email'
          rules={[
            {
              required: true,
              message: "Please enter a valid email address.",
            },
            {
              type: "email",
              message: "Please enter a valid email address.",
            },
          ]}
        >
          <Input size='large' />
        </Form.Item>
        <Form.Item
          name='password'
          data-testid='password'
          label='Password'
          rules={[
            {
              required: true,
              message: "Please enter a password.",
            },
            {
              type: "string",
              message: "Please enter a password.",
            },
          ]}
        >
          <Input.Password size='large' />
        </Form.Item>
        <Form.Item
          name='remember'
          className='logout-remember'
          data-testid='logout-remember'
          valuePropName='checked'
          noStyle
        >
          <Checkbox>Remember me</Checkbox>
        </Form.Item>
        <ForgotPasswordLink />
        <SubmitButton
          testId='logout-button'
          form={form}
          overrideDisabled={false}
        >
          Login
        </SubmitButton>
      </Form>
      <SignUpLink />
    </SplitPageLayout>
  );
};
