import {
  ISimpleSubcontractor,
  ISubcontractorMember,
} from "subcontractors/models";
import { httpService } from "shared/services";
import { ISelectOption } from "shared/models";
import { isEmpty } from "lodash";

const apiPath = `${process.env.REACT_APP_API_BASE_URL}/api/v1/subcontractors`;

export const getSimpleSubcontractorsByType = async (type: string) => {
  const res = await httpService.instance.get<ISimpleSubcontractor[]>(
    `${apiPath}/simple?type=${type}`
  );
  return res?.data;
};

export const getSimpleSubcontractors = async () => {
  const res = await httpService.instance.get<ISimpleSubcontractor[]>(
    `${apiPath}/simple`
  );
  return res?.data;
};

export const getSubcontractorMembers = async (id: string) => {
  const res = await httpService.instance.get<ISubcontractorMember[]>(
    `${apiPath}/${id}/members`
  );
  return res?.data;
};

export const getSubcontractorMemberSelectOptions = async (id?: string) => {
  let url = `${apiPath}/options`;

  if (!isEmpty(id)) {
    url += `?id=${id}`;
  }

  const res = await httpService.instance.get<ISelectOption[]>(url);
  return res?.data;
};

export const createSubcontractor = async (
  subcontractor: ISimpleSubcontractor
) => {
  const res = await httpService.instance.post<ISimpleSubcontractor>(
    `${apiPath}`,
    subcontractor
  );
  return res?.data;
};

export const updateSubcontractor = async (
  subcontractor: ISimpleSubcontractor
) => {
  const res = await httpService.instance.put<ISimpleSubcontractor>(
    `${apiPath}/${subcontractor.id}`,
    subcontractor
  );
  return res?.data;
};

export const deleteSubcontractor = async (id: string) => {
  await httpService.instance.delete(`${apiPath}/${id}`);
};
