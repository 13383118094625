import { Card } from "antd";

import "./FieldAuditorDashboardNotesCard.scss";

import { NotesList } from "notes/components";

export const FieldAuditorDashboardNotesCard = () => {
  return (
    <Card className='field-auditor-dashboard-notes-card-container'>
      <NotesList title='My Project Notes' height={600} />
    </Card>
  );
};
