import { Col, Row, Typography } from "antd";
import { Cell, Legend, Pie, PieChart, ResponsiveContainer } from "recharts";

import { useBoundStore } from "store";

import "./HvacInstallationsBySubcontractor.scss";
import { utilityService } from "shared/services";

export const HvacInstallationsBySubcontractor = () => {
  const { hvacInstallationsBySubcontractor } = useBoundStore();
  const COLORS = ["#FF5E4B", "#FB9D11", "#FAF119", "#13B76E"];

  return (
    <>
      <Row>
        <Typography.Title
          className='hvac-installations-by-subcontractor-title'
          level={3}
        >
          HVAC Installations By Subcontractor
        </Typography.Title>
      </Row>
      <Row>
        <Col span={24}>
          <ResponsiveContainer width='100%' height={400}>
            <PieChart>
              <Pie
                dataKey='totalProjects'
                nameKey='status'
                data={hvacInstallationsBySubcontractor}
                innerRadius={70}
                outerRadius={100}
              >
                {hvacInstallationsBySubcontractor?.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
              <Legend
                layout='vertical'
                align='right'
                verticalAlign='middle'
                payload={hvacInstallationsBySubcontractor?.map(
                  (item, index) => ({
                    id: item?.subcontractor,
                    type: "square",
                    value: `${item?.subcontractor} (${item?.totalProjects ?? 0}) $${utilityService.statisticsFormatter(item.amount)}`,
                    color: COLORS[index % COLORS.length],
                  })
                )}
              />
            </PieChart>
          </ResponsiveContainer>
        </Col>
      </Row>
    </>
  );
};
