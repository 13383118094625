import { financialsService } from "financials/services";
import { useState } from "react";

import { useBoundStore } from "store";

export const useGetFinancialsInfo = () => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState<Error | undefined>(undefined);
  const {
    setDepositStatuses,
    setOpenAccountsReceivableStatuses,
    setOpenAccountsReceivableIncentive,
    setOpenAccountsReceivableFinanced,
    setOpenAccountsReceivableCustomerDirect,
    setOpenProjectsDepositsReceived,
  } = useBoundStore();

  const getFinancialsInfo = async () => {
    try {
      setLoading(true);
      setSuccess(false);
      setError(undefined);

      const financialsInfo = await financialsService.getFinancialsInfo();
      setDepositStatuses(financialsInfo?.depositStatuses);
      setOpenAccountsReceivableStatuses(
        financialsInfo?.openAccountsReceivableStatuses
      );
      setOpenAccountsReceivableIncentive(
        financialsInfo?.openAccountsReceivableIncentive
      );
      setOpenAccountsReceivableFinanced(
        financialsInfo?.openAccountsReceivableFinanced
      );
      setOpenAccountsReceivableCustomerDirect(
        financialsInfo?.openAccountsReceivableCustomerDirect
      );
      setOpenProjectsDepositsReceived(
        financialsInfo?.openProjectsDepositsReceived
      );
      setSuccess(true);
    } catch (error) {
      setSuccess(false);
      setError(error as Error);
    } finally {
      setLoading(false);
    }
  };

  return {
    getFinancialsInfo,
    loading,
    success,
    error,
  };
};
