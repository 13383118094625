import { Row, Table, TableProps, Typography } from "antd";
import "./OpenAccountsReceivableFinanced.scss";
import React from "react";
import { useBoundStore } from "store";
import { OpenAccountsReceivableFinancedColumns } from "financials/constants";
import { IOpenAccountsReceivableFinanced } from "financials/models";
import { utilityService } from "shared/services";

type Props = {
  dataLoading?: boolean;
};

export const OpenAccountsReceivableFinanced = ({ dataLoading }: Props) => {
  const tblRef: Parameters<typeof Table>[0]["ref"] = React.useRef(null);
  const tableColumns = OpenAccountsReceivableFinancedColumns.map((item) => ({
    ...item,
  }));
  const tableProps: TableProps<IOpenAccountsReceivableFinanced> = {
    loading: dataLoading,
  };
  const { openAccountsReceivableFinanced } = useBoundStore();

  return (
    <>
      <Row>
        <Typography.Title
          className='open-accounts-receivable-financed-title'
          level={3}
        >
          Open Accounts Receivable - Financed
        </Typography.Title>
      </Row>
      <Row>
        <Table
          {...tableProps}
          columns={tableColumns}
          dataSource={openAccountsReceivableFinanced}
          className='open-accounts-receivable-financed-table'
          ref={tblRef}
          rowKey='id'
          pagination={false}
          summary={(pageData) => {
            let totalBilledFinancedAmount = 0;
            let totalCustomerFinancedPortionReceivedAmount = 0;

            pageData.forEach(
              ({
                billedFinancedAmount,
                customerFinancedPortionReceivedAmount,
              }) => {
                totalBilledFinancedAmount += Number(billedFinancedAmount ?? 0);
                totalCustomerFinancedPortionReceivedAmount += Number(
                  customerFinancedPortionReceivedAmount ?? 0
                );
              }
            );

            return (
              <>
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0}></Table.Summary.Cell>
                  <Table.Summary.Cell index={1} colSpan={2}>
                    <Typography.Text strong>TOTALS</Typography.Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={2} colSpan={4}>
                    <Typography.Text strong>
                      $
                      {utilityService.statisticsFormatter(
                        totalBilledFinancedAmount
                      )}
                    </Typography.Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={3}>
                    <Typography.Text strong>
                      $
                      {utilityService.statisticsFormatter(
                        totalCustomerFinancedPortionReceivedAmount
                      )}
                    </Typography.Text>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </>
            );
          }}
        />
      </Row>
    </>
  );
};
