import { InputNumber } from "antd";

type Props = {
  size?: "small" | "middle" | "large";
  variant?: "borderless" | "outlined" | "filled";
  value?: number;
  onChange?: ((value: number | null) => void) | undefined;
  readOnly?: boolean;
  disabled?: boolean;
};

export const MoneyInput = ({
  size,
  variant,
  onChange,
  value,
  readOnly,
  disabled,
}: Props) => {
  return (
    <InputNumber
      value={value}
      formatter={(v) => `$ ${v}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
      parser={(v) => v?.replace(/\$\s?|(,*)/g, "") as unknown as number}
      size={size}
      variant={variant}
      onChange={onChange}
      readOnly={readOnly}
      disabled={disabled}
    />
  );
};
