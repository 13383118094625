import { useEffect } from "react";
import { notification } from "antd";
import Link from "antd/es/typography/Link";

import { useResendConfirmationCode } from "../../hooks";

type Props = {
  email: string;
};

export const ResendConfirmationCode = ({ email }: Props) => {
  const { resendConfirmationCode, error, success } =
    useResendConfirmationCode();
  const [api, contextHolder] = notification.useNotification();

  const onClickHandler = async () => {
    await resendConfirmationCode(email);
  };

  useEffect(() => {
    if (success) {
      api.success({
        duration: 3,
        message: "Resend verification code complete",
        description: "Your account verification code has been re-sent.",
      });
    } else if (error) {
      api.error({
        message: "Resend verification code error",
        description: error?.message,
      });
    }
  }, [success, error, api]);

  return (
    <>
      {contextHolder}
      <Link onClick={onClickHandler}>Resend Code</Link>
    </>
  );
};
