import { ISelectOption } from "shared/models";

export const FinanceLenders: ISelectOption[] = [
  {
    label: "Ascentium",
    value: "ascentium",
  },
  {
    label: "First Commonwealth",
    value: "first_commonwealth",
  },
  {
    label: "Graybar",
    value: "graybar",
  },
  {
    label: "Other",
    value: "other",
  },
];
