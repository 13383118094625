import { Col, Row, Typography, Card, Skeleton } from "antd";

import "./ContractorDashboardProjectsCard.scss";
import { SalesTargetTimePeriods } from "users/components";
import {
  HvacInstallationsBySubcontractor,
  OutstandingContractorQuotes,
} from "projects/components";
import { LightingInstallationsBySubcontractor } from "projects/components/lighting-installations-by-subcontractor/LightingInstallationsBySubcontractor";

type Props = {
  onSalesTargetTimePeriodChange?: () => void;
  loaded?: boolean;
};

export const ContractorDashboardProjectsCard = ({
  onSalesTargetTimePeriodChange,
  loaded,
}: Props) => {
  return (
    <Card className='contractor-dashboard-projects-card-container'>
      {loaded ? (
        <>
          <Row
            gutter={{ xs: 4, sm: 8, md: 16, lg: 20 }}
            justify={"space-between"}
          >
            <Col>
              <Typography.Title
                className='contractor-dashboard-projects-card-title'
                level={3}
              >
                Projects
              </Typography.Title>
            </Col>
            <Col>
              <SalesTargetTimePeriods
                name='sales-target-time-periods'
                onChange={onSalesTargetTimePeriodChange}
              />
            </Col>
          </Row>
          <Row
            gutter={{ xs: 4, sm: 8, md: 16, lg: 20 }}
            justify={"space-between"}
          >
            <Col span={24}>
              <HvacInstallationsBySubcontractor />
            </Col>
          </Row>
          <Row
            gutter={{ xs: 4, sm: 8, md: 16, lg: 20 }}
            justify={"space-between"}
          >
            <Col span={24}>
              <LightingInstallationsBySubcontractor />
            </Col>
          </Row>
          <Row
            gutter={{ xs: 4, sm: 8, md: 16, lg: 20 }}
            justify={"space-between"}
          >
            <Col span={24}>
              <OutstandingContractorQuotes />
            </Col>
          </Row>
        </>
      ) : (
        <Skeleton />
      )}
    </Card>
  );
};
