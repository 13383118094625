import { Col, notification, Row } from "antd";

import "./SalesRepDashboard.scss";
import { useEffect } from "react";
import { useGetSalesPipelineInfo } from "sales/hooks";
import { SalesRepDashboardSalesPipelineCard } from "./sales-rep-dashboard-sales-pipeline-card/SalesRepDashboardSalesPipelineCard";
import { SalesRepDashboardNotesCard } from "./sales-rep-dashboard-notes-card/SalesRepDashboardNotesCard";

export const SalesRepDashboard = () => {
  const { getSalesPipelineInfo, error, success } = useGetSalesPipelineInfo();
  const [api, notificationContextHolder] = notification.useNotification();

  const onSalesTargetTimePeriodChange = () => {
    getSalesPipelineInfo();
  };

  useEffect(() => {
    getSalesPipelineInfo();
  }, []);

  useEffect(() => {
    if (error) {
      api.error({
        message: "Error",
        description: error?.message,
      });
    }
  }, [error, api]);

  return (
    <>
      {notificationContextHolder}
      <Col span={24} className='sales-rep-dashboard'>
        <Row
          gutter={{ xs: 4, sm: 8, md: 16, lg: 20 }}
          justify={"space-between"}
        >
          <Col span={16}>
            <SalesRepDashboardSalesPipelineCard
              onSalesTargetTimePeriodChange={onSalesTargetTimePeriodChange}
              loaded={success}
            />
          </Col>
          <Col span={8}>
            <SalesRepDashboardNotesCard />
          </Col>
        </Row>
      </Col>
    </>
  );
};
